<template>
    <div class="forget-password">
        <div class="register">
            <div
                :class="[isE() ? 'gr-register-left-ebot' : 'gr-register-left']"
            ></div>
            <div class="gr-register-right">
                <div class="gr-register-right-c">
                    <div
                        :class="[
                            isE()
                                ? 'gr-register-logo-ebot'
                                : 'gr-register-logo',
                        ]"
                    ></div>
                    <div class="gr-register-title">
                        {{ $t("forgetpassword.title") }}
                    </div>
                    <div class="gr-register-forms" id="form">
                        <div class="form-item">
                            <div class="label">
                                {{ $t("forgetpassword.accountLabel") }}
                            </div>
                            <el-input
                                id="inputPhone"
                                v-model="userName"
                                @input="changeUserName"
                                @blur="checkAccountStatus"
                                :placeholder="
                                    $t('forgetpassword.accountPlaceholder')
                                "
                            />
                            <div
                                class="gr-tips bgc-gr-tips"
                                v-if="userNameTips !== ''"
                            >
                                {{ userNameTips }}
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="label">
                                {{ $t("forgetpassword.verifyMethodLabel") }}
                            </div>
                            <el-select
                                @change="changeVerifyType"
                                v-model="verify"
                                :placeholder="
                                    $t('forgetpassword.verifyMethodPlaceholder')
                                "
                            >
                                <el-option
                                    v-for="item in verifyOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                ></el-option>
                            </el-select>
                            <div
                                class="gr-tips bgc-gr-tips"
                                v-if="verifyTips !== ''"
                            >
                                {{ verifyTips }}
                            </div>
                        </div>
                        <div class="form-item mark">
                            <div class="label">
                                {{ $t("forgetpassword.codeLabel") }}
                            </div>
                            <div class="form-input-cell">
                                <el-input
                                    id="inputPassword"
                                    :placeholder="
                                        $t('forgetpassword.codePlaceholder')
                                    "
                                    v-model="checkCode"
                                    @input="changeCheckCode"
                                />
                                <span
                                    @click="getCheckcode"
                                    v-if="!verifyIng"
                                    class="varify-btn"
                                    >{{ $t("forgetpassword.codeButton") }}</span
                                >
                                <span v-if="verifyIng" class="varify-time"
                                    >{{ verifyTime }} S</span
                                >
                            </div>
                            <div
                                class="gr-tips bgc-gr-tips"
                                v-if="checkCodeTips !== ''"
                            >
                                {{ checkCodeTips }}
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="label">
                                <span>{{
                                    $t("forgetpassword.passwordLabel")
                                }}</span>
                                <span class="tips">
                                    <i
                                        class="guoran-tongyichicun-18-16-youxianghouzhuishuomingtishifuhe iconfont"
                                    ></i>
                                    {{
                                        $t("forgetpassword.passwordTips")
                                    }}</span
                                >
                            </div>
                            <el-input
                                type="password"
                                id="inputPassword"
                                v-model.trim="newPassword"
                                :onkeyup="
                                    (newPassword = newPassword.replace(
                                        /\s+/g,
                                        ''
                                    ))
                                "
                                @blur="changeNewPassword"
                                :placeholder="
                                    $t('forgetpassword.inputPassword')
                                "
                            />
                            <div
                                class="gr-tips bgc-gr-tips"
                                v-if="newPasswordTips !== ''"
                            >
                                {{ newPasswordTips }}
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="label">
                                {{ $t("forgetpassword.confirmPasswordLabel") }}
                            </div>
                            <el-input
                                type="password"
                                v-model.trim="repeatNewPassword"
                                id="inputpasswordAgain"
                                :onkeyup="
                                    (repeatNewPassword =
                                        repeatNewPassword.replace(/\s+/g, ''))
                                "
                                @blur="changeRepeatNewPassword"
                                :placeholder="
                                    $t(
                                        'forgetpassword.confirmPasswordPlaceholder'
                                    )
                                "
                            />
                            <div
                                class="gr-tips bgc-gr-tips"
                                v-if="repeatNewPasswordTips !== ''"
                            >
                                {{ repeatNewPasswordTips }}
                            </div>
                        </div>
                        <div class="form-item gr-register-btn">
                            <button @click="resetPassword" id="getBackNow">
                                {{ $t("forgetpassword.resetPasswordButton") }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { redirectUri } from "../../const/redirectUri";
export default {
    data() {
        return {
            verifyTime: 60,
            verifyName: "",
            verify: "",
            verifyTips: "",
            userName: "",
            userNameTips: "",
            checkCode: "",
            checkCodeTips: "",
            newPassword: "",
            newPasswordTips: "",
            repeatNewPassword: "",
            repeatNewPasswordTips: "",
            verifyIng: false,
            verifyOptions: [],
            accountStatus: true,
        };
    },
    computed: {},
    methods: {
        checkAccountStatus() {
            if (this.userName.trim() == "") {
                return;
            }
            this.$http_ignore_auth
                .get("api/account/checkUserActivate?username=" + this.userName)
                .then((res) => {
                    if (res.data.code == 0) {
                        this.accountStatus = true;
                    } else {
                        this.accountStatus = false;
                        this.$message.error(res.data.message);
                    }
                });
        },
        changeUserName(val) {
            if (this.userName.trim() !== "") {
                this.userNameTips = "";
            }
        },
        changeVerifyType() {
            if (this.verify !== "") {
                this.verifyTips = "";
            }
        },
        changeCheckCode() {
            if (this.checkCode.trim() !== "") {
                this.checkCodeTips = "";
            }
        },
        changeNewPassword() {
            let reg = /^(?=.*[0-9])(?=.*[a-zA-Z])(.{8,})$/;
            if (this.newPassword === "") {
                this.newPasswordTips = this.$t("forgetpassword.inputPassword");
            } else {
                if (this.repeatNewPassword !== "") {
                    this.newPasswordTips = "";
                    if (this.newPassword !== this.repeatNewPassword) {
                        this.repeatNewPasswordTips = this.$t(
                            "forgetpassword.passwordMismatch"
                        );
                    } else {
                        if (reg.test(this.newPassword)) {
                            this.newPasswordTips = "";
                        } else {
                            this.newPasswordTips = this.$t(
                                "forgetpassword.passwordTips"
                            );
                        }
                        this.repeatNewPasswordTips = "";
                    }
                } else {
                    if (this.newPassword.length < 8) {
                        this.newPasswordTips = this.$t(
                            "forgetpassword.passwordLength"
                        );
                    } else {
                        if (reg.test(this.newPassword)) {
                            this.newPasswordTips = "";
                        } else {
                            this.newPasswordTips = this.$t(
                                "forgetpassword.passwordTips"
                            );
                        }
                    }
                }
            }
        },
        changeRepeatNewPassword() {
            let reg = /^(?=.*[0-9])(?=.*[a-zA-Z])(.{8,})$/;
            if (this.repeatNewPassword === "") {
                this.repeatNewPasswordTips = this.$t(
                    "forgetpassword.reinputPassword"
                );
            } else {
                if (this.newPassword !== "") {
                    this.newPasswordTips = "";
                    if (this.newPassword !== this.repeatNewPassword) {
                        this.repeatNewPasswordTips = this.$t(
                            "forgetpassword.passwordMismatch"
                        );
                    } else {
                        if (reg.test(this.repeatNewPassword)) {
                            this.repeatNewPasswordTips = "";
                        } else {
                            this.repeatNewPasswordTips = this.$t(
                                "forgetpassword.passwordTips"
                            );
                        }
                    }
                } else {
                    if (this.repeatNewPassword.length < 8) {
                        this.repeatNewPasswordTips = this.$t(
                            "forgetpassword.passwordLength"
                        );
                    } else {
                        if (reg.test(this.repeatNewPassword)) {
                            this.repeatNewPasswordTips = "";
                        } else {
                            this.repeatNewPasswordTips = this.$t(
                                "forgetpassword.passwordTips"
                            );
                        }
                    }
                }
            }
        },
        resetPassword() {
            if (!this.accountStatus) return false;
            if (this.userName.trim() === "") {
                this.userNameTips = this.$t("forgetpassword.accountNotEmpty");
                return false;
            }
            if (this.verify === "") {
                this.verifyTips = this.$t("forgetpassword.selectVerifyMethod");
                return false;
            }
            if (
                this.checkCode.trim() === "" ||
                this.checkCode.trim().length !== 6
            ) {
                this.checkCodeTips = this.$t("forgetpassword.correctCode");
                return false;
            }
            if (this.newPassword.trim() === "") {
                this.newPasswordTips = this.$t("forgetpassword.inputPassword");
                return false;
            }
            if (this.repeatNewPassword === "") {
                this.repeatNewPasswordTips = this.$t(
                    "forgetpassword.reinputPassword"
                );
                return false;
            }
            if (this.newPassword.trim() !== this.repeatNewPassword.trim()) {
                this.repeatNewPasswordTips = this.$t(
                    "forgetpassword.passwordMismatch"
                );
                return false;
            }
            if (
                this.newPasswordTips !== "" ||
                this.repeatNewPasswordTips !== ""
            ) {
                return false;
            }

            this.$http_ignore_auth
                .post("api/account/forgotPassword?userName", {
                    userName: this.userName,
                    checkCode: this.checkCode,
                    newPassword: this.newPassword,
                    repeatNewPassword: this.repeatNewPassword,
                    codeType: this.verify,
                })
                .then((res) => {
                    if (res.data.code == 0) {
                        window.location.href =
                            redirectUri.sso +
                            "/login.html?username=" +
                            this.userName.trim();
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
        },
        getCheckcode() {
            if (!this.accountStatus) return false;
            if (this.userName.trim() === "") {
                this.userNameTips = this.$t("forgetpassword.accountNotEmpty");
                return false;
            }
            if (this.verify === "") {
                this.verifyTips = this.$t("forgetpassword.selectVerifyMethod");
                return false;
            }
            this.$http_ignore_auth
                .post(
                    "api/code/mail/sendResetPassWord?verifyType=" +
                        this.verify +
                        "&receiver=" +
                        this.userName
                )
                .then((res) => {
                    if (res.data.code == 0) {
                        this.verifyIng = true;
                        this.verifyTime = 60;
                        let timer = setInterval(() => {
                            this.verifyTime = this.verifyTime - 1;
                            if (this.verifyTime == 0) {
                                this.verifyName = this.$t(
                                    "forgetpassword.codeButton"
                                );
                                this.verifyIng = false;
                                clearInterval(timer);
                            }
                        }, 1000);
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
        },
    },
    mounted() {
        this.verifyOptions = [
            {
                label: this.$t("forgetpassword.verifyMethod1"),
                value: 1,
            },
            {
                label: this.$t("forgetpassword.verifyMethod2"),
                value: 0,
            },
        ];
        this.verifyName = this.$t("forgetpassword.codeButton");
    },
};
</script>
<style lang="less" scoped>
.register {
    width: 100%;
    display: flex;
    height: 100vh;
    background-color: white;
    font-family: "Arial", "Microsoft YaHei", "黑体", "宋体", sans-serif;
}

.gr-tips {
    color: #f56c6c;
    text-align: right;
    font-size: 11px;
}
.bgc-gr-tips {
    color: #fe5965;
    font-size: 12px;
    margin-top: 2px;
    margin-bottom: 3px;
    height: 18px;
    background: #ffebec;
    border-radius: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 960px) {
    .gr-register-left {
        display: none;
    }

    .register {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.gr-register-left {
    width: 62vw;
    background: #f7f9ff;
    text-align: center;
    background-image: url("https://guoranopen-zjk.oss-cn-zhangjiakou.aliyuncs.com/cdn-office-website/images/login_newui.png");
    background-repeat: no-repeat;
    background-size: 70% auto;
    background-position: center center;
}

.gr-register-left-ebot {
    width: 62vw;
    background: #f7f9ff;
    text-align: center;
    background-image: url("https://guoranopen-zjk.oss-cn-zhangjiakou.aliyuncs.com/cdn-office-website/images/login_newui_eBot.png");
    background-repeat: no-repeat;
    background-size: 70% auto;
    background-position: center center;
}

.gr-register-right {
    width: 38vw;
    min-width: 556px;
    height: 370px;
    margin: auto 0;
}

.gr-register-right-c {
    margin-top: -100px;
}

.gr-register-logo {
    width: 100%;
    height: 46px;
    background-image: url("https://guoranopen-zjk.oss-cn-zhangjiakou.aliyuncs.com/cdn-office-website/images/logo_newui.png");
    background-repeat: no-repeat;
    background-size: auto 46px;
    background-position: center center;
}

.gr-register-logo-ebot {
    width: 100%;
    height: 46px;
    background-image: url("https://guoranopen-zjk.oss-cn-zhangjiakou.aliyuncs.com/cdn-office-website/images/logo_newui_eBot.png");
    background-repeat: no-repeat;
    background-size: auto 46px;
    background-position: center center;
}

.gr-register-title {
    margin-top: 15px;
    font-weight: 400;
    text-align: center;
    line-height: 14px;
    height: 14px;
}

.gr-register-forms {
    max-width: 60%;
    min-width: 342px;
    margin: 30px auto 0;
}

.gr-register-forms .form-item {
    width: 100%;
    height: 85px;
    .el-select {
        width: 100%;
        input {
            width: 100%;
        }
    }

    .form-input-cell {
        display: flex;
        justify-content: space-between;
        input {
            flex: auto;
            border-radius: 5px 0 0 5px;
        }
        .varify-btn {
            height: 38px;
            line-height: 38px;
            width: 140px;
            text-align: center;
            background: #3682ff;
            color: white;
            font-size: 12px;
            border-radius: 0 5px 5px 0;
            font-weight: 700;
            cursor: pointer;
        }
        .varify-time {
            height: 38px;
            line-height: 38px;
            width: 140px;
            text-align: center;
            background: #3682ff;
            color: white;
            font-size: 12px;
            border-radius: 0 5px 5px 0;
            font-weight: 700;
        }
    }
}

/deep/.gr-register-forms .form-item input {
    // width: calc(100% - 30px);
    outline-style: none;
    font-size: 14px;
    height: 38px;
}
/deep/.gr-register-forms .mark .el-input__inner {
    border-radius: 4px 0 0 4px;
}

.gr-register-forms .form-item .label {
    margin-bottom: 7px;
    font-size: 14px;
    font-weight: 500;
    color: #616161;
    line-height: 14px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    .tips,
    .iconfont {
        font-size: 12px;
        color: #a9b3c6;
    }
    .tips {
        margin-left: 4px;
    }
}

input::-webkit-input-placeholder {
    color: #a9b3c6 !important;
    font-weight: 500;
}

input::-moz-placeholder {
    color: #a9b3c6 !important;
    font-weight: 500;
}

input:-ms-input-placeholder {
    color: #a9b3c6 !important;
    font-weight: 500;
}

input:focus {
    border-color: #366aff;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.015), 0 0 4px #366aff;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.015), 0 0 4px #366aff;
}

.gr-register-btn {
    padding-top: 18px;
}

.gr-register-btn button {
    width: 100%;
    height: 38px;
    background: #3682ff;
    border-radius: 5px;
    color: white;
    border: none;
    cursor: pointer;
}

.gr-register-btn button:hover {
    background-color: #366aff;
}

.gr-register-login {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    font-weight: 500;
}

.disabled-btn {
    cursor: not-allowed !important;
    opacity: 0.8;
}
</style>