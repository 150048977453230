import { render, staticRenderFns } from "./Action_transfer.vue?vue&type=template&id=13ae13fc&scoped=true"
import script from "./Action_transfer.vue?vue&type=script&lang=js"
export * from "./Action_transfer.vue?vue&type=script&lang=js"
import style0 from "./Action_transfer.vue?vue&type=style&index=0&id=13ae13fc&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13ae13fc",
  null
  
)

export default component.exports