<template>
    <div class="action-transfer">
        <div class="artificial-send-text-value">
            <div class="tal artificial-text-title">{{$t('flowComponent.actionTransfer.title')}}</div>
            <div class="rhetorical-type-box">
                <span
                    :class="
                        unit.content.type == 1
                            ? 'rhetorical-type-box-cell-active'
                            : 'rhetorical-type-box-cell'
                    "
                    @click="unit.content.type = 1"
                    >{{$t('flowComponent.actionTransfer.randomCustomerService')}}</span
                >
                <span
                    :class="
                        unit.content.type == 2
                            ? 'rhetorical-type-box-cell-active'
                            : 'rhetorical-type-box-cell'
                    "
                    @click="unit.content.type = 2"
                    >{{$t('flowComponent.actionTransfer.customerServiceGroup')}}</span
                >
                <span
                    :class="
                        unit.content.type == 3
                            ? 'rhetorical-type-box-cell-active'
                            : 'rhetorical-type-box-cell'
                    "
                    @click="unit.content.type = 3"
                    >{{$t('flowComponent.actionTransfer.directConnection')}}</span
                >
            </div>
        </div>

        <div v-if="unit.content.type == 1" class="artificial-send-text-value">
            <div class="tal artificial-text-title">{{$t('flowComponent.transferToManualCopy')}}</div>
            <div class="rhetorical-question-input-cell">
                <el-input
                    type="textarea"
                    :rows="2"
                    v-model="unit.content.random.text"
                    :placeholder="$t('flowComponent.transferToManualCopyPla')"
                ></el-input>
            </div>
        </div>
        <div v-if="unit.content.type == 2" class="artificial-send-text-value">
            <div class="tal artificial-text-title">{{$t('flowComponent.transferToManualCopy')}}</div>
            <div class="rhetorical-question-input-cell">
                <el-input
                    type="textarea"
                    :rows="2"
                    v-model="unit.content.select.text"
                    :placeholder="$t('flowComponent.transferToManualCopyPla')"
                ></el-input>
            </div>
            <div style="margin-top: 8px" class="tal artificial-text-title">
                {{$t('flowComponent.actionTransfer.customerServiceGroup')}}
            </div>
            <div
                id="btn-list-box-sort"
                class="cell-content answer-radio-box btn-list-box"
            >
                <div
                    :key="radioTextIndex"
                    v-for="(tag, radioTextIndex) in unit.content.select.options"
                    class="btn-list-cell"
                >
                    <span class="btn-list-cell-text">{{ tag.name }}</span>
                    <span
                        class="sortIcon my-handle-btn iconfont guoran-a-16-10"
                        @mouseenter="sortClickBtn($event, tag, unitIndex)"
                    >
                    </span>
                    <span
                        @click="
                            deleteSelectCustRadio(unitIndex, radioTextIndex)
                        "
                        class="my-handle-close iconfont guoran-a-16-09"
                    >
                    </span>
                </div>
                <span
                    @click="addSelectCustRadio"
                    class="cell-title-setting-inner"
                    ><i class="el-icon-setting"></i
                ></span>
            </div>
        </div>
        <div v-if="unit.content.type == 3" class="artificial-send-text-value">
            <div class="tal artificial-text-title">{{$t('flowComponent.transferToManualCopy')}}</div>
            <div class="rhetorical-question-input-cell">
                <el-input
                    type="textarea"
                    :rows="2"
                    v-model="unit.content.direct.text"
                    :placeholder="$t('flowComponent.transferToManualCopyPla')"
                ></el-input>
            </div>
            <div style="margin-top: 8px" class="tal artificial-text-title">{{$t('flowComponent.actionTransfer.directConnection')}}</div>
            <div
                class="
                    rhetorical-question-input-cell
                    rhetorical-question-input-cell-select
                "
            >
                <el-select
                    v-model="unit.content.direct.customer"
                    size="small"
                    :placeholder="$t('common.selectPlaceholder')"
                    width="100%"
                    :no-data-text="$t('flowComponent.actionTransfer.noGroup')"
                >
                    <el-option
                        v-for="item in custonerOptions"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    ></el-option>
                </el-select>
            </div>
        </div>
        <div class="simple-cell-if-offline-title">{{$t('flowComponent.notOnline')}}</div>
        <div class="simple-cell-if-offline">
            <div class="left-select">
                <el-select
                    v-model="unit.content.ifOffline.scope"
                    size="small"
                    :placeholder="$t('common.selectPlaceholder')"
                    @change="submitErrorChangeOptions($event, index)"
                >
                    <el-option
                        v-for="item in jumpTypeAddContentOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                </el-select>
            </div>
            <div
                v-if="unit.content.ifOffline.scope === 'NODE'"
                class="right-select"
            >
                <!-- <el-select
                    v-model="unit.content.ifOffline.value"
                    :popper-append-to-body="false"
                    filterable
                    size="small"
                    placeholder="请输入选择名称"
                >
                    <el-option
                        v-for="item in nodeOptions"
                        :key="item.id"
                        :popper-class="
                            'pop_my' + item.classPop + item.parentNum
                        "
                        :label="item.name"
                        :value="item.id"
                    ></el-option>
                </el-select> -->
                <SelectAddnode
                    :nodeOptions="nodeOptions"
                    :nodeId="unit.content.ifOffline.value"
                    :defaultParentNodeId="nodeId"
                    :nodeSelectARef="`actionTransfer_offline_${unitIndex}`"
                    :intentId="intentId"
                    @getNodeOptions="getNodeOptions"
                    @setAddNodeId="setAddNodeId"
                    :index="unitIndex"
                    :isBotLink="isBotLink"
                ></SelectAddnode>
            </div>
            <div
                v-if="unit.content.ifOffline.scope === 'INTENT'"
                class="right-select select-cell-sub-content-aft"
                ref="selectCellSubContentAftWidth"
            >
                <el-cascader
                    v-if="!isBotLink"
                    filterable
                    v-model="unit.content.ifOffline.value"
                    size="small"
                    :key="`action_trasf_ofline${unitIndex}`"
                    :options="intentTree4Radio"
                    :props="{
                        emitPath: false,
                        label: 'name',
                        value: 'id',
                    }">
                </el-cascader>
                <template v-else>
                    <el-popover
                        ref="actionTransferProver"
                        placement="bottom"
                        :width="intentSelectAndSearchPoperWidth"
                        trigger="click"
                        popper-class="set-welcome-message-search-intent-poprver"
                        :visible-arrow="false"
                        @show="showPover(unit.content.ifOffline)">
                        <el-input
                            class="search-intent-input"
                            size="small"
                            :placeholder="$t('flowComponent.searchOrSelectIntent')"
                            v-model="unit.content.ifOffline.valueText"
                            slot="reference"
                            @input="inputFn($event,unit.content.ifOffline.valueText)">
                            <i slot="suffix" class="el-icon-arrow-down"></i>
                        </el-input>
                        <el-tree
                            class="choose-intent-tree"
                            :style="{width: intentSelectAndSearchPoperWidth + 'px'}"
                            :data="intentTree4Radio"
                            :props="{
                                children: 'children',
                                label: 'name',
                                id:'id',
                                isLeaf: 'isLeafNode',
                            }"
                            :filter-node-method="filterNode"
                            node-key="id"
                            ref="tree">
                            <span :class="['custom-tree-node',  data.id.indexOf('B') === -1 && data.id.indexOf('S') === -1 && data.id.indexOf('T') === -1  ? '' : 'disabled']" slot-scope="{node,data}"   @click="onNodeClick(data,node,unit.content.ifOffline)">
                                <span class="custom-tree-node-left">
                                    <span :class="['data-label']">{{ data.name }}</span>
                                </span>
                                <i class="el-icon-check" v-if="data.checked"></i>
                            </span>
                        </el-tree>
                    </el-popover>
                </template>
            </div>
            <div
                v-if="unit.content.ifOffline.scope === 'CONTENT'"
                class="right-select"
            >
                <el-input
                    size="small"
                    v-model="unit.content.ifOffline.value"
                    maxlength="2000"
                    :placeholder="$t('flowComponent.textAnswer')"
                ></el-input>
            </div>
        </div>
        <popup
            v-if="selectCustRadioVisible"
            @closeEvent="selectCustRadioVisible = false"
        >
            <div slot="popup-name">{{$t('flowComponent.actionTransfer.selectCustRadioTitle')}}</div>
            <div slot="popup-con">
                <div class="select-cell-title">
                    <span class="select-cell-title-name">{{$t('flowComponent.actionTransfer.radioName')}}</span>
                    <span class="select-cell-title-action"
                        >{{$t('flowComponent.actionTransfer.transfer')}}</span
                    >
                </div>
                <div id="select-list-sort" class="select-list">
                    <div
                        v-for="(cell, cellIndex) in activeOptions"
                        :key="cellIndex"
                        class="select-list-cell"
                    >
                        <span class="select-cell-move icon-handle-radio-btn">
                            <i
                                class="iconfont guoran-tongyichicun-16-10-paixu"
                            ></i>
                        </span>
                        <div class="select-cell-name">
                            <el-input
                                size="small"
                                v-model="cell.name"
                                maxlength="40"
                                :placeholder="$t('flowComponent.actionTransfer.nameTip')"
                            ></el-input>
                        </div>
                        <div class="select-cell-value">
                            <el-select
                                v-model="cell.customerId"
                                size="small"
                                :no-data-text="$t('flowComponent.actionTransfer.noGroup')"
                                :placeholder="$t('common.selectPlaceholder')"
                            >
                                <el-option
                                    v-for="item in custonerOptions"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </div>
                        <span
                            v-if="cellIndex === 0"
                            @click="addRCell"
                            class="answer-text-cell-handle-icon-add"
                        >
                            <i class="el-icon-plus"></i>
                        </span>
                        <span
                            v-else
                            @click="deleteRCell(cellIndex)"
                            class="answer-text-cell-handle-icon-delete"
                        >
                            <i class="el-icon-close"></i
                        ></span>
                    </div>
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button
                    @click="selectCustRadioVisible = false"
                    plain
                    size="small"
                    >{{$t('common.cancel')}}</el-button
                >
                <el-button
                    type="primary"
                    @click="confirmselectCustRadioVisible"
                    size="small"
                    >{{$t('common.confirm')}}</el-button
                >
            </div>
        </popup>
    </div>
</template>
<script>
import SelectAddnode from "../cell/SelectAddnode.vue";
import popup from "./../../../../components/popupNew.vue";
import Sortable from "sortablejs";
export default {
    name: "actiontransfer",
    components: {
        SelectAddnode,
        popup,
    },
    props: [
        "unit",
        "unitIndex",
        "intentOptions",
        "nodeOptions",
        "intentTree4Radio",
        "nodeId",
        "intentId",
        "custonerOptions",
        "isBotLink"
    ],
    data() {
        return {
            jumpTypeAddContentOptions: [
                {
                    value: "NODE",
                    label: "跳转到节点",
                },
                {
                    value: "INTENT",
                    label: "跳转到意图",
                },
                {
                    value: "CONTENT",
                    label: "向用户发送内容",
                },
            ],
            selectCustRadioVisible: false, // 转人工客服组选择按钮弹窗
            artificialSendTextValue: 1,
            selectCustRadioObj: {
                name: "",
                value: "",
                customerId: "",
            },
            selectCustRadioConfigIndex: [],
            activeOptions: [],
            isCheck:true,
            intentSelectAndSearchPoperWidth: 300,
        };
    },
    methods: {
        submitErrorChangeOptions() {
            this.unit.content.ifOffline.value = "";
        },
        // 客服组点选类型配置
        // 编辑点选
        addSelectCustRadio() {
            this.activeOptions = JSON.parse(
                JSON.stringify(this.unit.content.select.options)
            );
            if (this.activeOptions.length === 0) {
                this.activeOptions.push({
                    name: "",
                    customerId: this.custonerOptions[0].id || "",
                    value: "",
                });
            }
            this.selectCustRadioVisible = true;
            this.$nextTick(() => {
                this.popSortClickBtn();
            });
        },
        addRCell() {
            this.activeOptions.push({
                name: "",
                customerId: this.custonerOptions[0].id || "",
                value: "",
            });
        },
        // 删除点选
        deleteRCell(cellIndex) {
            this.activeOptions.splice(cellIndex, 1);
        },
        deleteSelectCustRadio(index, radioTextIndex) {
            this.unit.content.select.options.splice(radioTextIndex, 1);
        },

        cancelSelectCustrRadioVisible() {
            this.selectCustRadioVisible = false;
        },

        confirmselectCustRadioVisible() {
            let flag = true;
            this.activeOptions.forEach((item) => {
                if (item.name.trim() === "") {
                    flag && this.$message.error("点选按钮名称不能为空！");
                    flag = false;
                }
            });
            if (flag) {
                this.unit.content.select.options = [...this.activeOptions];
                this.selectCustRadioVisible = false;
            }
        },
        getNodeOptions() {
            this.$emit("getNodeOptions", true);
        },
        setAddNodeId(nodeId) {
            this.$set(this.unit.content.ifOffline, "value", nodeId);
        },

        sortClickBtn(e, items, index) {
            e.stopPropagation();
            let _this = this;
            let idname = "btn-list-box-sort";
            let box = document.getElementById(idname);
            var Sortables = new Sortable(box, {
                animation: 300,
                easing: "cubic-bezier(1, 0, 0, 1)",
                handle: ".my-handle-btn",
                group: "shared",
                forceFallback: true,
                onUpdate: function (event) {
                    //修改items数据顺序
                    let newIndex = event.newIndex,
                        oldIndex = event.oldIndex,
                        $li = box.children[newIndex],
                        $oldLi = box.children[oldIndex];
                    // 先删除移动的节点
                    box.removeChild($li);
                    // 再插入移动的节点到原有节点，还原了移动的操作
                    if (newIndex > oldIndex) {
                        box.insertBefore($li, $oldLi);
                    } else {
                        box.insertBefore($li, $oldLi.nextSibling);
                    }
                    // 更新items数组
                    let item = _this.unit.content.select.options.splice(
                        oldIndex,
                        1
                    );
                    _this.unit.content.select.options.splice(
                        newIndex,
                        0,
                        item[0]
                    );
                },
            });
            this.unit.content.select.options =
                _this.unit.content.select.options;
        },

        popSortClickBtn() {
            const box = document.querySelector("#select-list-sort");
            const _this = this;
            new Sortable(box, {
                ghostClass: "blue-background-class",
                handle: ".icon-handle-radio-btn",
                animation: 500,
                sort: true,
                onUpdate: function (event) {
                    //修改items数据顺序
                    let newIndex = event.newIndex,
                        oldIndex = event.oldIndex,
                        $li = box.children[newIndex],
                        $oldLi = box.children[oldIndex];
                    // 先删除移动的节点
                    box.removeChild($li);
                    // 再插入移动的节点到原有节点，还原了移动的操作
                    if (newIndex > oldIndex) {
                        box.insertBefore($li, $oldLi);
                    } else {
                        box.insertBefore($li, $oldLi.nextSibling);
                    }
                    // 更新items数组
                    let item = _this.activeOptions.splice(oldIndex, 1);
                    _this.activeOptions.splice(newIndex, 0, item[0]);
                    // 下一个tick就会走patch更新
                },
            });
            this.activeOptions = _this.activeOptions;
        },
        inputFn(val,item){
            this.$refs.tree[0].filter(val);
            this.$forceUpdate()
        },
        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
        onNodeClick(data,node,item){
            if(data.id.indexOf('B') > -1 || data.id.indexOf('S') > -1 || data.id.indexOf('T') > -1 ) return false;
            this.$set(item,'valueText',data.name);
            this.$set(item,'valueList',[node.parent.parent.parent.data.id,node.parent.parent.data.id,node.parent.data.id,data.id])
            this.$set(item,'value',data.id)
            this.$set(data,'checked',true)
            this.$refs['actionTransferProver'].doClose()
        },
        showPover(data){

            // 为poper设置宽度
            if (this.$refs.selectCellSubContentAftWidth) {
                this.intentSelectAndSearchPoperWidth = window.getComputedStyle(this.$refs.selectCellSubContentAftWidth).width;
                if (
                    this.intentSelectAndSearchPoperWidth.indexOf("px") > -1
                ) {
                    this.intentSelectAndSearchPoperWidth =
                        this.intentSelectAndSearchPoperWidth.replace("px", "") - 30;
                } else {
                    this.intentSelectAndSearchPoperWidth = 300;
                }
            } else {
                this.intentSelectAndSearchPoperWidth = 300;
            }

            let list = JSON.parse(JSON.stringify(this.intentTree4Radio))
            list.forEach(botItem => {
                console.log(botItem,'botItem');
                botItem.children.forEach(skillItem => {
                    skillItem.children.forEach(typeItem => {
                        typeItem.children.forEach(intentItem => {
                            intentItem.checked = false;
                            if(data.value == intentItem.id){
                                intentItem.checked = true;
                            } 
                        })
                    })
                })
            })
            this.intentTree4Radio =  JSON.parse(JSON.stringify(list))
            this.$forceUpdate()
        },
    },
    mounted() {
        this.$nextTick(() => {
            // 应用配置欢迎语时,默认为第一个机器人的'人工客服不在线'意图
            if(this.isBotLink){
                this.jumpTypeAddContentOptions = [ {
                    value: "INTENT",
                    label: "跳转到意图",
                },
                {
                    value: "CONTENT",
                    label: "向用户发送内容",
                }]
                if(this.unit.content.ifOffline.value === ''){
                    let botObj = this.intentTree4Radio[0];
                    console.log(botObj,'botObj');
                    let valueText = botObj.name + ' / ';
                    botObj.children.forEach(skillItem => {
                        skillItem.children.forEach(typeItem => {
                            typeItem.children.forEach(intentItem => {
                                if(intentItem.name === '人工客服不在线'){
                                     valueText += skillItem.name + ' / ' + typeItem.name + ' / ' + intentItem.name;
                                    this.unit.content.ifOffline.value = intentItem.id;
                                    this.unit.content.ifOffline.valueText = valueText;
                                }
                            })
                        })
                    })
                }
                this.$forceUpdate()
            }
            
            console.log(574, this.unit);
        })
    },
};
</script>
<style lang="less" scoped>
@import "./../../../../assets/less/channel/new_ui.less";
.choose-intent-tree {
    max-height: 150px;
    overflow-x: hidden;
    overflow-y: auto;
}
.action-transfer {
    .select-cell-title {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 12px;
        .select-cell-title-name {
            flex: none;
            margin-left: 24px;
            width: 170px;
            text-align: left;
        }
    }
    .select-list-cell {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 6px;
        .select-cell-move {
            color: #366aff;
            font-size: 20px;
            font-weight: 500;
            margin-right: 8px;
        }
        .select-cell-name {
            width: 155px;
            flex: none;
        }
        .select-cell-value {
            flex: auto;
            display: flex;
            justify-content: flex-start;
            border: 1px solid #dcdfe6;
            border-radius: 5px;
            margin-left: 15px;
            /deep/.el-select {
                width: 100%;
            }
            .select-type {
                width: 90px;
            }
            /deep/.inline-input {
                width: 200px;
                padding-right: 0;
            }
            /deep/.el-cascader {
                width: 200px;
                padding-right: 0;
            }
            /deep/.el-input__inner {
                border: none;
                padding-right: 0;
            }
            // /deep/.el-input__suffix {
            //     display: none;
            // }
        }
    }
    .btn-list-box {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        .cell-title-setting-inner {
            cursor: pointer;
            margin-top: 10px;
            display: inline-block;
            height: 28px;
            width: 28px;
            border-radius: 50%;
            border: solid 1px #366aff;
            text-align: center;
            line-height: 30px;
            i {
                font-size: 14px;
                color: #366aff;
                font-weight: 700;
            }
        }

        .btn-list-cell:hover {
            .my-handle-btn {
                display: inline-block;
            }
            .my-handle-close {
                display: inline-block;
            }
        }
        .btn-list-cell {
            height: 28px;
            line-height: 28px;
            border-radius: 14px;
            padding: 0;
            margin-right: 6px;
            margin-top: 10px;
            border: solid 1px #366aff;
            color: #366aff;
            cursor: pointer;
            position: relative;
            background-color: white;
            .btn-list-cell-text {
                display: inline-block;
                height: 28px;
                line-height: 28px;
                padding: 0 16px;
            }
            .my-handle-btn {
                display: none;
                position: absolute;
                top: -8px;
                right: 26px;
                height: 16px;
                line-height: 16px;
                width: 16px;
                border-radius: 8px;
                background-color: white;
            }
            .my-handle-close {
                display: none;
                position: absolute;
                top: -8px;
                right: 3px;
                height: 16px;
                line-height: 16px;
                width: 16px;
                border-radius: 8px;
                background-color: white;
            }
        }
    }
    .simple-cell-if-offline-title {
        text-align: left;
        font-size: 14px;
        font-weight: 400;
        color: #616161;
        line-height: 16px;
        margin-bottom: 6px;
        margin-top: 12px;
    }
    .simple-cell-if-offline {
        display: flex;
        justify-content: flex-start;
        border: 1px solid #e0e6f7;
        background-color: white;
        border-radius: 5px;
        /deep/.inline-input {
            width: 100%;
            padding-right: 0;
        }
        /deep/.el-cascader {
            width: 100%;
            padding-right: 0;
        }
        /deep/.el-input__inner {
            border: none;
            padding-right: 0;
        }
        /deep/.el-input__suffix {
            display: none;
        }
        .left-select {
            width: 120px;
            flex: none;
        }
        .right-select {
            flex: auto;
            height: 32px;
            .el-select {
                width: 100%;
            }
        }
    }
    .artificial-send-text-value {
        .artificial-text-title {
            font-size: 14px;
            font-weight: 400;
            color: #616161;
            line-height: 38px;
        }
        .rhetorical-type-box {
            margin-top: 8px;
            display: flex;
            justify-content: space-between;
            .rhetorical-type-box-cell {
                width: 32%;
                flex: none;
                text-align: center;
                height: 32px;
                line-height: 32px;
                background: #ffffff;
                border: 1px solid #e0e6f7;
                border-radius: 5px;
                color: #a9b3c6;
                cursor: pointer;
            }
            .rhetorical-type-box-cell-active {
                width: 32%;
                flex: none;
                text-align: center;
                height: 32px;
                line-height: 32px;
                background: #366aff;
                border: 1px solid #366aff;
                border-radius: 5px;
                color: white;
                cursor: pointer;
            }
        }
    }
}
</style>