<template>
    <div id="detail-page-header">
        <div class="header-title-c-l">
            <div class="header-back" @click="goBack">
                <i class="iconfont guoran-a-18-22"></i>
            </div>
            <slot name="header-name"> </slot>
            <slot name="header-des"> </slot>
            <slot name="edit-title"> </slot>
        </div>
        <div class="header-title-c-r">
            <div class="new-help-tips" @click="gotoHelp" v-if=" uid != 20281 && uid != 117431 && !isE() && !main_isLishi">
                <i class="iconfont guoran-tongyichicun-xinshouyindao"></i>
                <span>{{$t('index.helpCenter')}}</span>
            </div>
            <slot name="header-title-c-r-btn"></slot>
            <div v-if="false" class="header-title-c-r-border"></div>
            <div class="header-title-c-r-ava">
                <el-dropdown @command="topHandleCommand">
                    <span class="el-dropdown-link">
                        <span class="head_portrait">
                            <el-avatar
                                :size="37"
                                :src="userInfo.profilePhoto | filterProfilePhoto"
                            ></el-avatar>
                        </span>
                    </span>
                    <el-dropdown-menu slot="dropdown" style="min-width: 150px">
                        <el-dropdown-item
                            style="
                                color: black;
                                word-break: break-word;
                                border-bottom: 2px solid #f5f8fb;
                                line-height: 30px;
                            "
                            >{{
                                userInfo.realName
                                    ? userInfo.realName
                                    : userInfo.username
                            }}</el-dropdown-item
                        >
                        <el-dropdown-item command="profile"
                            ><i
                                class="el-icon-user-solid"
                                style="font-size: 14px"
                            ></i
                            >{{$t('common.personInfo')}}</el-dropdown-item
                        >
                        <!-- <el-dropdown-item
                            command="business"
                            class="borderBottom"
                            v-if="roles === '1' || roles === '6'"
                            ><i
                                class="el-icon-office-building"
                                style="font-size: 14px"
                            ></i
                            >企业管理</el-dropdown-item
                        >
                        <el-dropdown-item command="change" class="borderBottom"
                            ><i
                                class="arsenal_icon arsenalzhuanjie"
                                style="font-size: 14px"
                            ></i
                            >切换系统</el-dropdown-item
                        > -->
                        <el-dropdown-item command="logout"
                            ><i
                                class="el-icon-switch-button"
                                style="font-size: 14px"
                            ></i
                            >{{$t('common.logOut')}}</el-dropdown-item
                        >
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
    </div>
</template>

<script>
import { requestUrl } from "../api/requestUrl";
import { redirect2LoginPage } from "../const/redirectUri";

export default {
    name: "detailPageHeader",
    data() {
        return {
            dataSrc: "",
            userInfo: {
                profilePhoto:
                    "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
            },
            roles: "",
            uid:""
        };
    },
    mounted() {
        this.uid = localStorage.getItem('_uid')
        this.$nextTick(() => {
            let userInfo = localStorage.getItem("userInfo");
            if (userInfo && userInfo !== "{}") {
                this.userInfo = JSON.parse(userInfo);
            }
            this.roles = localStorage.getItem("roleId");
        });
    },
    filters:{
        filterProfilePhoto(val) {
            let str = val;;
            if (val == '' || val == null || val == 'null') {
                str = "https://static.guoranbot.com/cdn-office-website/images/default_avt_ui.png"; 
            } 
            return str;
        }
    },
    methods: {
        // 跳转到帮助页面
        gotoHelp() {
            //window.open("https://showdoc.askbot.cn/web/#/5?page_id=24");
            this.$router.push({
                name: "help",
                params: {
                    helpSrc:
                        "https://showdoc.askbot.cn/web/#/readonly/6?page_id=43",
                },
            });
        },
        goBack() {
            this.$emit("goBack");
        },
        topHandleCommand(command) {
            console.log("======command:", command);
            if (command === "logout") {
                this.$http.delete("/api/sso/auth/sign-out").then((res) => {
                    console.log("=======signout result:", res);
                    if (res.status === 200) {
                        redirect2LoginPage();
                    } else {
                        this.$message.error(res.message);
                    }
                });
            }
            if (command === "profile") {
                this.$router.push({ path: "_personal" });
            }
            if (command === "business") {
                let windo = window.open("_blank");
                windo.location = requestUrl[process.env.NODE_ENV].portal;
                windo.location = requestUrl[process.env.NODE_ENV].test;
            }
            if (command === "change") {
                window.open(requestUrl[process.env.NODE_ENV].portal);
            }
        },
    },
};
</script>

<style scoped lang="less">
#detail-page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 62px;
    background-color: white;
    box-shadow: 0px 0px 17px 0px rgba(29, 55, 129, 0.07);
    border-radius: 5px;
    .header-title-c-l {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .header-title-c-r {
        display: flex;
        align-items: center;
        padding-right: 20px;
        .el-button--primary {
            background-color: #366aff!important;
            border-color: #366aff;
            border-radius: 17px;
            // font-size: 14px;
            font-weight: 500;
        }
        .el-button--default {
            color: #366aff;
            border-color: #a1b9ff!important;
            border-radius: 17px;
            // font-size: 14px;
            font-weight: 500;
        }
        .el-button--small {
            padding: 7px 15px 8px;
        }
        .header-title-c-r-border {
            width: 1px;
            height: 22px;
            background-color: #e0e6f7;
            margin: 0 14px;
        }
    }
    .new-help-tips {
        margin-right: 12px;
        margin-left: -8px;
        width: 80px;
        padding: 0 10px 0 6px;
        height: 29px;
        background: #ffffff;
        border: 1px solid #a1b9ff;
        border-radius: 17px;
        font-size: 14px;
        color: #366aff;
        cursor: pointer;
        display: flex;
        align-items: center;
        i {
            font-size: 10px !important;
            padding-right: 0px !important;
            color: #fff !important;
            margin-right: 4px;
            width: 20px;
            height: 20px;
            background: linear-gradient(224deg, #749bff, #366aff);
            border-radius: 50%;
            display: inline-block;
            line-height: 20px;
            text-align: center;
        }
    }
    .header-back {
        width: 62px;
        height: 62px;
        background-color: #aab9e1;
        margin-right: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 5px;
        .iconfont {
            color: white;
            font-size: 18px;
        }
    }
    .header-name {
        font-size: 15px;
        display: flex;
        align-items: center;
        .guoran-a-14-04 {
            font-size: 14px;
        }
        .guoran-a-14-05 {
            font-size: 14px;
        }
        .guoran-a-18-08 {
            font-size: 18px;
        }
        .iconfont {
            margin-right: 8px;
            color: #616161;
        }
        .template-name {
            padding-right: 10px;
            max-width: 350px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    .header-des {
        color: #999999;
        margin-right: 10px;
        font-size: 15px;
        max-width: calc(80vw - 500px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-left: 11px;
        border-left: 2px solid #366aff;
    }
    .edit-title {
        color: #7aa8ff;
        width: 29px;
        height: 29px;
        background-color: #edf2ff;
        border-radius: 5px;
        border: 1px solid #a1b9ff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-left: 20px;
    }
}
</style>