<template>
    <div id="askBot">
        <div class="checkAll" v-if="privilegeTree.id !== 'WORKORDER'">
            <el-checkbox
                v-model="privilegeTree.checked"
                :indeterminate="isIndeterminate"
                @change="handleCheckAllChange"
                :disabled="isDisabled"
                >全选</el-checkbox
            >
        </div>
        <div class="manage-permissions">
            <div
                v-for="(item, index) in privilegeTree.children"
                :key="index"
                class="manage-permissions-title"
            >
                <div class="oneLevel">
                    <div style="display: flex; align-items: center">
                        <el-checkbox
                            v-model="item.checked"
                            :indeterminate="item.isIndeterminate"
                            @change="
                                checkUnit(
                                    $event,
                                    item.id,
                                    privilegeTree.children
                                )
                            "
                            :disabled="isDisabled"
                        >
                            {{ item.name }}
                        </el-checkbox>
                        <div v-if="item.type === 1">
                            <span style="margin-right: 10px; color: #606266">{{
                                item.title
                            }}</span>
                            <el-select
                                v-model="item.value"
                                multiple
                                placeholder="请选择"
                                size="small"
                                @change="changeSelect($event, item.items)"
                                :disabled="isDisabled"
                            >
                                <el-option
                                    v-for="role in item.items"
                                    :key="role.id"
                                    :label="role.name"
                                    :value="role.id"
                                >
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
                <div class="manage-permissions-list">
                    <div
                        v-for="(its, ind) in item.children"
                        :key="ind"
                        class="manage-permissions-list-item"
                        :style="{
                            width: privilegeTree.id == 'WORKORDER' ? '100%' : ''
                        }"
                    >   
                        <div
                            :class="(its.children !== null && its.children.length !== 0)? 'twoLevel' : ''"
                            style="display: flex; align-items: center"
                        >
                            <el-checkbox
                                v-model="its.checked"
                                :indeterminate="its.isIndeterminate"
                                @change="
                                    checkUnit($event, its.id, item.children)
                                "
                                :disabled="isDisabled"
                                :style="its.type === 2 ? 'min-width:100px' : ''"
                                >{{ its.name }}</el-checkbox
                            >
                            <div v-if="its.type === 2">
                                <span
                                    style="color: #606266; margin-right: 10px"
                                    >{{ its.title }}</span
                                >
                                <el-radio-group
                                    v-model="its.value"
                                    @change="changeRadio($event, its)"
                                    :disabled="isDisabled"
                                >
                                    <el-radio
                                        v-for="radio in its.items"
                                        :key="radio.id"
                                        :label="radio.id"
                                    >
                                        {{ radio.name }}
                                    </el-radio>
                                </el-radio-group>
                            </div>
                        </div>
                        <div
                            :class="
                                its.children !== null ? 'list-item-item' : ''
                            "
                        >
                            <div
                                v-for="(list, listIndex) in its.children"
                                :key="listIndex"
                                :class="[
                                    its.children !== null ? 'item-item' : '',
                                    list.children !== null
                                        ? 'last-item-item'
                                        : '',
                                ]"
                            >
                                <el-checkbox
                                    :indeterminate="list.isIndeterminate"
                                    v-model="list.checked"
                                    @change="
                                        checkUnit($event, list.id, its.children)
                                    "
                                    :disabled="isDisabled"
                                    :class="
                                        (list.children !== null && list.children.length !== 0)
                                            ? 'threeLevel'
                                            : ''
                                    "
                                    >{{ list.name }}</el-checkbox
                                >
                                <div
                                    :class="
                                        list.children !== null
                                            ? 'lastChildren'
                                            : ''
                                    "
                                >
                                    <div
                                        v-for="(
                                            listChildren, listChildrenIndex
                                        ) in list.children"
                                        :key="listChildrenIndex"
                                        class="listChildren"
                                    >
                                        <el-checkbox
                                            v-model="listChildren.checked"
                                            @change="
                                                childrenChange(
                                                    $event,
                                                    listChildren,
                                                    its.children
                                                )
                                            "
                                            :disabled="isDisabled"
                                            >{{
                                                listChildren.name
                                            }}</el-checkbox
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import roleTable from "./roleTable";
import { operable } from "../../js/operable";

export default {
    name: "askBot",
    data() {
        return {
            checkAll: false,
            manageList: [
                {
                    name: "机器人管理权限",
                    check: false,
                    id: 1,
                    list1: [
                        {
                            name: "机器人管理-创建机器人",
                            check: false,
                            id: 11,
                        },
                        {
                            name: "机器人管理-编辑机器人",
                            check: false,
                            id: 12,
                        },
                        {
                            name: "机器人管理-删除机器人",
                            check: false,
                            id: 13,
                        },
                    ],
                },
                {
                    name: "知识库管理权限",
                    check: false,
                    id: 2,
                    list1: [
                        {
                            name: "新增意图",
                            check: false,
                            id: 21,
                        },
                        {
                            name: "编辑意图",
                            check: false,
                            id: 22,
                        },
                        {
                            name: "删除意图",
                            check: false,
                            id: 23,
                        },
                    ],
                },
            ],
            AuthoCheck: [0, 0],
            selectRole: [0],
            isIndeterminate: false,
            isTwoIndeterminate: false,
            firstCheck: 0,
            isDisabled: false,
            rolePrivilegesList: [],
        };
    },
    props: ["privilegeTree", "handleClicked", "roleType", "system"],
    mounted() {
        console.log(this.privilegeTree,'privilegeTree');
        this.$nextTick(() => {
            setTimeout(() => {
                this.unitCheck(this.privilegeTree.children);
            }, 1000);
        });
        this.rolePrivilegesList = this.getRolePrivileges();
        if (this.$route.query.type === "details") {
            this.isDisabled = true;
        } else if (
            operable("PORTAL_MANAGEMENT_ROLE_UPDATE") &&
            this.$route.query.type === "edit"
        ) {
            this.isDisabled = false;
        } else if (
            operable("PORTAL_MANAGEMENT_ROLE_ADD") &&
            this.$route.query.type === "add"
        ) {
            this.isDisabled = false;
        } else {
            this.isDisabled = true;
        }
    },
    watch: {
        privilegeTree: {
            handler(value) {
                this.forEachPrivilegeTree(value.children);
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        //遍历角色
        forEachPrivilegeTree(privilegeTree) {
            let firstCheck = 0;
            let twoCheck = [];
            let threeCheck = [];
            let fourCheck = [];
            let len = [];
            let threeLen = [];
            let fourLen = [];
            let allLen = privilegeTree.length;
            privilegeTree.forEach((firstItem, firstIndex) => {
                if (firstItem.checked === true ) {
                    firstCheck += 1;
                }
                if (firstItem.children !== null) {
                    twoCheck[firstIndex] = 0;
                    len[firstIndex] = 0;
                    len[firstIndex] +=
                        privilegeTree[firstIndex].children.length;
                    allLen += firstItem.children.length;
                    firstItem.children.forEach((twoItem, twoIndex) => {
                        twoItem.isIndeterminate = false;
                        if (twoItem.checked === true) {
                            twoCheck[firstIndex] += 1;
                            firstCheck += 1;
                        }
                        if (twoItem.children !== null) {
                            threeCheck[twoIndex] = 0;
                            threeLen[twoIndex] = 0;
                            len[firstIndex] +=
                                privilegeTree[firstIndex].children[
                                    twoIndex
                                ].children.length;
                            threeLen[twoIndex] +=
                                privilegeTree[firstIndex].children[
                                    twoIndex
                                ].children.length;
                            allLen += twoItem.children.length;
                            twoItem.children.forEach(
                                (threeItem, threeIndex) => {
                                    if (threeItem.checked === true) {
                                        threeCheck[twoIndex] += 1;
                                        twoCheck[firstIndex] += 1;
                                        firstCheck += 1;
                                    }
                                    if (threeItem.children !== null) {
                                        fourCheck[threeIndex] = 0;
                                        fourLen[threeIndex] = 0;
                                        len[firstIndex] +=
                                            privilegeTree[firstIndex].children[
                                                twoIndex
                                            ].children[
                                                threeIndex
                                            ].children.length;
                                        threeLen[twoIndex] +=
                                            privilegeTree[firstIndex].children[
                                                twoIndex
                                            ].children[
                                                threeIndex
                                            ].children.length;
                                        fourLen[threeIndex] +=
                                            privilegeTree[firstIndex].children[
                                                twoIndex
                                            ].children[
                                                threeIndex
                                            ].children.length;
                                        allLen += threeItem.children.length;
                                        threeItem.children.forEach(
                                            (fourthItem) => {
                                                if (
                                                    fourthItem.checked === true
                                                ) {
                                                    fourCheck[threeIndex] += 1;
                                                    twoCheck[firstIndex] += 1;
                                                    threeCheck[twoIndex] += 1;
                                                    firstCheck += 1;
                                                }
                                            }
                                        );
                                    }
                                }
                            );
                        }
                    });
                }
            });
            if (firstCheck !== allLen && firstCheck > 0) {
                this.isIndeterminate = true;
                this.privilegeTree.checked = true;
            } else if (firstCheck === allLen) {
                this.isIndeterminate = false;
                this.privilegeTree.checked = true;
            } else {
                this.isIndeterminate = false;
                this.privilegeTree.checked = false;
            }
            privilegeTree.forEach((firstItem, firstIndex) => {
                if (firstItem.children !== null) {
                    firstItem.children.forEach((twoItem, twoIndex) => {
                        if (
                            twoCheck[firstIndex] !== len[firstIndex] &&
                            twoCheck[firstIndex] > 0
                        ) {
                            privilegeTree[firstIndex].isIndeterminate = true;
                            privilegeTree[firstIndex].checkedAll = false;
                            privilegeTree[firstIndex].checked = true;
                        } else if (twoCheck[firstIndex] === len[firstIndex]) {
                            privilegeTree[firstIndex].isIndeterminate = false;
                            privilegeTree[firstIndex].checkedAll = true;
                            privilegeTree[firstIndex].checked = true;
                        } else {
                            privilegeTree[firstIndex].isIndeterminate = false;
                            privilegeTree[firstIndex].checkedAll = false;
                            privilegeTree[firstIndex].checked = false;
                        }
                        if (twoItem.children !== null) {
                            twoItem.children.forEach(
                                (threeItem, threeIndex) => {
                                    if (
                                        threeCheck[twoIndex] !==
                                            threeLen[twoIndex] &&
                                        threeCheck[twoIndex] > 0
                                    ) {
                                        privilegeTree[firstIndex].children[
                                            twoIndex
                                        ].isIndeterminate = true;
                                        privilegeTree[firstIndex].children[
                                            twoIndex
                                        ].checkedAll = false;
                                        privilegeTree[firstIndex].children[
                                            twoIndex
                                        ].checked = true;
                                    } else if (
                                        threeCheck[twoIndex] ===
                                        threeLen[twoIndex]
                                    ) {
                                        privilegeTree[firstIndex].children[
                                            twoIndex
                                        ].isIndeterminate = false;
                                        privilegeTree[firstIndex].children[
                                            twoIndex
                                        ].checkedAll = true;
                                        privilegeTree[firstIndex].children[
                                            twoIndex
                                        ].checked = true;
                                    } else {
                                        privilegeTree[firstIndex].children[
                                            twoIndex
                                        ].isIndeterminate = false;
                                        privilegeTree[firstIndex].children[
                                            twoIndex
                                        ].checkedAll = false;
                                        privilegeTree[firstIndex].children[
                                            twoIndex
                                        ].checked = false;
                                    }

                                    if (threeItem.children !== null) {
                                        threeItem.children.forEach(
                                            (fourItem) => {
                                                if (
                                                    fourCheck[threeIndex] !==
                                                        fourLen[threeIndex] &&
                                                    fourCheck[threeIndex] > 0
                                                ) {
                                                    privilegeTree[
                                                        firstIndex
                                                    ].children[
                                                        twoIndex
                                                    ].children[
                                                        threeIndex
                                                    ].isIndeterminate = true;
                                                    privilegeTree[
                                                        firstIndex
                                                    ].children[
                                                        twoIndex
                                                    ].children[
                                                        threeIndex
                                                    ].checked = true;
                                                } else if (
                                                    fourCheck[threeIndex] ===
                                                    fourLen[threeIndex]
                                                ) {
                                                    privilegeTree[
                                                        firstIndex
                                                    ].children[
                                                        twoIndex
                                                    ].children[
                                                        threeIndex
                                                    ].isIndeterminate = false;
                                                    privilegeTree[
                                                        firstIndex
                                                    ].children[
                                                        twoIndex
                                                    ].children[
                                                        threeIndex
                                                    ].checked = true;
                                                } else {
                                                    privilegeTree[
                                                        firstIndex
                                                    ].children[
                                                        twoIndex
                                                    ].children[
                                                        threeIndex
                                                    ].isIndeterminate = false;
                                                    privilegeTree[
                                                        firstIndex
                                                    ].children[
                                                        twoIndex
                                                    ].children[
                                                        threeIndex
                                                    ].checked = false;
                                                }
                                            }
                                        );
                                    }
                                }
                            );
                        }
                    });
                }
            });
            privilegeTree.forEach((item) => {
                if (item.id === "OPEN_BOT") {
                    if (item.children !== null) {
                        item.children.forEach((itemChi) => {
                            if (itemChi.id === "OPEN_SKILL") {
                                let bool = false;
                                let intent = false;
                                if (itemChi.children !== null) {
                                    itemChi.children.forEach((children) => {
                                        if (children.id === "OPEN_PROCESS") {
                                            if (children.children !== null) {
                                                children.children.forEach(
                                                    (process) => {
                                                        if (
                                                            process.id ===
                                                            "OPEN_PROCESS_VIEW"
                                                        ) {
                                                            if (
                                                                children.checked
                                                            ) {
                                                                process.checked = true;
                                                            } else {
                                                                process.checked = false;
                                                            }
                                                        }
                                                    }
                                                );
                                            }
                                            if (children.checked) {
                                                bool = true;
                                            } else bool = false;
                                        }
                                        if (children.id === "OPEN_INTENT") {
                                            if (children.children !== null) {
                                                children.children.forEach(
                                                    (intent) => {
                                                        if (
                                                            intent.id ===
                                                            "OPEN_INTENT_VIEW"
                                                        ) {
                                                            if (
                                                                children.checked
                                                            ) {
                                                                intent.checked = true;
                                                            } else {
                                                                intent.checked = false;
                                                            }
                                                        }
                                                    }
                                                );
                                            }
                                        }
                                    });
                                    itemChi.children.forEach((children) => {
                                        if (children.id === "OPEN_SKILL_VIEW") {
                                            if (
                                                (itemChi.checked &&
                                                    !itemChi.isIndeterminate) ||
                                                (itemChi.isIndeterminate &&
                                                    !bool)
                                            ) {
                                                children.checked = true;
                                            }
                                        }
                                    });
                                }
                            }
                        });
                    }
                }
                if (item.id === "OPEN_CONFIG") {
                    this.viewCheck(item, "OPEN_CONFIG_VIEW");
                }
                if (item.id === "OPEN_ACCESS") {
                    this.viewCheck(item, "OPEN_ACCESS_VIEW");
                }
                if (item.id === "OPEN_ENTITY") {
                    this.viewCheck(item, "OPEN_ENTITY_VIEW");
                }
                if (item.id === "OPEN_INTERFACE") {
                    if (item.children !== null) {
                        item.children.forEach((interfaces) => {
                            if (interfaces.id === "OPEN_WEBHOOK") {
                                this.viewCheck(interfaces, "OPEN_WEBHOOK_VIEW");
                            }
                            if (interfaces.id === "OPEN_FORM") {
                                this.viewCheck(interfaces, "OPEN_FORM_VIEW");
                            }
                        });
                    }
                }
                if (item.id === "OPEN_ML") {
                    this.viewCheck(item, "OPEN_ML_VIEW");
                    if (item.children !== null) {
                        let bool = false;
                        item.children.forEach((children) => {
                            if (children.id === "OPEN_ML_RECORD_DELETE") {
                                if (children.checked) {
                                    bool = true;
                                } else bool = false;
                            }
                        });
                        item.children.forEach((children) => {
                            if (children.id === "OPEN_ML_RECORD_VIEW") {
                                if (bool) {
                                    children.checked = true;
                                }
                            }
                        });
                    }
                }
            });
        },
        viewCheck(item, id) {
            if (item.children !== null) {
                item.children.forEach((access) => {
                    if (access.id === id) {
                        if (item.checked) {
                            access.checked = true;
                        } else {
                            access.checked = false;
                        }
                    }
                });
            }
        },
        handleCheckAllChange(value) {
            this.manageCheckAll(this.privilegeTree.children, value);
            this.isIndeterminate = false;
        },
        //点击全选/取消全选
        manageCheckAll(privilegeTree, value) {
            if (value === true) {
                privilegeTree.forEach((item) => {
                    item.checked = true;
                    if (item.children !== null) {
                        return this.manageCheckAll(item.children, true);
                    } else return;
                });
            } else {
                privilegeTree.forEach((item) => {
                    item.checked = false;
                    if (item.children !== null) {
                        return this.manageCheckAll(item.children, false);
                    } else return;
                });
            }
        },
        //选中某一个单元块
        checkORnot(value, check) {
            if (value.children !== null) {
                value.children.forEach((chi) => {
                    chi.checked = check;
                    this.checkORnot(chi, check);
                });
            } else return;
        },
        checkUnit(value, id, list) {
            this.listCheck(list, id, value);
            if (id === "PORTAL_ENTRANCE_IM") {
                this.system.forEach((item) => {
                    if (item.id === "IM") {
                        if (value) {
                            item.checked = true;
                            this.checkORnot(item, value);
                        } else {
                            item.checked = false;
                            this.checkORnot(item, value);
                        }
                    }
                });
            }
            
            // if (id === "PORTAL_ENTRANCE_WORKORDER") {
                // this.system.forEach((item) => {
                //     if (item.id === "WORKORDER") {
                //         if (value) {
                //             item.checked = true;
                //             this.checkORnot(item, value);
                //         } else {
                //             item.checked = false;
                //             this.checkORnot(item, value);
                //         }
                //     }
                // });
            // }
        },
        unitCheck(privilegeTree) {
            let value = [];
            privilegeTree.forEach((item) => {
                if (item.type === 1) {
                    if (item.items !== null) {
                        item.items.forEach((its) => {
                            if (
                                its.id === 0 &&
                                this.$route.query.type === "add"
                            ) {
                                its.checked = true;
                            }
                            if (its.checked === true) {
                                value.push(its.id);
                                this.$set(item, "value", value);
                                /* item.value.push(its.id)*/
                            } else return;
                        });
                    } else {
                        if (item.children !== null) {
                            return this.unitCheck(item.children);
                        } else return;
                    }
                } else if (item.type === 2) {
                    if (item.items !== null) {
                        item.items.forEach((its) => {
                            if (its.checked === true) {
                                this.$set(item, "value", its.id);
                            } else return;
                        });
                    } else {
                        if (item.children !== null) {
                            return this.unitCheck(item.children);
                        } else return;
                    }
                } else {
                    if (item.children !== null) {
                        this.unitCheck(item.children);
                    }
                }
            });
        },
        listCheck(privilegeTree, id, type) {
            privilegeTree.forEach((item) => {
                if (item.id === id) {
                    this.checkCheck(item.children, type);
                } else return this.privilegeTree;
            });
            if((id === 'GNAV0004-02' || id === 'GNAV0004-03') && type === true) {
                privilegeTree.forEach((item) => {
                    if(item.id == 'GNAV0004-01')
                    item.checked = true
                })
            }
        },
        checkCheck(item, type) {
            if (type === true) {
                if (item !== null) {
                    item.forEach((its) => {
                        its.checked = true;
                        this.checkCheck(its.children, type);
                    });
                } else return;
            } else {
                if (item !== null) {
                    item.forEach((its) => {
                        its.checked = false;
                        this.checkCheck(its.children, type);
                    });
                } else return;
            }
        },
        changeSelect(value, item) {
            item.forEach((item1) => {
                item1.checked = false;
                if (value.length > 0) {
                    value.forEach((item2) => {
                        if (item2 === item1.id) {
                            item1.checked = true;
                        }
                    });
                } else {
                    item1.checked = false;
                }
            });
        },
        changeRadio(value, item) {
            item.items.forEach((item1) => {
                if (item1.id === value) {
                    item1.checked = true;
                }
            });
        },
        childrenChange(value) {},
    },
};
</script>

<style  lang="less">
#askBot {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    .manage-permissions {
        margin-left: 12px;
        .manage-permissions-title {
            .oneLevel {
                padding-left: 10px;
                border-left: 2px solid #366aff;
                margin: 10px 0 10px 0px;
            }
        }
        .checkBoxClass {
            display: flex;
            flex-direction: row;
        }
        .manage-permissions-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            margin-left: 10px;
            .twoLevel {
                padding-left: 10px;
                border-left: 2px solid #366aff;
                padding-bottom: 4px;
                margin-left: -11px;
            }

            .manage-permissions-list-item {
                margin: 10px 20px 10px 16px;
                .list-item-item {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;

                    .item-item {
                        margin: 10px 0px 10px 16px;

                        .threeLevel {
                            border-left: 2px solid #366aff;
                            margin-left: -12px;
                            padding: 5px 0 5px 10px;
                        }
                        .lastChildren {
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: flex-start;
                        }
                        .listChildren {
                            margin: 10px 0px 10px 16px;
                        }
                    }
                    .last-item-item {
                        width: 100%;
                        margin: 10px 0 0px 16px;
                    }
                }
            }
        }
    }
    .el-checkbox {
        min-width: 300px;
    }
}
</style>