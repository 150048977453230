<template>
    <div class="invite-qrcode">

    </div>
</template>

<script>
import { redirectUri } from "./../../const/redirectUri";
export default {
    mounted(){
        this.getScan();
    },
    methods:{
        getScan(){
            let url = "/scrm-api/we-work/knowledge/sso/3rd-qr-connect?redirect_uri=" +
                encodeURIComponent(redirectUri.callback + "invite-qrcode-login-callback2.html") + "&state="+this.$route.query.state;
            console.log(url,'管理系统中企微---url');
            this.$http.get(url).then((res) => {
                    if (res.status === 200) {
                        document.location.href = res.data;
                    }
                })
            .catch(() => {
                this.$message.warning("重新操作");
            });
        }
    }

}
</script>

<style>

</style>