<template>
    <div id="roleTable">
        <el-table v-loading="loading" :data="roleData" height="calc(100vh - 160px)">
            <template slot="empty">
                <DefaultImage></DefaultImage>
            </template>
            <el-table-column
                prop="name"
                :label="$t('dataRole.name')"
                width="240px"
                :show-overflow-tooltip="true"
            >
                <template slot-scope="scope">
                    <div style="display: flex; align-items: flex-start">
                        {{ scope.row.privilegesName }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="remark"
                :label="$t('dataRole.description')"
                align="left"
                :show-overflow-tooltip="true"
            >
                <template slot-scope="scope">
                    <div style="display: flex; align-items: flex-start">
                        <span
                            v-if="scope.row.description !== ''"
                            style="
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            "
                            >{{ scope.row.description }}</span
                        >
                        <span v-else>--</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="useNum"
                :label="$t('dataRole.useNum')"
                align="center"
                width="150"
            >
            </el-table-column>
            <el-table-column
                fixed="right"
                :label="$t('dataRole.operation')"
                align="center"
                width="100"
            >
                <template slot-scope="scope">
                    <div style="display: flex; justify-content: flex-start">
                        <span
                            @click="editRow(scope.$index, scope.row.id)"
                            v-if="scope.row.id != 0 && scope.row.id != -1 && scope.row.id != 99999"
                            class="table-handle-icon"
                        >
                            <i class="iconfont guoran-a-16-15"></i>
                        </span>
                        <span
                            @click="
                                deleteRow(
                                    scope.row.id,
                                    scope.row.privilegesName,
                                    scope.row.useNum
                                )
                            "
                            v-if="scope.row.id != 0 && scope.row.id != -1 && scope.row.id != 99999"
                            class="table-handle-icon"
                        >
                            <i class="iconfont guoran-a-18-13"></i
                        ></span>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div v-if="roleData.length != 0" class="footer-pagination">
            <pagination
                :pageSize="pageSize"
                :currentPage="currentPage"
                :total="roleTableTotal"
                @currentChange="handleCurrentChange"
            ></pagination>
        </div>
        <delete-dialog
            @closeEvent="dialogVisible = false"
            v-if="dialogVisible"
        >
            <div slot="popup-container" class="popup-container">
                <div class="delete-title">{{$t('dataRole.deleteTitle')}}</div>
                <div>
                    <span style="text-align: left; padding-left: 14px"
                        >{{$t('dataRole.deleteTip')}}
                        <span style="padding-left: 4px; font-weight: 600">{{
                            roleName
                        }}</span></span
                    >
                    <p
                        style="
                            text-align: left;
                            padding-left: 14px;
                            margin-top: 12px;
                            color: #909399;
                        "
                        v-if="deleteUseNum != 0"
                    >
                        <span
                            >{{$t('dataRole.tips')}}</span
                        >
                        <br />
                        <br />
                        <el-input
                            size="small"
                            v-model="checkName"
                            :placeholder="$t('dataRole.namePlaceholder')"
                        ></el-input>
                    </p>
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button @click="dialogVisible = false" size="small" plain
                    >{{$t('common.cancel')}}</el-button
                >
                <el-button
                    @click="deleteRole"
                    style="background-color: #fe5965; color: white"
                    size="small"
                    >{{$t('common.delete')}}</el-button
                >
            </div>
        </delete-dialog>
    </div>
</template>

<script>
import { operable } from "../../js/operable";
import pagination from "./../../../../components/pagination.vue";
import DeleteDialog from "./../../../../components/deleteDialogue.vue";
import DefaultImage from "@/components/DefaultImage.vue";

export default {
    name: "roleTable",
    components: { pagination, DeleteDialog, DefaultImage },
    data() {
        return {
            roleData: [],
            dialogVisible: false,
            roleName: "",
            checkName: "",
            deleteUseNum: 0,
            roleTableTotal: 20,
            currentPage: 1,
            pageSize: 20,
            roleId: 0,
            rolePrivilegesList: [],
            loading: false,
        };
    },
    mounted() {
        this.getRoles();
        this.rolePrivilegesList = this.getRolePrivileges();
    },
    computed: {
        operableDeleteRole() {
            return function (type) {
                return operable(type);
            };
        },
    },
    methods: {
        //获取所有角色
        getRoles() {
            this.loading = true;
            let url =
                this.requestUrl.userDataPrivilege.getUserDataPrivilegesList +
                "?pageSize=" +
                this.pageSize +
                "&pageNo=" +
                this.currentPage;
            this.$http.get(url).then((res) => {
                if (res.data.code === "0") {
                    this.roleData = res.data.data.list;
                    this.roleTableTotal = res.data.data.total;
                }
                this.loading = false;
            });
        },
        details(index, id) {
            this.$router.push({
                path: "/_addRole",
                query: {
                    type: "details",
                    id: id,
                },
            });
        },
        editRow(index, id) {
            this.$emit("c_editDataRole", JSON.stringify(this.roleData[index]));
        },
        deleteRow(id, name, useNum) {
            this.roleId = id;
            this.roleName = name;
            this.deleteUseNum = useNum;
            this.dialogVisible = true;
        },
        handleSizeChange(value) {
            this.pageSize = value;
            this.getRoles();
        },
        handleCurrentChange(value) {
            this.currentPage = value;
            this.getRoles();
        },
        deleteRole() {
            let flag = false;
            let url =
                this.requestUrl.userDataPrivilege.deleteUserDataPrivileges +
                this.roleId;
            if (this.deleteUseNum != 0) {
                if (this.checkName.trim() == "") {
                    this.$message.error(this.$t('dataRole.nameRequired'));
                } else if (this.checkName.trim() != this.roleName) {
                    this.$message.error(this.$t('dataRole.nameError'));
                } else {
                    flag = true;
                }
            } else {
                flag = true;
            }
            flag &&
                this.$http.delete(url).then((res) => {
                    if (res.data.code === "0") {
                        this.$message({
                            message: this.$t('dataRole.deleteSuccess'),
                            type: "success",
                            duration: 2000,
                        });
                        this.getRoles();
                        this.dialogVisible = false;
                    }
                });
        },
    },
};
</script>

<style scoped lang="less">
</style>