<template>
  <div class="basicInfo">
    <el-form
        ref="bindForm"
        :rules="yunzhijiaBindRules"
        :model="yunzhijiaBindForm"
    >
      <el-form-item
          :label="$t('botLink.enterpriseNamePla')"
          prop="corpName"
      >
        <el-input
            v-model.trim="yunzhijiaBindForm.corpName"
            :placeholder="$t('botLink.enterpriseNamePla')"
            :autofocus="true"
        ></el-input>
      </el-form-item>
      <el-form-item
          prop="corpId"
          :label="$t('botLink.yunZhiJiaCorpIdTip')"
      >
        <el-input
            v-model.trim="yunzhijiaBindForm.corpId"
            :placeholder="$t('botLink.EID')"
        ></el-input>
      </el-form-item>
      <el-form-item
          :label="$t('botLink.appName')"
          prop="appName"
      >
        <el-input
            v-model="yunzhijiaBindForm.appName"
            :placeholder="$t('botLink.appNamePla')"
            :autofocus="true"
        ></el-input>
      </el-form-item>
      <el-form-item
          prop="appId"
          :label="$t('botLink.yunZhiJiaAppidTip')"
      >
        <el-input
            v-model.trim="yunzhijiaBindForm.appId"
            :placeholder="$t('botLink.appidPla')"
        ></el-input>
        <span
            v-if="warningVisible"
            style="
            color: #f56c6c;
            display: block;
            text-align: left;
            font-size: 12px;
            line-height: 16px;"
            class="warning-text"
        >*{{ $t("botLink.agentIdTip") }}</span
        >
      </el-form-item>
      <el-form-item
          prop="appSecret"
      >
        <el-input
            v-model="yunzhijiaBindForm.appSecret"
            :placeholder="$t('botLink.appSecretPla')"
        ></el-input>
      </el-form-item>
      <el-form-item
          prop="signKey"
      >
        <el-input
            v-model.trim="yunzhijiaBindForm.signKey"
            :placeholder="$t('botLink.signKeyPla')"
        ></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { setI18nFormInfo } from "@/utils/common"
export default {
  name: "basicInfo",
  data() {
    return {
      yunzhijiaBindRules: {
        appName: [
          {
            required: true,
            message: "",
            trigger: "blur"
          },
          { max: 64, message: "", trigger: "blur" }
        ],
        corpName: [
          {
            required: true,
            message: "",
            trigger: "blur"
          },
          { max: 64, message: "", trigger: "blur" }
        ],
        corpId: [
          {
            required: true,
            message: "",
            trigger: "blur"
          },
          { max: 36, message: "", trigger: "blur" }
        ],
        appId: [
          {
            required: true,
            message: "",
            trigger: "blur"
          },
          { max: 32, message: "", trigger: "blur" }
        ],
        appSecret: [
          {
            required: true,
            message: "",
            trigger: "blur"
          },
          { max: 64, message: "", trigger: "blur" }
        ],
        signKey: [
          {
            required: true,
            message: "",
            trigger: "blur"
          },
          { max: 64, message: "", trigger: "blur" }
        ]
      },
      yunzhijiaBindForm: {
        appId: "",
        appName: "",
        appSecret: "",
        contactSecret: "",
        corpId: "",
        corpName: "",
        signKey: ""
      },
      warningVisible: false
    };
  },
  mounted() {
    setI18nFormInfo(this.yunzhijiaBindRules, this)
    // 云之家 的赋值方式 比较特殊，覆盖处理函数的赋值
    this.yunzhijiaBindRules.corpId[0].message = this.$t('botLink.EID');
    this.yunzhijiaBindRules.corpId[1].message =this.$t('channel.rule36Msg');
  }
};
</script>

<style scoped>

</style>