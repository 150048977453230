<template>
  <div class="singleChatBasicInfo">
    <el-form
        ref="bindForm"
        :rules="bindRules"
        :model="dingSingBindForm"
    >
      <el-form-item
          :label="$t('botLink.bindEnterpriseName')"
          prop="corpName"
      >
        <el-input
            v-model="dingSingBindForm.corpName"
            :placeholder="$t('botLink.enterpriseNamePla')"
            :autofocus="true"
        ></el-input>
      </el-form-item>
      <el-form-item
          prop="corpId"
          :label="$t('botLink.bindDingCorpIdTip')"
      >
        <el-input
            v-model="dingSingBindForm.corpId"
            :placeholder="$t('botLink.enterpriseIDPla')"
        ></el-input>
      </el-form-item>
      <el-form-item
          :label="$t('botLink.appName')"
          prop="agentName"
      >
        <el-input
            v-model="dingSingBindForm.agentName"
            :placeholder="$t('botLink.appNamePla')"
            :autofocus="true"
        ></el-input>
      </el-form-item>
      <el-form-item
          prop="agentId"
          :label="$t('botLink.dingAgentIdTip2')"
      >
        <el-input
            v-model="dingSingBindForm.agentId"
            :placeholder="$t('botLink.agentIdPla')"
        ></el-input>
        <span v-if="warningVisible"
              style="
              color: #f56c6c;
              display: block;
              text-align: left;
              font-size: 12px;
              line-height: 16px;"
              class="warning-text"
        >*{{ $t("botLink.agentIdTip") }}</span>
      </el-form-item>
      <el-form-item
          prop="appKey"
      >
        <el-input
            v-model="dingSingBindForm.appKey"
            :placeholder="$t('botLink.appKeyPla')"
        ></el-input>
      </el-form-item>
      <el-form-item
          prop="secret"
      >
        <el-input
            v-model="dingSingBindForm.secret"
            :placeholder="$t('botLink.secretPla')"
        ></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { setI18nFormInfo } from "@/utils/common"
export default {
  name: "dingSingleChatBasicInfo",
  data() {
    return {
      bindRules: {
        agentName: [
          {
            required: true,
            message: "",
            trigger: "blur",
          },
          { max: 64, message: "", trigger: "blur" },
        ],
        corpName: [
          {
            required: true,
            message: "",
            trigger: "blur",
          },
          { max: 64, message: "", trigger: "blur" },
        ],
        corpId: [
          {
            required: true,
            message: "",
            trigger: "blur",
          },
          { max: 36, message: "", trigger: "blur" },
        ],
        agentId: [
          {
            required: true,
            message: "",
            trigger: "blur",
          },
          { max: 32, message: "", trigger: "blur" },
        ],
        secret: [
          {
            required: true,
            message: "",
            trigger: "blur",
          },
          { max: 64, message: "", trigger: "blur" },
        ],
        appKey: [
          {
            required: true,
            message: "",
            trigger: "blur",
          },
          { max: 64, message: "", trigger: "blur" },
        ],
      },
      dingSingBindForm: {
        agentId: "",
        corpId: "",
        corpName: "",
        secret: "",
        appKey: "",
        agentName: ""
      },
      warningVisible:false
    };
  },
  created() {
    setI18nFormInfo(this.bindRules, this)
  }
};
</script>

<style scoped>

</style>