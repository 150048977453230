let ossConfigOption = {
    'askbot-open': {
        region: "oss-cn-zhangjiakou",
        //云账号AccessKey有所有API访问权限，建议遵循阿里云安全最佳实践，创建并使用STS方式来进行API访问
        accessKeyId: "LTAI4G3QtdEdwkEbihBngAsK",
        accessKeySecret: "OwgdVfc5PeCkIgqIdug660xmiSPchn",
        // stsToken: '<Your securityToken(STS)>',
        bucket: "guoranopen-zjk",
    },
    "lishi-open": {
        region: "oss-ap-southeast-1",
        accessKeyId: "LTAI5tAusPLDNJJwkvUbqi2T",
        accessKeySecret: "xqPVaunOIbvTe3g9qsXal2IZO6RftK",
        bucket: "askbotopen-ls",
    },
    "meinian-open": {
        region: "oss-cn-zhangjiakou",
        accessKeyId: "LTAI4G3QtdEdwkEbihBngAsK",
        accessKeySecret: "TOKEN",
        bucket: "guoranopen-zjk",
    },
    'askbot-wisdom': {
        region: "oss-cn-zhangjiakou",
        accessKeyId: "LTAI4G3QtdEdwkEbihBngAsK",
        accessKeySecret: "OwgdVfc5PeCkIgqIdug660xmiSPchn",
        bucket: "guoranwisdom",
    },
    "lishi-wisdom": {
        region: "oss-ap-southeast-1",
        accessKeyId: "LTAI5tAusPLDNJJwkvUbqi2T",
        accessKeySecret: "xqPVaunOIbvTe3g9qsXal2IZO6RftK",
        bucket: "askbotwisdom-ls",
    },
    "meinian-wisdom": {
        region: "oss-ap-southeast-1",
        accessKeyId: "LTAI5tAusPLDNJJwkvUbqi2T",
        accessKeySecret: "xqPVaunOIbvTe3g9qsXal2IZO6RftK",
        bucket: "askbotwisdom-ls",
    }
};

function generateOssConfig(bucketType) {
    return ossConfigOption[process.env.VUE_APP_SOURCE + '-' + bucketType]
}

export { generateOssConfig }


