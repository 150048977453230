<template>
    <div id="staffManage">
        <div class="content-container-staffManage">
            <div class="header">
                <div class="leftInfo">
                    <span class="headerName">企业管理</span>
                </div>
                <div class="select-main">
                    <el-select
                        v-model="mainValue"
                        size="small"
                        @change="changeMainValue"
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in mainOptions"
                            :key="item.corpId"
                            :label="item.corpName"
                            :value="item.corpId"
                        >
                            <img height="12px" v-if="item.corpType == 0" src="https://static.guoranbot.com/cdn-arsenal/dev/img/qiyechat.b7289a96.png" alt="" srcset="">
                            <img height="12px" v-if="item.corpType == 3" src="https://static.guoranbot.com/cdn-arsenal/dev/img/dingding.874450b3.jpeg" alt="" srcset="">
                            <span style="display: inline-block;margin-left: 6px;color: #8492a6; font-size: 13px;">{{ item.corpName }}</span>
                        </el-option>
                    </el-select>
                </div>
                <div class="header-handle">
                    <el-button
                        size="small"
                        type="text"
                        @click="openUpdateConfig"
                        >员工变更自动更新配置</el-button
                    >
                    <el-button
                        @click="importDialogVisible = true"
                        size="small"
                        v-if="
                            operableStaff('PORTAL_MANAGEMENT_MEMBER_ADD') &&
                            false
                        "
                        >批量导入
                    </el-button>
                    <el-button
                        @click="addStaff"
                        size="small"
                        type="primary"
                        v-if="
                            operableStaff('PORTAL_MANAGEMENT_MEMBER_ADD') &&
                            false
                        "
                        >添加成员
                    </el-button>
                    <el-button
                        size="small"
                        @click="asynStaff"
                        type="primary"
                        :loading="asynStaffLoading"
                        v-if="operableStaff('PORTAL_MANAGEMENT_MEMBER_SYNC')"
                        >同步员工信息
                    </el-button>
                </div>
            </div>
            <div class="staffManage-content">
                <div style="width: 150px; height: 100vh; background: white">
                    <leftSubMenu></leftSubMenu>
                </div>

                <div
                    class="content-box-staffManage"
                    v-loading="refreshLoading"
                    element-loading-text="获取数据中"
                    element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(0, 0, 0, 0.8)"
                >
                    <staffLeftTree
                        ref="leftTree"
                        :data="data"
                        :mainValue="mainValue"
                        @getMemberListByDeptId="getMemberListByDeptId"
                        @getMemberById="getMemberById"
                    ></staffLeftTree>
                    <div id="right-content">
                        <div class="right-content-table-box">
                            <el-table
                                :data="tableData"
                                width="100%"
                                height="calc(100vh - 130px)"
                                header-row-class-name="table-header"
                                row-class-name="table-row"
                            >
                                <el-table-column
                                    prop="name"
                                    label="姓名"
                                    width="120"
                                >
                                  <template slot-scope="scope">
                                    <div v-if="scope.row.source===0&&(scope.row.name.substr(0,4)==='woWe'||isStrings(scope.row.name))">
                                      <ww-open-data type="userName" :openid="scope.row.name"></ww-open-data>
                                    </div>
                                    <div v-else>{{scope.row.name}}</div>
                                  </template>
                                </el-table-column>
                                <el-table-column width="220" label="部门">
                                    <template slot-scope="scope">
                                        <div
                                            v-for="(cell, index) in scope.row
                                                .departments"
                                            :key="index"
                                        >
                                            <el-tooltip
                                                placement="top"
                                                effect="light"
                                            >
                                                <div
                                                    slot="content"
                                                >
                                                  <span v-if="cell.source===1&&isNumber(cell.fullName)">
                                                    <ww-open-data type="departmentName" :openid="cell.fullName"></ww-open-data>
                                                  </span>
                                                  <span v-else v-html="cell.fullName"></span>
                                                </div>
                                                <span v-if="cell.source===1&&isNumber(cell.name)">
                                                  <ww-open-data type="departmentName" :openid="cell.name"></ww-open-data>
                                                </span>
                                                <span v-else>{{ cell.name }}</span>
                                            </el-tooltip>
                                        </div>
                                    </template>
                                </el-table-column>

                                <el-table-column
                                    prop="mobile"
                                    label="手机"
                                    width="120"
                                >
                                </el-table-column>
                                <el-table-column
                                    prop="email"
                                    label="邮箱"
                                    width="250"
                                >
                                </el-table-column>
                                <el-table-column width="100" label="生日">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.birthday">{{
                                            new Date(scope.row.birthday).Format(
                                                "yyyy-MM-dd"
                                            )
                                        }}</span>
                                        <span v-else>--</span>
                                    </template>
                                </el-table-column>
                                <el-table-column width="100" label="入职时间">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.staffIn">{{
                                            new Date(scope.row.staffIn).Format(
                                                "yyyy-MM-dd"
                                            )
                                        }}</span>
                                        <span v-else>--</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="已关联">
                                    <template slot-scope="scope">
                                        <div
                                            v-for="(cell, index) in scope.row
                                                .memberApps"
                                            :key="index"
                                        >
                                            <img
                                                v-if="cell.type == 1"
                                                style="height: 12px"
                                                src="./../../../../src/assets/images/u2012.png"
                                                alt=""
                                                srcset=""
                                            />
                                            <img
                                                v-if="cell.type == 0"
                                                style="height: 12px"
                                                src="./../../../../src/assets/images/u2011.png"
                                                alt=""
                                                srcset=""
                                            />
                                            <span>{{ cell.agentName }}</span>
                                            <span
                                                class="disable-color"
                                                v-if="!cell.enable"
                                                >(禁用)</span
                                            >
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    label="操作"
                                    width="100"
                                    v-if="
                                        operableStaff(
                                            'PORTAL_MANAGEMENT_MEMBER_UPDATE'
                                        ) ||
                                        operableStaff(
                                            'PORTAL_MANAGEMENT_MEMBER_DELETE'
                                        )
                                    "
                                >
                                    <template slot-scope="scope">
                                        <el-button
                                            @click="editStaff(scope.row)"
                                            type="text"
                                            v-if="
                                                operableStaff(
                                                    'PORTAL_MANAGEMENT_MEMBER_UPDATE'
                                                )
                                            "
                                            >编辑
                                        </el-button>
                                        <el-button
                                            @click="deleteStaff(scope.row)"
                                            type="text"
                                            style="color: tomato"
                                            v-if="
                                                operableStaff(
                                                    'PORTAL_MANAGEMENT_MEMBER_DELETE'
                                                )
                                            "
                                            >删除
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                        <div class="right-content-table-page">
                            <el-pagination
                                @current-change="handleCurrentChange"
                                :current-page="currentPage"
                                :page-size="15"
                                layout="total, prev, pager, next, jumper"
                                :total="totalNumber"
                            >
                            </el-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <leftMenu></leftMenu>
        <importDia
            :dialogVisible="importDialogVisible"
            @uploadSuccess="uploadSuccess"
        ></importDia>
        <staffHandle
            :dialogVisible="staffHandleDialogVisible"
            :type="staffHandleType"
            :staffInfo="activeStaffInfo"
            @closeHandleStaff="closeHandleStaff"
            @saveHandleStaff="saveHandleStaff"
            @changeSingStatus="changeSingStatus"
            :options="data"
        ></staffHandle>
        <el-dialog
            title="员工变更自动更新配置"
            :visible.sync="updateDialogFormVisible"
            width="500px"
            :close-on-click-modal="false"
        >
            <div class="configuration-title">
                <span style="margin-right: 10px">同步渠道</span>
                <el-radio-group disabled v-model="channel" @change="changeRadio">
                    <el-radio label="WE_WORK">企业微信</el-radio>
                    <el-radio label="DING_TALK">钉钉</el-radio>
                </el-radio-group>
            </div>
            <div
                style="
                    max-height: calc(100vh - 300px);
                    overflow-y: scroll;
                    padding-right: 10px;
                "
            >
                <!--        <div class="configuration-title" v-if="channel==='DING_TALK'">
          <span>钉钉管理员打开钉钉管理后台，在【应用设置】页面中，配置【事件订阅】</span>
        </div>-->
                <el-form
                    :model="configuration"
                    label-position="top"
                    class="configuration"
                    v-if="channel === 'WE_WORK'"
                >
                    <el-form-item label="企业ID">
                        <el-input
                            v-model="configuration.corpId"
                            placeholder="请输入企业ID"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="通讯录secret">
                        <el-input
                            v-model="configuration.addressSecret"
                            placeholder="请输入通讯录secret"
                        ></el-input>
                    </el-form-item>
                    <div class="configuration-title isDisabledTitle">
                        <span v-if="channel === 'WE_WORK'"
                            >企业微信管理员打开企业微信管理后台，在【管理工具】页面中，配置【通讯录同步】</span
                        >
                    </div>
                    <el-form-item label="URL">
                        <el-input
                            v-model="configurationUrl"
                            readonly
                            placeholder="URL"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="token">
                        <el-input
                            v-model="configuration.token"
                            placeholder="请输入token"
                        ></el-input>
                    </el-form-item>

                    <el-form-item label="Encoding AESKey">
                        <el-input
                            v-model="configuration.aesKey"
                            placeholder="请输入Encoding AESKey"
                        ></el-input>
                    </el-form-item>
                </el-form>
                <el-form
                    :model="configuration"
                    label-position="top"
                    class="configuration"
                    v-if="channel === 'DING_TALK'"
                >
                    <el-form-item label="">
                        <span
                            >钉钉管理员打开钉钉应用管理后台,填写corpId与API
                            Token</span
                        >
                    </el-form-item>
                    <el-form-item label="corpId">
                        <el-input
                            v-model="dingConfiguration.corpId"
                            placeholder="请输入corpId"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="apiToken">
                        <el-input
                            v-model="dingConfiguration.apiToken"
                            placeholder="请输入apiToken"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="">
                        <span>打开应用基础信息,填写如下信息</span>
                    </el-form-item>
                    <el-form-item label="应用AgentId">
                        <el-input
                            v-model="dingConfiguration.syncMemberAgentId"
                            placeholder="请输入应用AgentId"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="应用AppKey">
                        <el-input
                            v-model="dingConfiguration.syncMemberAppKey"
                            placeholder="请输入应用AppKey"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="应用AppSecret">
                        <el-input
                            v-model="dingConfiguration.syncMemberAppSecret"
                            placeholder="请输入应用AppSecret"
                        ></el-input>
                    </el-form-item>

                    <el-form-item label="">
                        <span>在应用设置中,配置【事件订阅】</span>
                    </el-form-item>
                    <el-form-item label="应用加密aes_key">
                        <el-input
                            v-model="dingConfiguration.syncMemberAesKey"
                            placeholder="请输入加密aes_key"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="应用签名token">
                        <el-input
                            v-model="dingConfiguration.syncMemberToken"
                            placeholder="请输入签名token"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="请求网址">
                        <el-input
                            v-model="dingConfigurationUrl"
                            readonly
                            placeholder="请先填写corpId和应用AgentId"
                        ></el-input>
                    </el-form-item>
                    <div class="dingTalkTip">
                        <div class="tip">开启如下通讯录订阅事件</div>
                        <div class="tip">1.通讯录用户增加</div>
                        <div class="tip">2.通讯录用户更改</div>
                        <div class="tip">3.通讯录用户离职</div>
                        <div class="tip">4.加入企业后用户激活</div>
                        <div class="tip">5.通讯录企业部门创建</div>
                        <div class="tip">6.通讯录企业部门修改</div>
                        <div class="tip">7.通讯录企业部门删除</div>
                        <div class="tip">8.企业信息发生变更</div>
                    </div>
                </el-form>
            </div>

            <div slot="footer" class="dialog-footer">
                <el-button @click="updateDialogFormVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="updateStaffConfig"
                    >保 存</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>
<script>
import leftMenu from "./../../menu/leftMenu.vue";
import leftSubMenu from "./../../menu/leftSubMenu.vue";
import importDia from "./components/ImportDia";
import staffHandle from "./components/StaffHandle";
import StaffLeftTree from "./components/StaffLeftTree.vue";

import { formatTree } from "./utils/formatTree";
import { operable } from "../js/operable";
import { redirectUri } from "../../../const/redirectUri";

let id = 1000;
export default {
    name: "staffManage",
    components: {
        leftMenu,
        leftSubMenu,
        importDia,
        staffHandle,
        StaffLeftTree,
    },
    data() {
        return {
            mainValue: "", // 当前主体id
            corpType: "0",
            mainOptions: [], // 主体列表
            data: [], // 左侧部门树
            importDialogVisible: false, // 批量导入弹窗 visibel
            staffHandleDialogVisible: false, // 添加员工/编辑员工弹窗 visible
            // 添加员工/编辑员工 当前操作员工信息
            activeStaffInfo: {
                name: "",
                departments: [],
                mobile: "",
                email: "",
                memberApps: [
                    { agentName: "果然智汇企业微信", type: 0 },
                    { agentName: "果然智汇公众号", type: 1 },
                ],
                birthday: "",
                staffIn: "",
            },
            staffHandleType: "add", // 员工操作类型 add edit
            qichatDialogVisible: false,
            radio1: false,
            radio2: false,
            refreshLoading: false,
            tableData: [],
            currentPage: 1,
            totalNumber: 15,
            activeDeptId: "",
            // 同步
            asynStaffLoading: false,
            rolePrivilegesList: [],
            roleOperable: [],
            updateDialogFormVisible: false,
            configuration: {
                corpId: "",
                addressSecret: "",
                aesKey: "",
                token: "",
                externalSecret: "",
                scrmAagentSecret: "",
                scrmAgentId: "",
                scrmAgentName: "",
            },
            dingConfiguration: {
                apiToken: "",
                corpId: "",
                syncMemberAesKey: "",
                syncMemberAgentId: "",
                syncMemberAppKey: "",
                syncMemberToken: "",
                syncMemberAppSecret: "",
            },
            channel: "WE_WORK",
            weWorkId: "",
            dingTalkId: "",
            mainId: "",
        };
    },
    methods: {
        //部门是否是纯数字
        isNumber(value){
          const res = /^\d+$/;
          return res.test(value)
        },
        isStrings(value){
            const res = new RegExp("[\u4E00-\u9FA5]+");
            console.debug('是否是中文',res.test(value))
            return !res.test(value)
        },
        changeMainValue(val) {
            this.mainValue = val;
            this.mainOptions.forEach(item => {
                if (item.corpId == val) {
                    this.corpType = item.corpType;
                    if (this.corpType == 0) {
                        this.channel = "WE_WORK"
                    }
                    if (this.corpType == 3) {
                        this.channel = "DING_TALK"
                    }
                }
            })
            this.$nextTick(() => {
                this.$refs.leftTree.getdepartmentTree(val);
            });
            // this.$http
            //     .get(
            //         this.requestUrl.department.getDepartmentTree +
            //             "?corpId=" +
            //             val
            //     )
            //     .then((res) => {
            //         if (res.data && res.data.code === "0") {
            //             this.data = [...formatTree(res.data.data)];
            //         } else {
            //             this.$message.error(res.data.message);
            //         }
            //     });
        },
        // 获取主体下的公司
        getCorps4main() {
            this.$http
                .get(this.requestUrl.accountManage.corps4main)
                .then((res) => {
                    if (res.data && res.data.code === "0") {
                        this.mainOptions = res.data.data;
                        this.mainValue = this.mainOptions[0].corpId;
                        this.corpType = this.mainOptions[0].corpType;
                        if (this.corpType == 0) {
                            this.channel = "WE_WORK"
                        }
                        if (this.corpType == 3) {
                            this.channel = "DING_TALK"
                        }
                        this.$nextTick(() => {
                            this.$refs.leftTree.getdepartmentTree(
                                this.mainValue
                            );
                        });
                        // this.$http
                        //     .get(
                        //         this.requestUrl.department.getDepartmentTree +
                        //             "?corpId=" +
                        //             this.mainValue
                        //     )
                        //     .then((res) => {
                        //         if (res.data && res.data.code === "0") {
                        //             this.data = [...formatTree(res.data.data)];
                        //         } else {
                        //             this.$message.error(res.data.message);
                        //         }
                        //     });
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
        },
        //打开员工自动配置弹窗
        openUpdateConfig() {
            this.updateDialogFormVisible = true;
            if (this.channel === "WE_WORK") {
                this.getWeWork();
            }
            if (this.channel === "DING_TALK") {
                this.getDingDing();
            }
        },
        changeRadio(value) {
            console.log(value);
            if (value === "DING_TALK") {
                this.getDingDing();
            }
        },
        getDingDing() {
            let url = "/scrm-api/ding-talk-auth-info?corpId=" + this.mainValue;
            this.$http.get(url).then((res) => {
                console.log(res);
                if (res.status === 200) {
                    this.dingTalkId = res.data.id;
                    if (res.data.corpId) {
                        this.dingConfiguration.corpId = res.data.corpId;
                    }
                    if (res.data.apiToken) {
                        this.dingConfiguration.apiToken = res.data.apiToken;
                    }

                    if (res.data.syncMemberAesKey) {
                        this.dingConfiguration.syncMemberAesKey =
                            res.data.syncMemberAesKey;
                    }
                    if (res.data.syncMemberAgentId) {
                        this.dingConfiguration.syncMemberAgentId =
                            res.data.syncMemberAgentId;
                    }
                    if (res.data.syncMemberAppKey) {
                        this.dingConfiguration.syncMemberAppKey =
                            res.data.syncMemberAppKey;
                    }
                    if (res.data.syncMemberToken) {
                        this.dingConfiguration.syncMemberToken =
                            res.data.syncMemberToken;
                    }
                    if (res.data.syncMemberAppSecret) {
                        this.dingConfiguration.syncMemberAppSecret =
                            res.data.syncMemberAppSecret;
                    }
                }
            });
        },
        updateStaffConfig() {
            if (this.channel === "WE_WORK") {
                let url = "/scrm-api/we-work-auth-info/" + this.weWorkId;
                let postData = this.configuration;
                console.log(postData);
                if (this.configuration.corpId === "") {
                    this.$message({
                        message: "请输入企业ID",
                        type: "warning",
                        duration: 2000,
                    });
                    return;
                }
                if (this.configuration.token === "") {
                    this.$message({
                        message: "请输入token",
                        type: "warning",
                        duration: 2000,
                    });
                    return;
                }
                if (this.configuration.addressSecret === "") {
                    this.$message({
                        message: "请输入通讯录secret",
                        type: "warning",
                        duration: 2000,
                    });
                    return;
                }
                if (this.configuration.aesKey === "") {
                    this.$message({
                        message: "请输入Encoding AESKey",
                        type: "warning",
                        duration: 2000,
                    });
                    return;
                }

                this.$http.put(url, this.configuration).then((res) => {
                    console.log(res);
                    this.$message({
                        message: "员工变更自动更新配置成功",
                        duration: 2000,
                        type: "success",
                    });
                    this.updateDialogFormVisible = false;
                });
            } else {
                let url = "/scrm-api/ding-talk-auth-info/" + this.dingTalkId;
                if (!this.dingConfiguration.corpId) {
                    this.$message({
                        message: "请输入corpId",
                        type: "warning",
                        duration: 2000,
                    });
                    return;
                }
                if (!this.dingConfiguration.syncMemberAesKey) {
                    this.$message({
                        message: "请输入加密ace_key",
                        type: "warning",
                        duration: 2000,
                    });
                    return;
                }
                if (!this.dingConfiguration.syncMemberToken) {
                    this.$message({
                        message: "请输入签名token",
                        type: "warning",
                        duration: 2000,
                    });
                    return;
                }
                if (!this.dingConfiguration.syncMemberAgentId) {
                    this.$message({
                        message: "请输入AgentId",
                        type: "warning",
                        duration: 2000,
                    });
                    return;
                }

                if (!this.dingConfiguration.syncMemberAppKey) {
                    this.$message({
                        message: "请输入应用AppKey",
                        type: "warning",
                        duration: 2000,
                    });
                    return;
                }
                if (!this.dingConfiguration.syncMemberAppSecret) {
                    this.$message({
                        message: "请输入应用AppSecret",
                        type: "warning",
                        duration: 2000,
                    });
                    return;
                }
                if (!this.dingConfiguration.apiToken) {
                    this.$message({
                        message: "请输入apiToken",
                        type: "warning",
                        duration: 2000,
                    });
                    return;
                }
                this.$http.put(url, this.dingConfiguration).then((res) => {
                    this.$message({
                        message: "员工变更自动更新配置成功",
                        duration: 2000,
                        type: "success",
                    });
                    this.updateDialogFormVisible = false;
                });
            }
        },
        // 同步员工信息
        asynStaff() {
            this.asynStaffLoading = true;
            this.$http
                .get(this.requestUrl.department.initDepartment + "?corpId=" + this.mainValue)
                .then((res) => {
                    if (res.data && res.data.code === "0") {
                        console.log(res.data.data);
                        this.$message.success(
                            "已完成同步，请刷新浏览器查看最新员工信息"
                        );
                        this.asynStaffLoading = false;
                    } else {
                        this.asynStaffLoading = false;
                        this.$message.error(res.data.message);
                    }
                });
        },
        // 改变员工关联状态
        changeSingStatus(data) {
            console.log(data);
            this.tableData.forEach((item) => {
                if (data.id == item.id) {
                    item.memberApps.forEach((cell) => {
                        if (cell.id == data.type) {
                            cell.enable = data.enable;
                        }
                    });
                }
            });
            this.tableData = [...this.tableData];
        },
        getMemberById(data) {
            this.$http
                .get(
                    this.requestUrl.member.getMemberById +
                        "/" +
                        data.id +
                        "?page=" +
                        data.currentPage +
                        "&size=15"
                )
                .then((res) => {
                    if (res.data && res.data.code === "0") {
                        console.log(res.data.data);
                        this.tableData = [res.data.data];
                        this.currentPage = 1;
                        this.totalNumber = 1;
                        WWOpenData.bind(this.$el)
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
        },
        getMemberListByDeptId(data) {
            if (data.id == null) {
                this.tableData = [];
                this.totalNumber = 0;
            } else {
                this.activeDeptId = data.id;
                this.$http
                    .get(
                        this.requestUrl.member.getMemberListByDeptId +
                            "/" +
                            data.id +
                            "?page=" +
                            data.currentPage +
                            "&size=15"
                    )
                    .then((res) => {
                        if (res.data && res.data.code === "0") {
                            // console.log(res.data.data);
                            this.tableData = res.data.data.list;
                            this.totalNumber = res.data.data.total;
                            WWOpenData.bind(this.$el)

                        } else {
                            this.$message.error(res.data.message);
                        }
                    });
            }
        },
        // 分页改变数量
        handleCurrentChange(e) {
            this.currentPage = e;
            console.log(this.currentPage);
            this.getMemberListByDeptId({
                id: this.activeDeptId,
                currentPage: this.currentPage,
            });
        },
        // 子组件 importDia 上传成功调用
        uploadSuccess() {
            this.importDialogVisible = false;
            this.staffHandleDialogVisible = false;
        },
        // 点击添加成员
        addStaff() {
            this.$http
                .get(this.requestUrl.department.getDepartmentTree)
                .then((res) => {
                    if (res.data && res.data.code === "0") {
                        this.data = formatTree(res.data.data);
                        this.staffHandleType = "add";
                        this.activeStaffInfo = {
                            name: "",
                            departments: [],
                            mobile: "",
                            email: "",
                            memberApps: [],
                            birthday: "",
                            staffIn: "",
                        };
                        this.staffHandleDialogVisible = true;
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
        },
        // close 添加/编辑员工弹窗
        closeHandleStaff() {
            this.staffHandleDialogVisible = false;
            this.importDialogVisible = false;
        },
        // 保存 添加/编辑员工信息
        saveHandleStaff(data) {
            console.log("保存员工信息", data);
            console.log("shengri", data.info.birthday);
            let birthDay = "";
            if (data.info.birthday) {
                birthDay = new Date(data.info.birthday).Format("yyyy-MM-dd");
            }
            let staffIn = "";
            if (data.info.staffIn) {
                staffIn = new Date(data.info.staffIn).Format("yyyy-MM-dd");
            }
            let param = {
                departmentIds: data.info.departmentIds,
                email: data.info.email,
                mobile: data.info.mobile,
                name: data.info.name,
                birthday: birthDay,
                staffIn: staffIn,
            };
            console.log("param", param);
            if (data.type == "edit") {
                param.id = data.info.id;
                this.$http
                    .put(
                        this.requestUrl.member.editMemberById +
                            "/" +
                            data.info.id,
                        param
                    )
                    .then((res) => {
                        if (res.data && res.data.code === "0") {
                            this.getMemberListByDeptId({
                                id: this.activeDeptId,
                                currentPage: this.currentPage,
                            });
                        } else {
                            this.$message.error(res.data.message);
                        }
                    });
                this.closeHandleStaff();
            }
            if (data.type == "add") {
                this.$http
                    .post(this.requestUrl.member.addMember, param)
                    .then((res) => {
                        if (res.data && res.data.code === "0") {
                            this.getMemberListByDeptId({
                                id: data.info.departmentIds[0],
                                currentPage: 1,
                            });
                            this.closeHandleStaff();
                            this.$refs.leftTree.treeSetCurrentKey(
                                data.info.departmentIds[0]
                            );
                        } else {
                            this.$message.error(res.data.message);
                        }
                    });
            }
        },
        // 点击编辑成员
        editStaff(data) {
            this.$http
                .get(this.requestUrl.department.getDepartmentTree)
                .then((res) => {
                    if (res.data && res.data.code === "0") {
                        this.data = formatTree(res.data.data);
                        this.staffHandleType = "edit";
                        this.activeStaffInfo = JSON.parse(JSON.stringify(data));
                        this.staffHandleDialogVisible = true;
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
        },
        // 点击删除成员
        deleteStaff(data) {
            this.$confirm("确定删除该成员吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.$http
                        .delete(
                            this.requestUrl.member.deleteMemberById +
                                "/" +
                                data.id
                        )
                        .then((res) => {
                            if (res.data && res.data.code === "0") {
                                this.getMemberListByDeptId({
                                    id: this.activeDeptId,
                                    currentPage: this.currentPage,
                                });
                                this.$message({
                                    type: "success",
                                    message: "删除成功!",
                                });
                            } else {
                                this.$message.error(res.data.message);
                            }
                        });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
        },
        //获取同步员工信息
        getWeWork() {
            let url = "/scrm-api/we-work-auth-info?corpId=" + this.mainValue;
            this.$http.get(url).then((res) => {
                console.log("woshjxinxi", res);
                this.weWorkId = res.data.id;
                if (res.data.addressSecret) {
                    this.configuration.addressSecret = res.data.addressSecret;
                }
                if (res.data.corpId) {
                    this.configuration.corpId = res.data.corpId;
                }
                if (res.data.aesKey) {
                    this.configuration.aesKey = res.data.aesKey;
                }
                if (res.data.token) {
                    this.configuration.token = res.data.token;
                }
                if (res.data.externalSecret) {
                    this.configuration.externalSecret = res.data.externalSecret;
                }
                if (res.data.scrmAagentSecret) {
                    this.configuration.scrmAagentSecret =
                        res.data.scrmAagentSecret;
                }
                if (res.data.scrmAgentId) {
                    this.configuration.scrmAgentId = res.data.scrmAgentId;
                }
                if (res.data.scrmAgentName) {
                    this.configuration.scrmAgentName = res.data.scrmAgentName;
                }
            });
        },
        onCopy() {
            this.$message({
                message: "复制成功",
                type: "success",
            });
        },
        onError() {
            this.$message.error("复制失败");
        },
    },
    filters: {
        findNameById: function (id, list) {
            let name = "";

            function f(arr) {
                arr.forEach((item, index) => {
                    if (item.id == id) {
                        name = item.label;
                        return;
                    } else {
                        if (item.children && item.children.length > 0) {
                            f(item.children);
                        }
                    }
                });
            }

            f(list);
            return name;
        },
    },
    computed: {
        operableStaff() {
            return function (type) {
                return operable(type);
            };
        },
        configurationUrl: function () {
            let mainId = localStorage.getItem("_mainId");
            let url = "";
            if (this.configuration.corpId !== "") {
                url =
                    redirectUri.staff +
                    "/ewechat-api/callback/";
                if (mainId) {
                    url += mainId;
                }
                url += "/" + this.configuration.corpId;
            }
            console.log(url);
            return url;
        },
        dingConfigurationUrl: function () {
            let mainId = localStorage.getItem("_mainId");
            let url = "";
            console.log(mainId);
            if (this.dingConfiguration.corpId !== "") {
                if (mainId) {
                    if (this.dingConfiguration.syncMemberAgentId !== "") {
                        url =
                            redirectUri.staff +
                            "/dingtalk-api/agent-callback/sync-member/" +
                            this.dingConfiguration.corpId +
                            "/" +
                            mainId +
                            "/" +
                            this.dingConfiguration.syncMemberAgentId;
                    }
                }
            }

            console.log(url);
            return url;
        },
    },
    mounted() {
        this.rolePrivilegesList = this.getRolePrivileges();
        this.roleOperable = this.getRoleId();
        this.mainId = localStorage.getItem("_mainId");
        this.getCorps4main();
    },
};
</script>
<style lang="less">
@import "./../../../assets/less/staffManage/staffManage.less";

.table-header {
    color: #366AFF !important;

    .cell {
        font-weight: 600 !important;
        margin: 8px 0 !important;
        text-align: center;
    }
}

td {
    .cell {
        text-align: center;
    }
}

.configuration {
    text-align: left;

    .el-form-item__label {
        line-height: 20px;
    }

    .el-form-item {
        margin-bottom: 14px;
    }
}

.configuration-title {
    text-align: left;
    margin: 15px 0;
}

.isDisabledTitle {
    margin-top: 40px;
}

.isCopyTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;

    i {
        margin-left: 10px;
        width: 20px;
        flex: none;
        font-size: 18px;
        color: #366AFF;
        cursor: pointer;
    }
}

#staffManage {
    display: flex;

    .content-container-staffManage {
        position: absolute;
        left: 64px;
        height: 100vh;
        width: calc(100vw - 60px);
        text-align: left;

        .staffManage-content {
            height: calc(100vh - 60px);
            margin: 0 auto;
            display: flex;
            justify-content: flex-start;

            .content-box-staffManage {
                background-color: #ffffff;
                border-radius: 5px;
                flex: auto;
                margin: 15px 20px 0px 15px;
                display: flex;
                justify-content: space-between;

                #right-content {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .right-content-table-box {
                        flex: auto;
                        overflow-x: hidden;
                        position: relative;

                        .el-table {
                            position: absolute;
                        }
                    }

                    .right-content-table-page {
                        text-align: right;
                        flex: none;
                        height: 52px;
                        line-height: 52px;
                        margin-right: 16px;
                    }
                }
            }
        }

        .steps1 {
            margin-top: 30px;

            .form-item {
                text-align: left;
                margin: 15px 0;

                .label {
                    margin-right: 5px;
                }
            }
        }
    }

    .header {
        padding: 8px 16px;
        background: #f5f8fb;
        -webkit-box-shadow: 0 2px 10px -5px #d1dfec;
        box-shadow: 0 2px 10px -5px #d1dfec;
        border-bottom: 1px solid #d1dfec;
        height: 40px;
        display: flex;
        justify-content: space-between;

        .header-handle {
            margin-top: 4px;
            margin-right: 8px;
            flex: none;
        }
        .select-main {
            flex: auto;
            padding-top: 4px;
            .el-select {
                width: 310px !important;
            }
        }
        .leftInfo {
            height: 38px;
            line-height: 38px;
            flex: none;
            width: 150px;
            .headerName {
                font-size: 16px;
                color: #303133;
                margin-left: 16px;
            }
        }
    }
}

.steps-content {
    .desc {
        text-align: left;
        margin: 20px 0;
    }

    .apps {
        text-align: left;
        margin: 20px 0;

        .sub-title {
            margin: 10px 0;
        }
    }
}

.disable-color {
    color: tomato;
}

/* /deep/.opt-button{
    text-align: left !important;
    i{
        margin-left: 5px;
    }
} */
/deep/ .el-tabs__header {
    height: 60px !important;
    line-height: 60px !important;
}

/deep/ .el-tabs__item {
    height: 60px !important;
    line-height: 60px !important;
    font-size: 16px;
}
.dingTalkTip {
    .tip {
        padding: 4px 0;
    }
}
</style>