<template>
    <div id="add-or-edit-data-role">
        <div class="add-or-edit-data-role-c">
            <div class="input-cell">
                <span class="input-cell-title"
                    ><span class="input-cell-title-text"
                        >{{$t('dataRole.dataRoleName')}} <span class="input-cell-title-pre">*</span></span
                    ></span
                >
                <el-input
                    size="small"
                    v-model="dataRoleName"
                    maxlength="20"
                    :placeholder="$t('common.placeholder')"
                ></el-input>
            </div>
            <div class="input-cell">
                <span class="input-cell-title"
                    ><span class="input-cell-title-text"
                        >{{$t('dataRole.dataRoleDes')}}</span
                    ></span
                >
                <el-input
                    size="small"
                    v-model="dataRoleDes"
                    maxlength="200"
                    :placeholder="$t('common.placeholder')"
                ></el-input>
            </div>
            <div class="input-cell input-cell-bg">
                <span class="input-cell-title">
                    <span class="input-cell-title-text">{{$t('dataRole.botDataRole')}}</span>
                    <span @click="askbotAuthVisible = !askbotAuthVisible" class="input-cell-title-visible">
                        <i v-if="!askbotAuthVisible" class="arsenal_icon arsenalangle-right-solid"></i>
                        <i v-else class="arsenal_icon arsenalangle-down-solid"></i>
                    </span>
                </span>
                <div v-show="askbotAuthVisible" class="bot-role-box">
                    <div class="bot-data-list-title">
                        <div class="bot-data-list-title-l">
                            <el-checkbox
                                :indeterminate="
                                    checklist.length > 0 &&
                                    checklist.length < checklistAllInfo.length
                                "
                                v-model="checkAll"
                                @change="checkAllChange"
                                >{{$t('dataRole.bot')}}</el-checkbox
                            >
                        </div>
                        <div class="bot-data-list-title-r">{{$t('dataRole.knowledgeBase')}}</div>
                    </div>
                    <el-cascader-panel
                        :options="newBotList"
                        :props="defaultProps"
                        v-model="checklist"
                    ></el-cascader-panel>
                </div>
            </div>
            <div class="input-cell input-cell-bg">
                <span class="input-cell-title"
                    ><span class="input-cell-title-text"
                        >{{$t('dataRole.orderDataRole')}}</span
                    >
                    <span @click="workorderAuthVisible = !workorderAuthVisible" class="input-cell-title-visible">
                        <i v-if="!workorderAuthVisible" class="arsenal_icon arsenalangle-right-solid"></i>
                        <i v-else class="arsenal_icon arsenalangle-down-solid"></i>
                    </span>
                </span>
                <div v-show="workorderAuthVisible" class="wo-role-box">
                    <div
                        v-for="(woCell, index) in woInfoList"
                        :key="index"
                        class="wo-role-cell"
                    >
                        <div class="wo-role-cell-handle">
                            <span
                                v-if="woCell.type == 1"
                                class="el-icon-error-disable-up"
                            ><i class="iconfont guoran-tongyichicun-16-09-shanchu2"></i></span>
                            <span
                                @click="deleteCondition(index)"
                                v-if="woCell.type != 1"
                                class=" el-icon-error-handle-up"
                            >
                            <i class="iconfont guoran-tongyichicun-16-09-shanchu2"></i>
                            </span>
                        </div>
                        <div class="wo-role-cell-select-box">
                            <el-select
                                v-if="woCell.type == 1"
                                disabled
                                size="small"
                                v-model="woCell.type"
                                :placeholder="$t('common.selectPlaceholder')"
                            >
                                <el-option
                                    v-for="item in wo_options_self"
                                    :key="item.id"
                                    :label="item.fatherNodeName"
                                    :value="item.id"
                                >
                                </el-option>
                            </el-select>
                            <el-select
                                size="small"
                                v-model="woCell.type"
                                v-if="woCell.type != 1"
                                @change="changeFatherNodeName($event, index)"
                                :placeholder="$t('common.selectPlaceholder')"
                            >
                                <el-option
                                    v-for="item in wo_options"
                                    :key="item.id"
                                    :label="item.fatherNodeName"
                                    :value="item.id"
                                >
                                </el-option>
                            </el-select>
                        </div>
                        <div class="wo-role-cell-info">
                            <span class="self-des" v-if="woCell.type == 1"
                                >{{$t('dataRole.orderDataRoleTip')}}</span
                            >
                            <el-select
                                size="small"
                                multiple
                                v-model="woCell.scopes"
                                v-if="woCell.type == 5"
                                :placeholder="$t('common.selectPlaceholder')"
                            >
                                <el-option
                                    v-for="item in mod_wo_options"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                >
                                </el-option>
                            </el-select>
                            <!-- <el-select
                                size="small"
                                multiple
                                v-model="woCell.scopes"
                                v-if="woCell.type == 3"
                                placeholder="请选择"
                            >
                                <el-option
                                    v-for="item in bot_wo_options"
                                    :key="item.platformId"
                                    :label="item.platfromName"
                                    :value="item.platformId"
                                >
                                </el-option>
                            </el-select> -->
                            <el-select
                                v-model="woCell.scopes"
                                size="small"
                                multiple
                                v-if="woCell.type == 3"
                                :placeholder="$t('common.selectPlaceholder')"
                            >
                                <el-option-group
                                    v-for="group in bot_wo_options"
                                    :key="group.platformId"
                                    :label="group.platfromName"
                                >
                                    <el-option
                                        v-for="item in group.agents"
                                        :key="item.agentId"
                                        :label="item.agentName"
                                        :value="item.agentId"
                                    >
                                    </el-option>
                                </el-option-group>
                            </el-select>
                            <div class="wo-role-cell-info-work" v-if="woCell.type == 4">
                                <el-select
                                    size="small"
                                    v-model="woCell.onlyAssignToGroup"
                                >
                                    <el-option :value="false" :label="$t('dataRole.groupAllOrder')"></el-option>
                                    <el-option :value="true" :label="$t('dataRole.onlyGroupOrder')"></el-option>
                                </el-select>
                                <el-select
                                    size="small"
                                    multiple
                                    v-model="woCell.scopes"
                                    :placeholder="$t('dataRole.groupPlaceholder')"
                                    collapse-tags
                                >
                                    <el-option
                                        value="CURRENT_ACCOUNT_GROUP"
                                        :label="$t('dataRole.currentGroup')"
                                    ></el-option>
                                    <el-option
                                        v-for="item in treeOptions"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                    <div class="add-condition" @click="addCondition">
                        <span
                            class="add-condition-sq-icon"
                        > + </span>
                        <span class="text"> {{$t('dataRole.addCondition')}} </span>
                    </div>
                </div>
            </div>
            <div class="input-cell input-cell-bg">
                <span class="input-cell-title"
                    ><span class="input-cell-title-text"
                        >{{$t('dataRole.orderDataPermission')}}</span
                    >
                    <span @click="workorderStaticsVisible = !workorderStaticsVisible" class="input-cell-title-visible">
                        <i v-if="!workorderStaticsVisible" class="arsenal_icon arsenalangle-right-solid"></i>
                        <i v-else class="arsenal_icon arsenalangle-down-solid"></i>
                    </span>
                </span>
                <div v-show="workorderStaticsVisible" class="wo-role-box">
                    <div
                        v-for="(woCell, index) in woStaticsInfoList"
                        :key="index"
                        class="wo-role-cell"
                    >
                        <div class="wo-role-cell-handle">
                            <span
                                v-if="woCell.type == 1"
                                class="el-icon-error-disable-up"
                            ><i class="iconfont guoran-tongyichicun-16-09-shanchu2"></i></span>
                            <span
                                @click="deleteStaticsCondition(index)"
                                v-if="woCell.type != 1"
                                class=" el-icon-error-handle-up"
                            >
                            <i class="iconfont guoran-tongyichicun-16-09-shanchu2"></i>
                            </span>
                        </div>
                        <div class="wo-role-cell-select-box">
                            <el-select
                                size="small"
                                v-model="woCell.type"
                                v-if="woCell.type == 1"
                                disabled
                                :placeholder="$t('common.selectPlaceholder')"
                            >
                                <el-option
                                    v-for="item in wo_statics_options_self"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                >
                                </el-option>
                            </el-select>
                            <el-select
                                size="small"
                                v-else
                                v-model="woCell.type"
                                @change="changeStaFatherNodeName($event, index)"
                                :placeholder="$t('common.selectPlaceholder')"
                            >
                                <el-option
                                    v-for="item in wo_statics_options"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                >
                                </el-option>
                            </el-select>
                        </div>
                        <div class="wo-role-cell-info">
                            <div class="wo-role-cell-info-statistics-work" v-if="woCell.type == 2">
                                <el-select
                                    size="small"
                                    multiple
                                    v-model="woCell.scopes"
                                    :placeholder="$t('dataRole.groupPlaceholder')"
                                    collapse-tags
                                >
                                    <el-option
                                        value="CURRENT_ACCOUNT_GROUP"
                                        :label="$t('dataRole.currentGroup')"
                                    ></el-option>
                                    <el-option
                                        v-for="item in treeOptions"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="String(item.id)"
                                    >
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                    <div class="add-condition" @click="addStaticsCondition">
                        <span
                            class="add-condition-sq-icon"
                        > + </span>
                        <span class="text"> {{$t('dataRole.addCondition')}} </span>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="dialog-footer">
            <el-button size="small" @click="close">取 消</el-button>
            <el-button size="small" type="primary" @click="saveDataRole"
                >保 存</el-button
            >
        </div> -->
    </div>
</template>

<script>
export default {
    name: "addRole",
    data() {
        return {
            checklist: [],
            checklistAllInfo: [],
            isIndeterminate: true,
            dataRoleName: "",
            dataRoleDes: "",
            wo_options_self: [
                {
                    id: 1,
                    name: "",
                },
            ],
            wo_options: [],
            woInfoList: [
                {
                    type: 1,
                    name: "",
                    scopes: [],
                },
            ],
            mod_wo_options: [],
            bot_wo_options: [],
            treeOptions: [],
            newBotList: [],
            defaultProps: {
                label: "name",
                value: "id",
                children: "children",
                checkStrictly: false,
                multiple: true,
            },
            // 复用机器人数据权限部分
            botList: [],
            checkAll: false,
            botInfoList: [],

            askbotAuthVisible: true,
            workorderAuthVisible: true,
            workorderStaticsVisible: true,

            woStaticsInfoList: [
                {
                    type: 1,
                    name: "当前账号",
                    scopes: [],
                }
            ],
            wo_statics_options_self: [
                {
                    id: 1,
                    name: "当前账号",
                }
            ],
            wo_statics_options: [
                {
                    id: 2,
                    name: "指定组",
                },
                {
                    id: 3,
                    name: "全部",
                },
            ],
        };
    },
    props: ["editObjStr", "handleType"],
    components: {},
    computed: {},
    watch: {},
    mounted() {
        this.wo_options_self[0].name = this.$t('dataRole.myOrder')
        this.wo_options_self[0].name = this.$t('dataRole.myOrder')

        console.log(this.editObjStr,'========');
        if (this.editObjStr != "") {
            let editObj = JSON.parse(this.editObjStr);
            this.dataRoleName = editObj.name;
            this.dataRoleDes = editObj.description;
            this.checklist = [...editObj.skills];
            this.woInfoList = editObj.woInfoList;
            if (!(editObj.workOrderStatisticsPrivileges == null || editObj.workOrderStatisticsPrivileges == 'null')) {
                this.woStaticsInfoList = editObj.workOrderStatisticsPrivileges;
            }
        }
        this.getBotList();
        this.queryApplicationType();
        this.queryWorkOrderTemplate();
        this.queryWorkOrderGroup();
    },
    methods: {
        saveDataRole() {
            if (this.dataRoleName.trim() == "") {
                this.$message.error("请填写数据权限名称!");
                return false;
            }
            // if (this.dataRoleDes.trim() == "") {
            //     this.$message.error("请填写数据权限描述!");
            //     return false;
            // }

            let param = {
                privilegesName: this.dataRoleName,
                description: this.dataRoleDes,
                dataPrivileges: this.formDataDataPrivileges(),
                workOrderDataPrivileges: this.formatWorkOrderDataPrivileges(),
                workOrderStatisticsPrivileges: this.formatWorkOrderStaticsPrivileges()
            };
            if (this.handleType == "add") {
                this.$http
                    .post(
                        this.requestUrl.userDataPrivilege.addUserDataPrivileges,
                        param
                    )
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.$emit("c_closeHandleDataRole", "save");
                        } else {
                            this.$message.error(res.data.message);
                        }
                    });
            }
            if (this.handleType == "edit") {
                param.id = JSON.parse(this.editObjStr).id;
                this.$http
                    .put(
                        this.requestUrl.userDataPrivilege
                            .updateUserDataPrivileges,
                        param
                    )
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.$emit("c_closeHandleDataRole", "save");
                        } else {
                            this.$message.error(res.data.message);
                        }
                    });
            }
        },
        // 指定应用所有工单
        queryApplicationType() {
            this.$http
                .get(this.requestUrl.userDataPrivilege.queryApplicationType)
                .then((res) => {
                    if (res.data.code == 0) {
                        this.bot_wo_options = res.data.data;
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
        },
        // 指定应用所有工单模板
        queryWorkOrderTemplate() {
            this.$http
                .get(this.requestUrl.userDataPrivilege.queryWorkOrderTemplate)
                .then((res) => {
                    if (res.data.code == 0) {
                        this.mod_wo_options = res.data.data;
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
        },
        // 指定组工单
        queryWorkOrderGroup() {
            this.$http
                .get(this.requestUrl.userDataPrivilege.queryWorkOrderGroup)
                .then((res) => {
                    if (res.data.code == 0) {
                        this.treeOptions = res.data.data;
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
        },
        formDataDataPrivileges() {
            let arr = [],
                r_arr = [];
            this.checklist.forEach((item) => {
                if (item.length == 2) {
                    this.newBotList.forEach((bot) => {
                        if (bot.id == item[0] && arr.indexOf(bot.id) == -1) {
                            arr.push(bot.id);
                            bot.children &&
                                bot.children.forEach((skill) => {
                                    if (skill.id == item[1]) {
                                        r_arr.push({
                                            botId: item[0],
                                            botName: bot.name,
                                            checked: true,
                                            skillIds: [item[1]],
                                            skillList: [skill.name],
                                        });
                                    }
                                });
                        } else {
                            bot.children &&
                                bot.children.forEach((skill) => {
                                    if (skill.id == item[1]) {
                                        if (
                                            r_arr[arr.indexOf(bot.id)] &&
                                            r_arr[arr.indexOf(bot.id)].skillIds
                                        ) {
                                            r_arr[
                                                arr.indexOf(bot.id)
                                            ].skillIds.push(item[1]);
                                        }
                                        if (
                                            r_arr[arr.indexOf(bot.id)] &&
                                            r_arr[arr.indexOf(bot.id)].skillList
                                        ) {
                                            r_arr[
                                                arr.indexOf(bot.id)
                                            ].skillList.push(skill.name);
                                        }
                                    }
                                });
                        }
                    });
                }
            });
            // 新增是否全选机器人技能标识
            r_arr.forEach((bot) => {
                this.newBotList.forEach((cell) => {
                    if (bot.botId == cell.id) {
                        if (bot.skillIds.length == cell.children.length) {
                            bot.allChoose = true;
                        } else {
                            bot.allChoose = false;
                        }
                    }
                });
            });
            return r_arr;
        },
        formatWorkOrderDataPrivileges() {
            let workOrderDataPrivileges = [];
            this.woInfoList.forEach((item) => {
                let workOrderId = [],
                    workOrderName = [];
                if (item.type == 4) {
                    if(item.scopes.includes("CURRENT_ACCOUNT_GROUP")) {
                        workOrderName.push('当前账号组')
                        workOrderId.push('CURRENT_ACCOUNT_GROUP')
                    }
                    this.treeOptions.forEach((cell) => {
                        if (item.scopes.indexOf(cell.id) != -1) {
                            workOrderName.push(cell.name);
                            workOrderId.push(Number(cell.id));
                        }
                    });
                }
                if (item.type == 3) {
                    this.bot_wo_options.forEach((plat) => {
                        plat.agents.forEach((agent) => {
                            if (item.scopes.indexOf(agent.agentId) != -1) {
                                workOrderName.push(agent.agentName);
                                workOrderId.push(agent.agentId);
                            }
                        });
                    });

                }
                if (item.type == 5) {
                    this.mod_wo_options.forEach((cell) => {
                        if (item.scopes.indexOf(cell.id) != -1) {
                            workOrderName.push(cell.name);
                            workOrderId.push(cell.id);
                        }
                    });
                }
                workOrderDataPrivileges.push({
                    fatherNodeTag: item.name,
                    workOrderId: workOrderId,
                    workOrderName: workOrderName,
                    fatherNodeType: item.type,
                    onlyAssignToGroup: item.onlyAssignToGroup
                });
            });
            return workOrderDataPrivileges;
        },
        formatWorkOrderStaticsPrivileges() {
            let workOrderDataPrivileges = [];
            this.woStaticsInfoList.forEach((item) => {
                let workOrderId = [],
                    workOrderName = [];
                if (item.type == 2) {
                    if(item.scopes.includes("CURRENT_ACCOUNT_GROUP")) {
                        workOrderName.push('当前账号组')
                        workOrderId.push('CURRENT_ACCOUNT_GROUP')
                    }
                    this.treeOptions.forEach((cell) => {
                        if (item.scopes.indexOf(cell.id) != -1) {
                            workOrderName.push(cell.name);
                            workOrderId.push(Number(cell.id));
                        }
                    });
                }
                workOrderDataPrivileges.push({
                    fatherNodeTag: item.name,
                    workOrderId: workOrderId,
                    workOrderName: workOrderName,
                    fatherNodeType: item.type,
                    onlyAssignToGroup: item.onlyAssignToGroup
                });
            });
            return workOrderDataPrivileges;
        },
        close() {
            this.$emit("c_closeHandleDataRole", "cancel");
        },
        changeFatherNodeName(val, index) {
            console.log(val, index);
            this.woInfoList[index].scopes = [];
            this.wo_options.forEach((item) => {
                if (item.id == val) {
                    this.woInfoList[index].name = item.fatherNodeName;
                }
            });
        },
        changeStaFatherNodeName(val, index) {
            console.log(val, index);
            this.woStaticsInfoList[index].scopes = [];
            this.wo_statics_options.forEach((item) => {
                if (item.id == val) {
                    this.woStaticsInfoList[index].name = item.name;
                }
            });
        },
        checkAllChange(val) {
            this.checklist = [];
            if (val) {
                this.newBotList.forEach((item) => {
                    if (item.children) {
                        item.children.forEach((skill) => {
                            this.checklist.push([item.id, skill.id]);
                        });
                    } else {
                        this.checklist.push([item.id]);
                    }
                });
            }
            this.checklist = [...this.checklist];
        },
        addCondition() {
            this.woInfoList.push({
                type: 2,
                name: "所有工单",
                scopes: [],
            });
        },
        addStaticsCondition(){
            this.woStaticsInfoList.push({
                type: 3,
                name: "全部",
                scopes: [],
            });
        },
        deleteCondition(index) {
            this.woInfoList.splice(index, 1);
        },
        deleteStaticsCondition(index) {
            this.woStaticsInfoList.splice(index, 1);
        },
        // 获取机器人列表
        getBotList() {
            this.$http
                .get(this.requestUrl.userDataPrivilege.queryUserBotSkill)
                .then((res) => {
                    if (res.data.code == 0) {
                        // 工单数据权限
                        this.wo_options_self = [
                            res.data.data.userDataWorkOrderPrivilegesList[0],
                        ];
                        this.wo_options =
                            res.data.data.userDataWorkOrderPrivilegesList.slice(
                                1,
                                res.data.data.userDataWorkOrderPrivilegesList
                                    .length
                            );
                        // 初始化机器人数据权限渲染
                        let newBotList = [];
                        res.data.data.userBotPrivileges.forEach((bot) => {
                            bot.children = [];
                            bot.id = bot.botId;
                            bot.name = bot.botName;
                            bot.skillIds &&
                                bot.skillIds.length > 0 &&
                                bot.skillIds.forEach((skill, index) => {
                                    bot.children[index] = {
                                        id: skill,
                                        name: bot.skillList[index],
                                    };
                                });
                            newBotList.push(bot);
                        });
                        this.newBotList = [...newBotList];
                        // 记录全选状态
                        this.newBotList.forEach((item) => {
                            if (item.children) {
                                item.children.forEach((skill) => {
                                    this.checklistAllInfo.push([
                                        item.id,
                                        skill.id,
                                    ]);
                                });
                            } else {
                                this.checklistAllInfo.push([item.id]);
                            }
                        });
                        this.checklistAllInfo = [...this.checklistAllInfo];
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
        },
    },
};
</script>

<style  lang="less">
#add-or-edit-data-role {
    .add-or-edit-data-role-c {
        .input-cell-bg{
            background-color: #FBFCFD;
            padding: 14px;
            margin-top: 16px;
            user-select: none;
            .input-cell-title-text {
                font-weight: 500;
            }
        }
        .input-cell {
            text-align: left;
            margin-bottom: 12px;
            .el-cascader-panel {
                border: none;
                width: 100% !important;
                .el-cascader-menu {
                    width: 50% !important;
                }
            }
            .input-cell-title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 20px;
                line-height: 20px;
                margin-bottom: 4px;
                .input-cell-title-visible {
                    width: 30px;
                    height: 30px;
                    line-height: 28px;
                    text-align: center;
                    background-color: #F2F4F6;
                    border-radius: 5px;
                    cursor: pointer;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    i {
                        font-size: 12px;
                        margin-left: 10px;
                    }
                }
                .input-cell-title-pre {
                    color: tomato;
                }
                .input-cell-title-text {
                    color: #000000;
                }
            }
            .bot-role-box {
                border: solid 1px #e4e7ed;
                margin-top: 8px;
                .bot-data-list-title {
                    display: flex;
                    justify-content: flex-start;
                    height: 34px;
                    line-height: 34px;
                    background-color: #f2f6fc;

                    .bot-data-list-title-l {
                        padding-left: 20px;
                        flex: 1;
                    }
                    .bot-data-list-title-r {
                        padding-left: 16px;
                        flex: 1;
                    }
                }
            }
            .wo-role-box {
                .add-condition {
                    margin-top: 16px;
                    width: 130px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    cursor: pointer;
                    .add-condition-sq-icon {
                        height: 30px;
                        width: 30px;
                        flex: none;
                        line-height: 28px;
                        font-size: 18px;
                        color: white;
                        background-color: #366aff;
                        border-radius: 5px;
                        margin-right: 6px;
                    }
                    .text {
                        display: inline-block;
                        height: 34px;
                        line-height: 34px;
                        font-size: 12px;
                        color: #366aff;
                    }
                }
                .wo-role-cell {
                    margin: 8px 0;
                    display: flex;
                    justify-content: flex-start;
                    .wo-role-cell-select-box {
                        flex: none;
                        width: 200px;
                        margin-right: 8px;
                        .el-select {
                            width: 100%;
                        }
                    }
                    .wo-role-cell-info {
                        flex: auto;
                        width: 200px;
                        // margin-right: 8px;
                        .self-des {
                            font-size: 12px;
                            height: 34px;
                            line-height: 34px;
                            color: #909399;
                            overflow: hidden;
                        }
                        .el-select {
                            width: 100%;
                        }
                        .el-cascader {
                            width: 100%;
                        }
                        .wo-role-cell-info-work {
                            display: flex;
                            align-items: flex-start;
                            .el-select:first-child {
                                flex: none;
                                width: 40%;
                                margin-right: 8px;
                            }
                            .el-select:last-child {
                                flex: none;
                                width: calc(60% - 8px);
                                margin-right: 8px;
                            }
                        }
                        .wo-role-cell-info-statistics-work {
                            display: flex;
                            align-items: flex-start;
                            .el-select {
                                flex: none;
                                width: 100%;
                            }
                        }
                    }
                    .wo-role-cell-handle {
                        flex: none;
                        width: 24px;
                        margin-right: 8px;
                        .el-icon-error-disable-up {
                            font-size: 24px;
                            line-height: 30px;
                            cursor: not-allowed;
                            color: #909399;
                        }
                        .el-icon-error-handle-up{
                            font-size: 24px;
                            line-height: 30px;
                            cursor: pointer;
                            color: #366aff;
                        }
                    }
                }
            }
        }
    }
    .dialog-footer {
        text-align: right;
    }
}
</style>