<template>
    <div class="action_Jump">
        <div class="goto-list">
            <div
                class="jump-cell"
            >
                <div class="jump-cell-l">
                    <div class="left-select">
                        <el-select
                            style="width: 120px"
                            v-model="unit.content.scope"
                            size="small"
                            @change="jumpTypeValueChange"
                            placeholder="请选择"
                        >
                            <el-option
                                v-for="item in filterJumpTypeOptions(
                                    jumpTypeOptions
                                )"
                                :key="item.value"
                                :label="$t('common.' + item.value)"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </div>

                    <div ref="selectCellSubContentAftWidth" class="right-select select-cell-sub-content-aft">
                        <SelectAddnode
                            v-if="unit.content.scope === 'NODE'"
                            :nodeOptions="nodeOptions"
                            :nodeId="unit.content.value"
                            :defaultParentNodeId="nodeId"
                            :nodeSelectARef="`actionJumpAddnode_${com_index}`"
                            :intentId="intentId"
                            @getNodeOptions="getNodeOptions"
                            @setAddNodeId="setAddNodeId"
                            :index="0"
                        ></SelectAddnode>
                        <el-cascader
                            v-if="!isBotLink"
                            filterable
                            v-show="unit.content.scope === 'INTENT'"
                            v-model="unit.content.value"
                            :placeholder="$t('flowComponent.selectIntent')"
                            size="small"
                            :options="intentTree4Radio"
                            :props="{
                                emitPath: false,
                                label: 'name',
                                value: 'id',
                            }"
                        ></el-cascader>
                        <template v-else>
                            <el-popover
                                ref="popover-jump"
                                placement="bottom"
                                :width="intentSelectAndSearchPoperWidth"
                                trigger="click"
                                popper-class="set-welcome-message-search-intent-poprver"
                                :visible-arrow="false"
                                @show="showPover(unit.content)">
                                <el-input
                                    class="search-intent-input"
                                    size="small"
                                    :placeholder="$t('flowComponent.searchOrSelectIntent')"
                                    v-model="unit.content.valueText"
                                    slot="reference"
                                    @input="inputFn($event,unit.content.valueText)">
                                    <i slot="suffix" class="el-icon-arrow-down"></i>
                                </el-input>
                                <el-tree
                                    class="choose-intent-tree"
                                    :style="{width: intentSelectAndSearchPoperWidth + 'px'}"
                                    :data="intentTree4Radio"
                                    :props="{
                                        children: 'children',
                                        label: 'name',
                                        id:'id',
                                        isLeaf: 'isLeafNode',
                                    }"
                                    :filter-node-method="filterNode"
                                    node-key="id"
                                    ref="tree">
                                    <span :class="['custom-tree-node',  data.id.indexOf('B') === -1 && data.id.indexOf('S') === -1 && data.id.indexOf('T') === -1  ? '' : 'disabled']" slot-scope="{node,data}"   @click="onNodeClick(data,node,unit.content)">
                                        <span class="custom-tree-node-left">
                                            <span :class="['data-label']">{{ data.name }}</span>
                                        </span>
                                        <i class="el-icon-check" v-if="data.checked"></i>
                                    </span>
                                </el-tree>
                            </el-popover>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import SelectAddnode from "./../cell/SelectAddnode.vue";
export default {
    components: { SelectAddnode },
    props: [
        "unit",
        "intentOptions",
        "nodeOptions",
        "intentTree4Radio",
        "type",
        "isStartOrEnd",
        "intentId",
        "nodeId",
        "com_index",
        "isBotLink"
    ],
    data() {
        return {
            jumpTypeOptions: [
                {
                    value: "NODE",
                    label: "跳转到节点",
                },
                {
                    value: "INTENT",
                    label: "跳转到意图",
                },
            ],
             isCheck:true,

            intentSelectAndSearchPoperWidth: 300,
        };
    },
    methods: {
        getNodeOptions() {
            this.$emit("getNodeOptions", true);
        },
        setAddNodeId(nodeId, index) {
            this.$set(this.unit.content, "value", nodeId);
        },
        filterJumpTypeOptions(value) {
            if (this.isStartOrEnd) {
                return [
                    {
                        value: "INTENT",
                        label: "跳转到意图",
                    },
                ];
            } else {
                return value;
            }
        },
        jumpTypeValueChange() {
            this.unit.content.value = "";
        },
        inputFn(val,item){
            this.$refs.tree[0].filter(val);
            this.$forceUpdate()
        },
        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
        onNodeClick(data,node,item){
            if(data.id.indexOf('B') > -1 || data.id.indexOf('S') > -1 || data.id.indexOf('T') > -1 ) return false;
            this.$set(item,'valueText',data.name);
            this.$set(item,'value',data.id)
            this.$set(data,'checked',true)
            this.$refs['popover-jump'].doClose()
        },
        showPover(data){
            // 为poper设置宽度
            if (this.$refs.selectCellSubContentAftWidth) {
                this.intentSelectAndSearchPoperWidth = window.getComputedStyle(this.$refs.selectCellSubContentAftWidth).width;
                if (
                    this.intentSelectAndSearchPoperWidth.indexOf("px") > -1
                ) {
                    this.intentSelectAndSearchPoperWidth =
                        this.intentSelectAndSearchPoperWidth.replace("px", "") - 30;
                } else {
                    this.intentSelectAndSearchPoperWidth = 300;
                }
            } else {
                this.intentSelectAndSearchPoperWidth = 300;
            }

            let list = JSON.parse(JSON.stringify(this.intentTree4Radio))
            list.forEach(botItem => {
                botItem.children.forEach(skillItem => {
                    skillItem.children.forEach(typeItem => {
                        typeItem.children.forEach(intentItem => {
                            intentItem.checked = false;
                            if(data.value == intentItem.id){
                                intentItem.checked = true;
                            } 
                        })
                    })
                })
            })
            this.intentTree4Radio =  JSON.parse(JSON.stringify(list))
        },
    },
};
</script>
<style lang="less" scoped>
.choose-intent-tree {
    max-height: 150px;
    overflow-x: hidden;
    overflow-y: auto;
}
.action_Jump {
    .goto-list {
        margin-top: 8px;
        .jump-cell {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 4px;
            .jump-cell-l {
                flex: auto;
                border: 1px solid #e0e6f7;
                border-radius: 5px;
                display: flex;
                justify-content: flex-start;
                /deep/.inline-input {
                    width: 100%;
                    padding-right: 0;
                }
                /deep/.el-cascader {
                    width: 100%;
                    padding-right: 0;
                }
                /deep/.el-input__inner {
                    border: none;
                    padding-right: 0;
                }
                /deep/.el-input__suffix {
                    display: none;
                }
            }
            .left-select {
                flex: none;
                width: 120px;
                .el-select {
                    width: 100%;
                }
            }
            .right-select {
                margin-left: 16px;
                flex: auto;
                &.el-select {
                    width: 100%;
                }
                &.el-cascader {
                    width: 100%;
                }
            }
            .el-icon-plus-box {
                color: white;
                width: 29px;
                height: 29px;
                line-height: 29px;
                background: #366aff;
                border-radius: 5px;
                margin-left: 14px;
                text-align: center;
                i {
                    font-size: 14px;
                    font-weight: 800;
                }
            }
            .el-icon-delete-box {
                margin-left: 14px;
                width: 27px;
                height: 27px;
                line-height: 27px;
                background: #ffffff;
                border: 1px solid #e0e6f7;
                border-radius: 5px;
                color: #366aff;
                i {
                    font-size: 14px;
                    font-weight: 800;
                }
            }
        }
    }
}
</style>