<template>
    <div id="notice-list" style="width: 100%">
        <div class="notice-list-content">
            <el-table
                :data="tableData"
                style="width: calc(100% - 1px)"
                stripe
                :show-header="false"
                align="left"
                @row-click="rowClick"
                :empty-text="$t('common.emptyData')"
                row-class-name="cursor-row"
            >
                <el-table-column
                    align="left"
                    width="150"
                    label="Date"
                    prop="date"
                >
                    <template slot-scope="scope">
                        <i
                            style="color: #d2d8e3; margin-left: 16px"
                            class="el-icon-time"
                        ></i>
                        <span style="margin-left: 4px">{{
                            new Date(scope.row.updateTime).Format("yyyy-MM-dd")
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    width="210"
                    align="left"
                    label="Name"
                    prop="systemName"
                >
                    <template slot-scope="scope">
                        <span class="system-pic">
                            <i :class="scope.row.systemPic"></i>
                        </span>

                        <span style="margin-left: 4px">
                            {{
                                isE() ? scope.row.systemName.replace('Ask', 'e').replace('Bot知识管理', 'KMS知识管理') : scope.row.systemName
                            }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column align="left">
                    <template slot-scope="scope">
                        <div class="title-box">
                            <span class="circle-blue"></span>
                            <span style="margin-left: 4px">{{
                                scope.row.updateNoticeTitle == null
                                    ? ""
                                    : scope.row.updateNoticeTitle.split(
                                          "*+@@+*"
                                      )[1]
                            }}</span>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <popup v-if="noticeDialog" @closeEvent="noticeDialog = false">
            <div slot="popup-name" class="popup-name">
                <div class="notice-name-top">
                    {{
                        activeNotice.updateNoticeTitle == null
                            ? ""
                            : activeNotice.updateNoticeTitle.split("*+@@+*")[1]
                    }}
                </div>
            </div>
            <div slot="popup-con">
                <!-- <br />
                <h3>{{
                    activeNotice.updateNoticeTitle == null
                        ? ""
                        : activeNotice.updateNoticeTitle.split("*+@@+*")[1]
                }}</h3> -->
                <p v-html="activeNotice.updateNoticeDescription"></p>
            </div>
            <div slot="dialog-footer" class="notice-other">
                <p class="notice-other-aut">{{$t('notice.askbot')}}</p>
                <p class="notice-other-time">
                    {{ initTime(activeNotice.updateTime) }}
                </p>
            </div>
        </popup>
    </div>
</template>
<script>
import Popup from "@/components/popup";
export default {
    props: ["pageNo", "pageSize", "editPageble"],
    components: {
        Popup,
    },
    data() {
        return {
            noticeDialog: false,
            tableData: [],
            activeNotice: {
                systemName: "",
                updateNoticeTitle: "",
                updateNoticeDescription: "",
                updateTime: "",
                deleted: false,
            },
        };
    },
    filters: {
        
    },
    mounted() {
        this.getList();
    },
    methods: {
        formatDateText(val) {
            var day = new Date();
            let text = val;
            if (val == day.Format("yyyy-MM-dd")) {
                text = this.$t('notice.today');
            }
            if (
                val ==
                new Date(new Date().setDate(new Date().getDate() - 1)).Format(
                    "yyyy-MM-dd"
                )
            ) {
                text = this.$t('notice.yesterday');
            }
            return text;
        },
        initTime(date) {
            var d = new Date(date);
            var a = [
                d.getFullYear(),
                d.getMonth() + 1,
                d.getDate(),
                d.getHours(),
                d.getMinutes(),
                d.getSeconds(),
            ];
            for (var i = 0, len = a.length; i < len; i++) {
                if (a[i] < 10) {
                    a[i] = "0" + a[i];
                }
            }
            let str2 =
                a[0] +
                "-" +
                a[1] +
                "-" +
                a[2] +
                " " +
                a[3] +
                ":" +
                a[4] +
                ":" +
                a[5];
            return str2;
        },

        rowClick(row, column, event) {
            let regP = new RegExp('<p', "g")
            let regLi = new RegExp('<li', "g")
            row.updateNoticeDescription = row.updateNoticeDescription.replace(regP, '<p style="margin-bottom:10px"')
            row.updateNoticeDescription = row.updateNoticeDescription.replace(regLi, '<li style="margin-bottom:10px"')
            row.updateNoticeDescription = this.replaceIframeWithVideo(row.updateNoticeDescription)
            this.activeNotice = row;
            this.noticeDialog = true;
        },
        replaceIframeWithVideo (htmlString) {
            // 解析 HTML 字符串为 DOM
            const parser = new DOMParser();
            const doc = parser.parseFromString(htmlString, 'text/html');

            // 查找所有的 iframe 元素
            const iframes = doc.querySelectorAll('iframe');

            iframes.forEach(iframe => {
                // 创建一个新的 video 元素
                const video = document.createElement('video');
                video.setAttribute('width', iframe.getAttribute('width'));
                video.setAttribute('height', iframe.getAttribute('310px'));
                video.setAttribute('controls', 'controls'); // 使 video 支持播放控制
                // 创建 source 元素并设置 src 属性
                const source = document.createElement('source');
                source.setAttribute('src', iframe.getAttribute('customaryurl'));
                source.setAttribute('type', 'video/mp4'); // 根据实际视频类型设置
                // 将 source 添加到 video 元素中
                video.appendChild(source);
                // 替换 iframe 为 video
                iframe.parentNode.replaceChild(video, iframe);
            });
            // 返回修改后的 HTML 字符串
            return doc.documentElement.outerHTML;
        },
        getList(pageNo, pageSize) {
            if (pageNo) {
                this.pageNo = pageNo;
            }
            if (pageSize) {
                this.pageSize = pageSize;
            }
            this.$http
                .get(
                    `${this.requestUrl.notice.selectAllNotice}?pageNo=${this.pageNo}&pageSize=${this.pageSize}`
                )
                .then((res) => {
                    if (res.data.code === "0") {
                        this.tableData = [...res.data.data.list];
                        if (this.editPageble == "1") {
                            this.$emit("getTableTotal", {
                                total: res.data.data.total,
                            });
                        }
                    }
                });
        },
    },
};
</script>
<style lang="less">
.notice-name-top {
    font-size: 18px;
    font-weight: 700;
    max-width: 500px;
    text-align: center;
}
#notice-list {
    height: calc(100vh - 550px);
    overflow-y: auto;
    .notice-other {
        width: 90%;
        margin-top: 10px;
        text-align: right;
        .notice-other-time {
            margin-top: 12px;
        }
    }
    .el-carousel__item h3 {
        color: #475669;
        font-size: 18px;
        opacity: 0.75;
        line-height: 168px;
        margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
        background-color: #d3dce6;
    }
    .el-table-column {
        text-align: left;
        .cell {
            text-align: left !important;
        }
    }
    td .cell {
        text-align: left !important;
    }

    .title-box {
        display: flex;
        align-items: center;
    }

    .circle-blue {
        width: 4px;
        height: 4px;
        min-width: 4px;
        border-radius: 2px;
        background-color: #366aff;
    }
    .system-pic {
        i {
            color: #366aff;
        }
    }
    .notice-list-title {
        padding: 15px 25px;
        display: flex;
        justify-content: space-between;

        .notice-list-title-l {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .gr-left-cbt-icon {
                width: 22px;
                height: 22px;
                background: #366aff;
                border-radius: 50%;
                color: white;
                text-align: center;
                line-height: 22px;
                margin-right: 8px;

                i {
                    font-size: 15px;
                }
            }

            .gr-left-cbt-text {
                font-weight: 500;
                font-size: 15px;
            }
        }
        .notice-list-title-r {
            text-align: center;
            width: 59px;
            height: 27px;
            line-height: 29px;
            background: #ffffff;
            border: 1px solid #a1b9ff;
            border-radius: 14px;
            color: #366aff;
            font-size: 12px;
            font-weight: 700;
            cursor: pointer;
            i {
                font-weight: bolder;
            }
        }
    }
}
</style>