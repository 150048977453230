<template>
    <div id="app">
        <!-- <leftMenu></leftMenu> -->
      <keep-alive :include="['channelView']">
        <router-view />
      </keep-alive>
    </div>
</template>

<script>
//import leftMenu from './views/menu/leftMenu.vue'
import agentConfig from "@/common/wechat/agentConfig";
import { requestUrl } from "./api/requestUrl";

export default {
    name: "App",
    methods: {
        getWechatCorp () {
            let mainId = localStorage.getItem('_mainId');
            let uid = localStorage.getItem('_uid');
            if(!mainId || !uid) {
                return
            }
            let url = this.requestUrl.agentConfig.getWechat + '?mainId=' + mainId + '&uid=' + uid
            this.$http.get(url).then(res => {
                console.debug('res', res)
                /*if (res.data&&res.data.weChatKfPermanentCodeResponse!=null && res.data.weChatKfPermanentCodeResponse.auth_corp_info){
                    let corpId = res.data.weChatKfPermanentCodeResponse.auth_corp_info.corpid;
                    this.initWxAgentConfig(corpId);
                }*/
                if (res.data.code === '0') {
                    if (res.data.data.length !== 0) {
                        this.initWxAgentConfig(res.data.data[0].corpId)
                    }
                }
            })
        },
        initWxAgentConfig (corpId) {
            //agentConfig
            let currentUrl = encodeURIComponent(window.location.origin + window.location.pathname);
            let url = this.requestUrl.agentConfig.agentConfigNew + "?corpId=" + corpId + "&url=" + currentUrl;
            this.$http.get(url).then(res => {
                console.info('load init wx agent config ', corpId, res);
                if (res.data && res.data.data) {
                    agentConfig(corpId, res.data.data.data, (res) => {
                        console.info('agent auth config success', res);
                        WWOpenData.bind(this.$el);
                    });
                }
            })
        },
        getCorps4main(){
          let url = requestUrl.accountManage.corps4main;
          this.$http.get(url).then(res => {
            if (res.data.code == 0 && res.data.data && res.data.data.length > 0){
              localStorage.setItem('_corpId',res.data.data[0].corpId)
            }
          })
        }
    },
    /* components:{
        leftMenu
    } */
    mounted() {
        this.$nextTick(() => {
            console.log('systemLanguage',sessionStorage.getItem('systemLanguage'))
            let systemLanguage = sessionStorage.getItem('systemLanguage');
            if (systemLanguage){
                this.$i18n.locale = systemLanguage
            }
            //   this.getCorps4main();
        })
    }
};
</script>

<style lang="less">
@import "./assets/less/app/app.less";
.select-cell-sub-content-aft {
    input {
        box-shadow: none !important;
        border: none !important;
    }
}
.staff-costomer-popper {
    z-index: 99999!important;
}

.el-dropdown-menu {
    max-height: calc(100vh - 350px);
    overflow-y: auto;
}

.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
    background-color: #ecf5ff;
    color: #366aff !important;
}

.el-select-dropdown__item.selected {
    color: #366aff !important;
}

.el-date-picker {
    z-index: 99999 !important;
}

.el-input__inner {
    box-shadow: none;
}

.el-input__inner:focus {
    box-shadow: none;
    border-color: #366aff !important;
}

input:focus {
    box-shadow: none !important;
    border-color: #366aff !important;
}


// .el-drawer__wrapper{
//     z-index: 2010 !important;
// }
#app {
    overflow: auto;
    width: 100vw;
    height: 100vh;
}

.el-select-dropdown {
    z-index: 3100 !important;

    .dlhg-select-item {
        width: 200px !important;
    }
}

.el-message {
    z-index: 3000 !important;
}
.choose-conditions-poprver{
  z-index: 3333!important;
}
.el-cascader__dropdown{
  z-index: 3333!important;
}
.el-popper{
  z-index: 3333!important;
}
@media screen and (max-width: 1100px) {
    body {
        font-size: 12px !important;
    }

    .bind-third-account-input {
        font-size: 12px !important;
    }

    .el-form-item__label {
        font-size: 12px !important;
    }

    .el-input__inner {
        font-size: 12px !important;
        min-height: 34px;
    }

    .el-radio__label {
        font-size: 12px !important;
    }

    .el-checkbox__label {
        font-size: 12px !important;
    }

    .el-cascader-panel {
        font-size: 12px !important;
    }

    .el-select-dropdown__item {
        font-size: 12px !important;
    }

    .el-timeline {
        font-size: 12px !important;
    }

    .el-range-input {
        font-size: 12px !important;
    }

    .el-range-separator {
        font-size: 12px !important;
    }

    .el-cascader__search-input {
        font-size: 12px !important;
    }

    .el-select-dropdown__empty {
        font-size: 12px !important;
    }

    .el-popover {
        font-size: 12px !important;
    }

    .el-textarea__inner {
        font-size: 12px !important;
    }

    .el-dropdown-menu__item {
        font-size: 12px !important;
    }

    .el-table {
        font-size: 12px !important;
    }

    #popup-assembly {
        width: 600px !important;
    }

    .isShowNotice-s {
        img {
            height: 13px;
        }
    }

    .el-tree-node__label {
        font-size: 12px !important;
    }

    .el-dropdown {
        font-size: 12px !important;
    }
}

@media screen and (min-width: 1100px) and (max-width: 1464px) {
    body {
        font-size: 13px !important;
    }

    .bind-third-account-input {
        font-size: 13px !important;
    }

    .el-form-item__label {
        font-size: 13px !important;
    }

    .el-input__inner {
        font-size: 13px !important;
        min-height: 36px;
    }

    .el-radio__label {
        font-size: 13px !important;
    }

    .el-checkbox__label {
        font-size: 13px !important;
    }

    .el-cascader-panel {
        font-size: 13px !important;
    }

    .el-select-dropdown__item {
        font-size: 13px !important;
    }

    .el-timeline {
        font-size: 13px !important;
    }

    .el-range-input {
        font-size: 13px !important;
    }

    .el-range-separator {
        font-size: 13px !important;
    }

    .el-cascader__search-input {
        font-size: 13px !important;
    }

    .el-select-dropdown__empty {
        font-size: 13px !important;
    }

    .el-popover {
        font-size: 13px !important;
    }

    .el-textarea__inner {
        font-size: 13px !important;
    }

    .el-dropdown-menu__item {
        font-size: 13px !important;
    }

    .el-table {
        font-size: 13px !important;
    }

    #popup-assembly {
        width: 600px !important;
    }

    .isShowNotice-s {
        img {
            height: 13px;
        }
    }

    .el-tree-node__label {
        font-size: 13px !important;
    }

    .el-dropdown {
        font-size: 13px !important;
    }

    .work-order-card-item-r {
        width: 180px !important;
    }
}

@media screen and (min-width: 1465px) {
    body {
        font-size: 14px !important;
    }

    .bind-third-account-input {
        font-size: 14px !important;
    }

    .el-form-item__label {
        font-size: 14px !important;
    }

    .el-input__inner {
        font-size: 14px !important;
    }

    .el-radio__label {
        font-size: 14px !important;
    }

    .el-checkbox__label {
        font-size: 14px !important;
    }

    .el-cascader-panel {
        font-size: 14px !important;
    }

    .el-select-dropdown__item {
        font-size: 14px !important;
    }

    .el-timeline {
        font-size: 14px !important;
    }

    .el-range-input {
        font-size: 14px !important;
    }

    .el-range-separator {
        font-size: 14px !important;
    }

    .el-cascader__search-input {
        font-size: 14px !important;
    }

    .el-select-dropdown__empty {
        font-size: 14px !important;
    }

    .el-popover {
        font-size: 14px !important;
    }

    .el-textarea__inner {
        font-size: 14px !important;
    }

    .el-dropdown-menu__item {
        font-size: 14px !important;
    }

    .el-table {
        font-size: 14px !important;
    }

    .el-button--small {
        font-size: 14px !important;
    }

    #popup-assembly {
        width: 640px !important;
    }

    .isShowNotice-s {
        img {
            height: 14px;
        }
    }

    .el-tree-node__label {
        font-size: 14px !important;
    }

    .el-dropdown {
        font-size: 14px !important;
    }

    .work-order-card-item-r {
        width: 340px;
    }
}</style>
