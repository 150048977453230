<template>
  <div class="staff-customer">
    <div class="staff-customer-input">
      <el-input
          v-model="keyword"
          :placeholder="$t('common.search')"
          size="small"
          @keyup.enter.native="searchEmployee"
          @input="monitorSearchNull"
      ></el-input>
    </div>
    <el-tabs v-model="activeName">
      <el-tab-pane :label="$t('accountManage.memberTitle')" name="staff" v-if="dataType === 'all' || dataType === 'staff'">
        <el-select
            v-model="corpId"
            :placeholder="$t('accountManage.selectPlaceholder')"
            v-if="corpList.length > 1"
            size="small"
            style="width: 100%;margin-bottom: 10px"
        >
          <el-option
              v-for="item in corpList"
              :key="item.originData.corpId"
              :label="item.label"
              :value="item.originData.corpId">
            <open-data :type="'departmentName'" :openid="item.label" v-if="item.originData && item.originData.wechatBotDTO && item.originData.wechatBotDTO.bindType == 0 && item.originData.wechatBotDTO.wechatType == 0"></open-data>
            <span v-else>{{ item.label }}</span>
          </el-option>
        </el-select>
        <div v-for="(item,index) in treeData" :key="index">
          <el-tree
              key="selectOrganizationTreeLazy"
              :data="[item]"
              id="selectOrganizationTreeLazy"
              class="filter-tree"
              node-key="id"
              :default-expanded-keys="defaultExpandedKeysMember"
              :expand-on-click-node="false"
              :props="{
                isLeaf: 'leaf',
              }"
              :ref="item.id+'TreeLazy'">
            <span
                class="custom-tree-node"
                slot-scope="{node,data}" @click="onNodeClick(data,'',item,node)">
              <span class="custom-tree-node-left" :class="data.dataType == 'hasNext'?'load-more-node':''">
                <!-- // 员工树结构的数据   0:部门，1:员工，2:上下游部门，3:上下游成员 数字型字段-->
                <i
                    :class="['el-icon-caret-right',data.showChildren?'down':'right']"
					          v-show="data.type === 1 && !data.isLeaf"
                    @click.stop="nodeExpand(data,node,item)">
                </i>
                <span
                    class="data-label"
                    v-if="
                    data.isWeWorkThirdData ||
                    item.isWeWorkThirdData ||
                    (item.originData&&
                    item.originData.wechatBotDTO&&
                    item.originData.wechatBotDTO.bindType===0&&
                    item.originData.wechatBotDTO.wechatType===0)">
                  <open-data :type="data.type == 1 ? 'departmentName' : 'userName'"
                             :openid="data.label"></open-data>
                </span>
                <span v-else class="data-label">
                  <span v-if="chooseType === 'dept'">
                    <span v-if="data.type == 1 || data.dataType == 'hasNext'">{{ data.label }}</span>
                  </span>
                  <span v-else>{{ data.label }}</span>
                </span>
                <template v-if="data.originData && data.originData.departments && keyword && data.type == 0">
                  <span>&#8195</span>
                  <div v-for="(cell, index) in data.originData.departments"
                       :key="index"
                  >
                    <el-tooltip placement="top" effect="light">
                      <div slot="content">
                        (
                        <template
                            v-if="data.isWeWorkThirdData"
                        >
                          <open-data :type="'departmentName'"  :openid="cell.fullName"></open-data>
                        </template>
                        <span
                            v-else
                            v-html="cell.fullName"
                        ></span>
                        )
                      </div>
                      <div
                          v-if="data.isWeWorkThirdData"
                      >
                        ( <open-data :type="'departmentName'"  :openid="cell.name"></open-data> )
                      </div>
                      <span v-else>({{ cell.name }})</span>
                    </el-tooltip>
                  </div>
                </template>
              </span>
              <span v-show="data.checked">
                <i :class="['el-icon-check']"></i>
              </span>
            </span>
          </el-tree>
        </div>
      </el-tab-pane>
      <el-tab-pane :label="$t('upDownManage.selectUpDown')" name="upDown" v-if="dataType === 'all' || dataType === 'updown'">
        <el-tree
            class="updown-tree"
            :props="{
          isLeaf: 'leaf',
          label: 'name',
          children:'children'
        }"
            :data="updownDateTree"
            :load="loadNode"
            lazy
            ref="upDownTree"
            :expand-on-click-node="false"
            node-key="id">
          <span :class="['custom-tree-node',!data.wxOpenId && data.selfType===3 && !insertIm ? 'not-allowed' : 'allowed']"
                slot-scope="{node,data}" @click="onNodeClick(data,'updown',data,node)">
            <span class="custom-tree-node-left">
              <span class="data-label">{{ data.name }}</span>
            </span>
            <span v-if="data.checked">
              <i :class="['el-icon-check']"></i>
            </span>
          </span>
        </el-tree>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import OpenData from "../../../components/openData";

export default {
  name: "staffCustomer",
  components: { OpenData },
  data() {
    return {
      activeName: "staff",
      defaultProps: {
        label: "label",
        children: "children",
        isLeaf: "leaf"
      },
      clientId: "",
      keyword: "",
      defaultExpand: false,
      checkMemberList: [],
      isLazy: false,
      defaultExpandedKeys: [],
      defaultExpandedKeysMember: [],
      treeData: [],
      updownDateTree: [],
      corpId: "",
      currentPage: 1,
      pageSize: 20,
      corpList: [],
      staffHasNext: false,
      updownNode: "",
      updownResolve: "",
      newCurrentPage:1,
      newPageSize:15,
      userCorpId:''
    };
  },
  props: ["selectClient", "clientType", "clientValue", "memberInfo4Client", "isMultiple","checkedList","isChooseDeptMember",'dataType','chooseType', 'insertIm'],
  mounted() {
    console.log(this.memberInfo4Client,'memberInfo4Client');
    this.getData(null);
  },
  methods: {
    // 递归获取所有节点
    handlerUpDown(moduleDataList, data) {
      return new Promise((resolve) => {
        if (moduleDataList) {
          for (let i = 0; i < moduleDataList.length; i++) {
            this.$set(moduleDataList[i], "checked", false);
            if (moduleDataList[i].children && moduleDataList[i].children.length > 0) {
              this.handlerUpDown(moduleDataList[i].children, data);
            }
          }
        }
        resolve(true);
      });
    },
    //懒加载上下游员工
    loadNode(node, resolve) {
      console.log("懒加载");
      let uid = localStorage.getItem("_uid");
      if (node.level === 0) {
        let datas = [];
        this.updownNode = node;
        this.updownResolve = resolve;
        let url = "/api/upAndDown/selectOrganizationById" + "?userId=" + uid;
        // this.$http.get(url).then(res => {
        this.$http.get(url).then(res => {
          datas = res.data.data || [];
          datas.forEach(element => {
            element.leaf = false;
            element.checked = false;
            if (element.organizationId) {
              element.selfType = 3;
            } else {
              element.selfType = 2;
            }

          });
          this.updownDateTree = datas;
          this.getAllNodeId(datas, "show");
          return resolve(datas);

        });
        /* return resolve([{ name: 'region' }]);*/
      }
      if (node.level >= 1) {
        let datas = [];
        let userDatas = [];
        let url = "/api/upAndDown/selectOrganizationById" + "?id=" + node.data.id + "&userId=" + uid;
        this.$http.get(url).then(res => {
          datas = res.data.data || [];
          datas.forEach(element => {
            element.leaf = false;
            element.checked = false;
            if (element.organizationId) {
              element.selfType = 3;
            } else {
              element.selfType = 2;
            }
          });
          let departUrl = "/api/upAndDown/selectUpAndDownMemberByOrganizationId?organizationId=" + node.data.id + "&userId=" + uid;
          this.$http.get(departUrl).then(res => {
            userDatas = res.data.data;
            userDatas.forEach(element => {
              element.leaf = true;
              element.checked = false;
              if (element.organizationId) {
                element.selfType = 3;
              } else {
                element.selfType = 2;
              }
            });
            datas = datas.concat(userDatas);
            this.getAllNodeId(datas, "show");
            node.data.children = datas;
            return resolve(datas);
          });
        });
      }
    },
    // 节点被展开
    nodeExpand(data, node,item) {
      console.log(257);
      data.showChildren = !data.showChildren;
      if (item.originData){
        let corpId = item.originData.corpId;
        this.userCorpId = corpId;
      }
      if (data.showChildren) {
        // this.getData(data.id, data, node);
        this.newCurrentPage = 1;
        this.getUserLazy(data.id, data, node);
      } else {
        this.newCurrentPage = 1;
        data.children = [];
      }
    },
    // 获取员工数据
    getData(departId, data, node) {
      let axiosDatas = [];
      let userId = localStorage.getItem("_uid");
      let url = departId ? "/api/department/corpListLazy" + "?departId=" + departId + "&userId=" + userId : "/api/department/corpListLazy" + "?userId=" + userId;
      this.$http.get(url).then(res => {
        let ids = [];
        let _res = []
        // 员工树结构的数据 type=0是员工，type=1是部门
        if(this.chooseType === 'dept'){
          res.data.forEach((resItem,resIndex) => {
            if(resItem.type === 1){
              _res.push(resItem)
            }
          })
          if(_res.length === 0){
            this.$set(data, "isLeaf", true);
          }
        } else {
          res.data.forEach((resItem,resIndex) => {
            resItem.isLeaf = false;
          })
          _res = res.data;
        }
        axiosDatas = this.handlerCheckedSelf(_res || [], [], departId ? departId : -1);
        this.getAllNodeId(axiosDatas, "show");
        if (departId) {
          data.children = [...data.children, ...axiosDatas];
          this.defaultExpandedKeysMember = [...this.defaultExpandedKeysMember, ...[departId]];
          data.showChildren = true;
        } else {
          this.treeData = [...axiosDatas];
          this.defaultExpandedKeysMember = [];
          this.corpList = JSON.parse(JSON.stringify(axiosDatas));
        }
        this.treeData.forEach(item => {
          if (!departId) {
            item.showChildren = false;
            item.children = [];
          }
          this.$nextTick(() => {
            let ref = item.id + "TreeLazy";
            setTimeout(() => {
              this.getNodeChecked(ref, item, ids);

            }, 500);
          });
        });
        if (this.treeData.length > 0){
          this.corpId = this.treeData[0].originData?this.treeData[0].originData.corpId:''
        }
        console.log('treeData',this.treeData)
      });
    },
    getUserLazy(departId, data, node, isLoadMore) {
      let axiosDatas = [];
      let userId = localStorage.getItem('_uid');
      let url = departId
          ? "/api/department/corpListLazy-v2" +
          "?departId=" +
          departId +
          "&userId=" +
          userId +
          "&corpId=" +
          this.userCorpId + '&page=' + this.newCurrentPage + '&size=' + this.newPageSize
          : "/api/department/corpListLazy-v2" +
          "?userId=" +
          userId +
          "&corpId=" +
          this.userCorpId  + '&page=' + this.newCurrentPage + '&size=' + this.newPageSize;
      this.$http.get(url).then((res) => {
        let ids = [];
        axiosDatas = this.handlerCheckedSelf(res.data.list || [],
            ids,
            departId ? departId : -1
        );
        console.log(res.data.list, axiosDatas);
        if (this.chooseType === 'dept'){
          axiosDatas = axiosDatas.filter(item => item.type == 1)
        }
        this.getAllNodeId(axiosDatas, "show");
        if (departId) {
          if (res.data.hasNextPage){
            let info = {
              label: this.$t('index.more'),
              dataType: 'hasNext',
              id: departId + 'hasNext',
              parentId: departId,
              currentPage: res.data.nextPage ? res.data.nextPage : 1
            }
            axiosDatas = axiosDatas.concat([info])
          }
          axiosDatas.forEach(element => {
            if(element.type === 0 || element.dataType === 'hasNext') {
              element.leaf = true
            }
          })
          if (isLoadMore) {
            let flagFind = true;
            let findParent = (list) => {
              if (flagFind) {
                list.forEach(item => {
                  if (item.id == departId) {
                    flagFind = false;
                    if (item.children[item.children.length - 1].dataType == 'hasNext') {
                      item.children.length = item.children.length - 1;
                    }
                    item.children = [...item.children, ...axiosDatas];
                    this.defaultExpandedKeysMember = [
                      ...this.defaultExpandedKeysMember,
                      ...[departId],
                    ];
                    item.showChildren = true;
                  } else {
                    findParent(item.children);
                  }
                })
              }

            }
            findParent(this.treeData);
          } else {
            data.children = [...data.children, ...axiosDatas];
            this.defaultExpandedKeysMember = [
              ...this.defaultExpandedKeysMember,
              ...[departId],
            ];
            data.showChildren = true;
          }
        } else {
          this.treeData = [...axiosDatas];
          this.defaultExpandedKeysMember = [];
        }
        this.treeData.forEach((item) => {
          if (!departId) {
            item.showChildren = false;
            item.children = [];
          }
        });
      });
    },
    getNodeChecked(ref, data, ids) {
      if (ids.indexOf(data.id) > -1) {
        if (this.$refs[ref] && this.$refs[ref][0]) {
          this.$set(this.$refs[ref][0].getNode(data.id).data, "checked", true);
        }
      }
      if (data.children) {
        data.children.forEach(item => {
          this.getNodeChecked(ref, item, ids);
        });
      }
    },
    handlerCheckedSelf(datas, ids, parentId) {
      datas.forEach(item => {
        item.checked = false;
        item.showChildren = false;
        if (item.type === 1) {
          item.selfType = 0;
        } else if (item.type === 0) {
          item.selfType = 1;
        }
        // if(ids.indexOf(item.id) > -1){
        // 	let ref = item.id +'TreeLazy'
        // 	// type类型 -- 保存接口需要 ,0:部门，1:员工，2:上下游部门，3:上下游成员 数字型字段

        // 	this.handlerCheckedSelf(item.children || [],ids,item.id || null);
        // }  else {
        this.handlerCheckedSelf(item.children || [], ids, item.id || null);
        // }

      });
      return datas;
    },
    //选择员工报单人
    onNodeClick(node, staffType, corpItem, row) {
      console.log(node, staffType, row, "点击");
      if (node.dataType === "hasNext") {
        /* 点击加载更多 */
        this.newCurrentPage = node.currentPage;
        this.getUserLazy(node.parentId, {}, "", true);
      } else {
        let item = {}
        let type = "";
        if (!staffType) {
          let selectCorp = this.corpList.filter(item=>item.originData.corpId == this.corpId);
          item = this.keyword&& selectCorp.length > 0 ?selectCorp[0]:corpItem;//搜索时取
          type = node.isWeWorkThirdData ||
          item.isWeWorkThirdData ||
          (item.originData &&
              item.originData.wechatBotDTO &&
              item.originData.wechatBotDTO.bindType === 0 &&
              item.originData.wechatBotDTO.wechatType === 0) ? "staff-t" : "staff";
        } else {
          type = staffType;
          item = corpItem
        }
        this.$set(node, "staffType", type);
        console.log(type, "typetypetypetype");
        if (!this.isMultiple) {
          if (type === "updown") {
            let obj = JSON.parse(JSON.stringify(node));
            // let flag = obj.checked;
            // 	// selfType -- 保存接口需要 ,0:部门，1:员工，2:上下游部门，3:上下游成员 数字型字段
            if (node.selfType == 2) {
              this.getAllNodeId(this.treeData, "").then(res => {

              });
              this.handlerUpDown(this.updownDateTree, node);
              this.$set(node, "checked", true);
              this.$emit("onNodeClick", node, type, item, row);
            } else {
              if (node.wxOpenId && node.organizationId && staffType === "updown") {
                this.getAllNodeId(this.treeData, "").then(res => {

                });
                this.handlerUpDown(this.updownDateTree, node);
                this.$set(node, "checked", true);
                this.$emit("onNodeClick", node, type, item, row);
              }
            }

          } else {
            this.handlerUpDown(this.updownDateTree, node);
            this.getAllNodeId(this.treeData, "").then(res => {
              this.$set(node, "checked", true);
              this.clientId = node.id;
              if (type === "staff-t") {
              }
              this.$emit("onNodeClick", node, type, item, row);
            });
          }
        } else {
          if (type === "updown") {
            if (node.selfType == 2) {
              let obj = JSON.parse(JSON.stringify(node));
              let flag = obj.checked;
              if (staffType === "updown") {
                this.$set(node, "checked", !flag);
                this.$emit("onNodeClick", node, type, item, row);
              }
            } else {
              if ((node.wxOpenId || this.insertIm) && node.organizationId && staffType === "updown") {
                let obj = JSON.parse(JSON.stringify(node));
                let flag = obj.checked;
                if (staffType === "updown") {
                  this.$set(node, "checked", !flag);
                  this.$emit("onNodeClick", node, type, item, row);
                }
              }
            }
          } else {
            this.$set(node, "checked", !node.checked);
            this.$emit("onNodeClick", node, type, item, row);
          }
        }
      }
    },
    ergodicClient(type) {
      return new Promise((resolve) => {
        this.selectClient.forEach(client => {
          this.$set(client, "checked", false);
        });
        this.selectClient.forEach(client => {
          //   if (!type){
          if (!this.isMultiple) {
            if (client.id === this.clientValue) {
              this.$set(client, "checked", true);
            }
          } else {
            this.clientValue.forEach(item => {
              if (client.id === item) {
                this.$set(client, "checked", true);
              }
            });
          }
          //   }


        });
        resolve(true);
      });

    },
    // 递归获取所有节点
    getAllNodeId(moduleDataList, type) {
      return new Promise((resolve) => {
        if (moduleDataList) {
          let checkedList = this.isChooseDeptMember?this.checkedList:this.memberInfo4Client.clientInfo
          for (let i = 0; i < moduleDataList.length; i++) {
            this.$set(moduleDataList[i], "checked", false);
            if (type) {
              if (!this.isMultiple) {
                if (moduleDataList[i].id == Number(this.memberInfo4Client.memberId)) {
                  this.$set(moduleDataList[i], "checked", true);
                }
              } else {
                checkedList && checkedList.forEach(item => {
                  if (moduleDataList[i].id == item.id) {
                    this.$set(moduleDataList[i], "checked", true);
                  }
                });
              }
            }
            if (moduleDataList[i].children) {
              this.getAllNodeId(moduleDataList[i].children, type);
            }
          }
        }
        resolve(true);
      });

    },
    //多选遍历树节点
    ergodicMemberTree(moduleDataList, node) {
      return new Promise((resolve) => {
        if (moduleDataList) {
          for (let i = 0; i < moduleDataList.length; i++) {
            this.$set(moduleDataList[i], "checked", false);
            if (moduleDataList[i].children) {
              this.ergodicMemberTree(moduleDataList[i].children, node);
            }
          }
        }
        resolve(true);
      });
    },
    //在触发条件下遍历树节点
    ergodicMemberTree2(moduleDataList, type) {
      if (moduleDataList) {
        for (let i = 0; i < moduleDataList.length; i++) {
          if (moduleDataList[i].checked) {
            if (type === "treeData") {
              moduleDataList[i].staffType = moduleDataList[i].originData &&
              moduleDataList[i].originData.wechatBotDTO &&
              moduleDataList[i].originData.wechatBotDTO.bindType === 0 &&
              moduleDataList[i].originData.wechatBotDTO.wechatType === 0 ? "staff-t" : "staff";
            }
            this.checkMemberList.push(moduleDataList[i]);
            console.log(this.checkMemberList, 23333333);
          }
          if (moduleDataList[i].children) {
            this.ergodicMemberTree2(moduleDataList[i].children, type);
          }
        }
      }
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    //搜索成员
    searchEmployee(type) {
      let departId = "";
      console.debug("updown11", this.activeName);
      if (this.activeName == "staff" && this.keyword) {
        if (type !== "load") {
          this.treeData = [];
          this.currentPage = 1;
        }
        let userId = localStorage.getItem("_uid");
        let url = "/api/department/corpListLazy-v2?" + "userId=" + userId + "&corpId=" + this.corpId + "&keyword=" + this.keyword + "&page=" + this.currentPage + "&size=" + this.pageSize;
        this.$http.get(url).then(res => {
          console.debug("get member", res);
          let list = res.data && res.data.list ? res.data.list : [];
          this.staffHasNext = res.data.hasNextPage;
          let ids = [];
          let _res = []
          // 员工树结构的数据 type=0是员工，type=1是部门
          if(this.chooseType === 'dept'){
            list.forEach((listItem,listIndex) => {
              if(listItem.type === 1){
                _res.push(listItem)
              }
            })
          } else {
            _res = list;
          }
          _res.forEach(item => {
            this.$set(item, "checked", false);
            if (ids.includes(item.id)) {
              this.$set(item, "checked", true);
            }
            if (item.type === 1) {
              item.selfType = 0;
            } else if (item.type === 0) {
              item.selfType = 1;
            }
          });
          if (type !== "load") {
            this.treeData = _res;
          } else {
            this.treeData = [...this.treeData, ..._res];
          }
          this.getAllNodeId( this.treeData , "show");
          console.log(this.treeData);
        });
      } else if (this.activeName == "upDown" && this.keyword) {

        this.searchUpAndDown();
      }

    },
    //分页加载更多
    loadStaffMore() {
      this.currentPage += 1;
      this.searchEmployee("load");
    },
    //搜索上下游
    searchUpAndDown() {
      let userId = localStorage.getItem("_uid");
      let url = "/api/upAndDown/searchUpDownMemberOrDepart?userId=" + userId + "&keyword=" + this.keyword;
      this.$http.get(url).then(res => {
        console.debug("get updown", res);
        if (res.data.code == 0) {
          let list = res.data.data ? res.data.data : [];
          list.forEach(item => {
            let leaf = item.type == 1 ? false : true;
            this.$set(item, "leaf", leaf);
            item.type = "updown";
            this.$set(item, "checked", false);
            if (item.organizationId) {
              item.selfType = 3;
            } else {
              item.selfType = 2;
            }
          });
          this.updownDateTree = list;
          this.getAllNodeId(this.updownDateTree, "show");
        }
      });

    },
    //监听搜索框为空时
    monitorSearchNull() {
      if (this.activeName == "staff" && !this.keyword) {
        this.treeData = [];
        this.getData(null);
      } else if (this.activeName === "upDown" && !this.keyword) {
        this.updownDateTree = [];
        this.loadNode(this.updownNode, this.updownResolve);
      }
    }
  },
  watch: {
    keyword(val) {
      let tree = this.$refs.tree;
      if (tree) {
        tree.forEach(item => {
          item.filter(val);
        });
      }
    },
    activeName(value) {
      console.log(value,'log out activeName');
    }
  }
};
</script>

<style lang="less">
.staff-customer-input {
  height: 42px;
}

.member-contanier {

  .customer-list {
    .customer-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 30px;
      padding: 0 0 0 4px;
      cursor: pointer;

      .el-icon-check {
        font-size: 14px;
        font-weight: 600;
      }
    }

    .customer-item:hover {
      background-color: #F6F8FD;
    }
  }

  .load-more-client {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 12px;
  }

  .custom-tree-node {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;

    .custom-tree-node-left {
      display: flex;
      align-items: center;
     /* width: 90%;*/

      i {
        font-size: 14px;
        display: flex;
        align-items: center;
        padding-right: 6px;
        color: #c0c4cc;
      }

      .data-label {
       /* width: 100%;*/
        height: 28px;
        display: flex;
        align-items: center;
      }
    }
    .load-more-node{
      .data-label{
        color: #366aff;
        cursor:pointer;
      }
    }
    .el-icon-check {
      font-size: 14px;
      font-weight: 600;
    }

    &.not-allowed {
      cursor: not-allowed !important;
    }
  }

  .el-tabs__nav-wrap::after {
    height: 0px !important;
  }

  ::v-deep.el-tree-node__content {
    &:hover {
      background-color: #F6F8FD;
    }
  }

  .el-tree-node:focus > .el-tree-node__content {
    background-color: white !important;
  }

  .el-tree-node__content {
    height: 30px !important;
  }

  .el-tree-node__content:hover {
    background-color: #F6F8FD !important;
  }

}
.choose-dept-member{
  .custom-tree-node {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;

    .custom-tree-node-left {
      display: flex;
      align-items: center;
     /* width: 90%;*/

      i {
        font-size: 14px;
        display: flex;
        align-items: center;
        padding-right: 6px;
        color: #c0c4cc;
      }

      .data-label {
        /*width: 100%;*/
        height: 28px;
        display: flex;
        align-items: center;
      }
    }
    .load-more-node{
      .data-label{
        color: #366aff;
        cursor:pointer;
      }
    }
    .el-icon-check {
      font-size: 14px;
      font-weight: 600;
    }

    &.not-allowed {
      cursor: not-allowed !important;
    }
  }
}
.staff-customer {
  min-height: 300px;

  .el-tree {

    .el-tree-node__expand-icon {
      display: none;
      font-size: 14px;
    }

    .el-icon-caret-right {
      &.down {
        transform: rotate(90deg);
      }

      &.right {
        transform: rotate(0)
      }
    }

    &.updown-tree {
      .el-tree-node__expand-icon {
        display: inline-block;
      }
    }
  }
}
</style>