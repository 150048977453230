<template>
  <div class="basicInformation">
    <el-form
        ref="”bindForm“"
        :rules="bindRules"
        :model="websiteBindForm"
    >
      <el-form-item label="网站名称">
        <span slot="label">
          <span>{{$t('botLink.websiteName')}}</span>
          <span class="red" style="margin-left:3px;color: red;">*</span>
        </span>
        <el-input
            :placeholder="$t('botLink.websiteNamePla')"
            v-model="websiteBindForm.websiteName"
        ></el-input>
      </el-form-item>
      <el-form-item label="网站域名">
        <span slot="label">
          <span>{{$t('botLink.websiteDomainName')}}</span>
          <span class="red" style="margin-left:3px;color: red;">*</span>
        </span>
        <el-input
            :placeholder="$t('botLink.websiteDomainNamePla')"
            v-model="websiteBindForm.domain"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('channel.website.login')">
       <div class="login">
         <el-switch
             v-model="websiteBindForm.needLogin"
             @click.native.prevent="changeLoginSwitch('switch')"
             active-color="#366aff"
             inactive-color="#E2E2E2">
         </el-switch>
         <span
             class="switch-open-icon"
             @click="changeLoginSwitch('open')"
             v-if="websiteBindForm.needLogin"
             @click.native="clickFn"
         >
           <i class="iconfont guoran-a-16-17"></i>
         </span>
         <span
             class="switch-close-icon"
             @click="changeLoginSwitch('close')"
             v-if="!websiteBindForm.needLogin">
           <i class="arsenal_icon arsenalcuo1"></i>
         </span>
         <div class="tips">
           <img src="@/assets/images/fav.png">
           {{$t('channel.website.loginTip')}}
         </div>
       </div>
      </el-form-item>
      <el-form-item label="登录方式">
         <div slot="label" class="loginWay">
           <span>{{$t('channel.website.loginWay')}}</span>
           <span class="tips">
             <i class="iconfont guoran-guanzhuren"></i>
             {{$t('channel.website.wayTip')}}
           </span>
         </div>
        <div class="company">
          <el-radio-group
              v-model="websiteBindForm.corpId"
              @change="changeCompany"
          >
            <el-radio :label="item.corpId" v-for="item in loginModeOptions">{{item.corpName}}</el-radio>
          </el-radio-group>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { setI18nFormInfo } from "@/utils/common";
export default {
  name: "basicInformation",
  data(){
    return{
      bindRules:{
        websiteName: [
          {
            required: true,
            message: "",
            trigger: "blur",
          }
        ],
        domain: [
          {
            required: true,
            message: "",
            trigger: "blur",
          }
        ]
      },
      websiteBindForm: { websiteName: "", domain: "", botId: "",needLogin:false,corpId:"",corpName:"",channel:"" },
      loginModeOptions:[]
    }
  },
  props:{
    bindDetail:{
      type:Object,
      default(){
        return {}
      }
    }
  },
  methods:{
    changeLoginSwitch(datas){
      if(datas === 'open'){
        this.websiteBindForm.needLogin = false;
      } else if (datas === 'close'){
        this.websiteBindForm.needLogin = true;
      }
    },
    // 获取主体下的公司
    getCorps4main() {
      this.$http.get(this.requestUrl.websiteBind.corps4main).then((res) => {
        if (res.data.code == 0) {
          this.loginModeOptions = res.data.data || [];
          if (!this.websiteBindForm.corpId && this.loginModeOptions.length > 0){
            this.websiteBindForm.corpId = this.loginModeOptions[0].corpId;
            this.websiteBindForm.corpName = this.loginModeOptions[0].corpName;
            this.websiteBindForm.channel = this.loginModeOptions[0].corpType;
          }
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    clickFn(item){
      if(item.bindBot && item.bindBot.botInfos && item.bindBot.botInfos.length > 1){
        this.$message.warning(this.$t('channel.website.bindBotTip'));
      }
    },
    changeCompany(val){
      let data = this.loginModeOptions.find(item => item.corpId === val);
      this.websiteBindForm.corpName = data.corpName;
      this.websiteBindForm.channel = data.corpType;
      console.log('changeCompany',data);
    },
  },
  mounted() {
    setI18nFormInfo(this.bindRules, this)
    this.getCorps4main();
  },
  watch:{
    bindDetail:{
      handler(){
        if (Object.keys(this.bindDetail).length > 0){
          this.websiteBindForm.websiteName = this.bindDetail.agentName;
          this.websiteBindForm.domain = this.bindDetail.agentId;
          this.websiteBindForm.channel = this.bindDetail.channel;
          this.websiteBindForm.corpId = this.bindDetail.corpId;
          this.websiteBindForm.corpName = this.bindDetail.corpName;
          this.websiteBindForm.needLogin = this.bindDetail.needLogin;
          this.websiteBindForm.id = this.bindDetail.id;
        }
      },
      immediate:true
    }
  }
};
</script>

<style scoped lang="less">
.basicInformation{
  .login{
    display: flex;
    align-items: center;
    background-color: #F6F8FD;
    padding: 2px 10px;
    border-radius: 5px;
    position: relative;
    .tips{
      font-size: 13px;
      color: #999999;
      margin-left: 14px;
      display: flex;
      align-items: center;
      img{
        width: 18px;
        margin-right: 4px;
      }
    }
    .switch-open-icon {
      position: absolute;
      z-index: 999;
      top: 50%;
      transform: translateY(-50%);
      left: 12px;
      i {
        color: white;
        font-size: 16px;
      }
    }

    .switch-close-icon {
      position: absolute;
      z-index: 999;
      top: 50%;
      transform: translateY(-50%);
      left: 32px;
      i {
        color: #6E6B7B;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
  .loginWay{
    display: flex;
    align-items: center;
    .tips{
      margin-left: 14px;
      color: #999999;
      font-size: 13px;
      i{
        color: #FF9555;
        margin-right: 4px;
      }
    }
  }
  .company{
    display: flex;
    align-items: center;
    background-color: #F6F8FD;
    padding: 10px;
    border-radius: 5px;
    flex-wrap: wrap;
    //height: 40px;
    /deep/.el-radio{
      line-height: 24px;
    }
  }
}
</style>