var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"action_enforce"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.type == 'action_enforce'),expression:"type == 'action_enforce'"}],staticClass:"top-tip"},[_vm._v(" "+_vm._s(_vm.$t('flowComponent.enforceTip1'))+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.type == 'action_random_jump'),expression:"type == 'action_random_jump'"}],staticClass:"top-tip"},[_vm._v(" "+_vm._s(_vm.$t('flowComponent.enforceTip2'))+" ")]),_c('div',{staticClass:"goto-list"},_vm._l((_vm.unit.content.gotoList),function(cell,index){return _c('div',{key:index,staticClass:"jump-cell"},[_c('div',{staticClass:"jump-cell-l"},[_c('div',{staticClass:"left-select"},[_c('el-select',{staticStyle:{"width":"120px"},attrs:{"size":"small","placeholder":_vm.$t('common.selectPlaceholder')},on:{"change":function($event){return _vm.jumpTypeValueChange($event, index)}},model:{value:(cell.scope),callback:function ($$v) {_vm.$set(cell, "scope", $$v)},expression:"cell.scope"}},_vm._l((_vm.filterJumpTypeOptions(
                                _vm.jumpTypeOptions
                            )),function(item){return _c('el-option',{key:item.value,attrs:{"label":_vm.$t('common.' + item.value),"value":item.value}})}),1)],1),_c('div',{ref:"selectCellSubContentAftWidth",refInFor:true,staticClass:"right-select select-cell-sub-content-aft"},[(cell.scope === 'NODE')?_c('SelectAddnode',{attrs:{"nodeOptions":_vm.nodeOptions,"nodeId":cell.value,"defaultParentNodeId":_vm.nodeId,"nodeSelectARef":`actionEnforceAddnode_${_vm.com_index}_${index}`,"intentId":_vm.intentId,"index":index},on:{"getNodeOptions":_vm.getNodeOptions,"setAddNodeId":_vm.setAddNodeId}}):_vm._e(),(!_vm.isBotLink)?_c('el-cascader',{directives:[{name:"show",rawName:"v-show",value:(cell.scope === 'INTENT'),expression:"cell.scope === 'INTENT'"}],attrs:{"filterable":"","placeholder":"请选择意图","size":"small","options":_vm.intentTree4Radio,"props":{
                            emitPath: false,
                            label: 'name',
                            value: 'id',
                        }},model:{value:(cell.value),callback:function ($$v) {_vm.$set(cell, "value", $$v)},expression:"cell.value"}}):[_c('el-popover',{ref:"action_enforce_popover",refInFor:true,attrs:{"placement":"bottom","width":_vm.intentSelectAndSearchPoperWidth,"trigger":"click","popper-class":"set-welcome-message-search-intent-poprver","visible-arrow":false},on:{"show":function($event){return _vm.showPover(cell)}}},[_c('el-input',{staticClass:"search-intent-input",attrs:{"slot":"reference","size":"small","placeholder":_vm.$t('flowComponent.searchOrSelectIntent')},on:{"input":function($event){return _vm.inputFn($event,cell.valueText)}},slot:"reference",model:{value:(cell.valueText),callback:function ($$v) {_vm.$set(cell, "valueText", $$v)},expression:"cell.valueText"}},[_c('i',{staticClass:"el-icon-arrow-down",attrs:{"slot":"suffix"},slot:"suffix"})]),_c('el-tree',{ref:"tree",refInFor:true,staticClass:"choose-intent-tree",style:({width: _vm.intentSelectAndSearchPoperWidth + 'px'}),attrs:{"data":_vm.intentTree4Radio,"props":{
                                    children: 'children',
                                    label: 'name',
                                    id:'id',
                                    isLeaf: 'isLeafNode',
                                },"filter-node-method":_vm.filterNode,"node-key":"id"},scopedSlots:_vm._u([{key:"default",fn:function({node,data}){return _c('span',{class:['custom-tree-node',  data.id.indexOf('B') === -1 && data.id.indexOf('S') === -1 && data.id.indexOf('T') === -1  ? '' : 'disabled'],on:{"click":function($event){return _vm.onNodeClick(data,node,cell)}}},[_c('span',{staticClass:"custom-tree-node-left"},[_c('span',{class:['data-label']},[_vm._v(_vm._s(data.name))])]),(data.checked)?_c('i',{staticClass:"el-icon-check"}):_vm._e()])}}],null,true)})],1)]],2)]),(index == 0)?_c('span',{staticClass:"el-icon-plus-box",on:{"click":_vm.addGoto}},[_c('i',{staticClass:"el-icon-plus"})]):_vm._e(),(index !== 0)?_c('span',{staticClass:"el-icon-delete-box",on:{"click":function($event){return _vm.deleteGoto(index)}}},[_c('i',{staticClass:"el-icon-close"})]):_vm._e()])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }