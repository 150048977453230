<template>
  <div class="accessInfo">
    <div class="container">
      <template v-if="channelType === 'website'">
        <div class="title">
          <div class="left">
            {{$t('channel.website.copyHeader')}}
            <span class="tips">{{$t('channel.website.copyHeaderTip')}}</span>
          </div>
          <div class="copy"
               v-clipboard:copy="bindDetail.websiteJsCode"
               v-clipboard:success="onCopy"
               v-clipboard:error="onError"
          >{{$t('channel.copy')}}</div>
        </div>
        <json-viewer
            :value="bindDetail.websiteJsCode"
            :expand-depth=5
            copyable
            boxed
            sort>
        </json-viewer>
        <div class="copyTips">
          {{$t('channel.website.copyTips')}}
        </div>
      </template>
      <template v-if="channelType === 'app'">
        <div class="title">
          <div class="left">
            {{$t('channel.appBot.copyHeader')}}
            <span class="tips">{{$t('channel.appBot.copyHeaderTip')}}</span>
          </div>
          <div class="copy"
               v-clipboard:copy="bindDetail.websiteJsCode"
               v-clipboard:success="onCopy"
               v-clipboard:error="onError"
          >{{$t('channel.copy')}}</div>
        </div>
        <json-viewer
            :value="bindDetail.websiteJsCode"
            :expand-depth=5
            copyable
            boxed
            sort>
        </json-viewer>
        <div class="copyTips">
          {{$t('channel.website.copyTips')}}
        </div>
      </template>
      <template v-else-if="channelType === 'wechatAccount'">
        <div class="title">
          <div class="copy"
               v-clipboard:copy="bindDetail.authUrl"
               v-clipboard:success="onCopy"
               v-clipboard:error="onError"
          >{{$t('channel.copy')}}</div>
        </div>
        <div class="auth_url">
          {{bindDetail.authUrl}}
        </div>
        <div class="copyTips">
          {{$t('channel.wechatAccount.copyTips')}}
        </div>
      </template>
      <template v-else-if="channelType === 'dingSingleChatBot'">
        <div class="title">
          <div class="left">
            {{$t('channel.dingSingleChatBot.tip1')}}
          </div>
          <div class="copy"
               v-clipboard:copy="bindDetail.authUrl"
               v-clipboard:success="onCopy"
               v-clipboard:error="onError"
          >{{$t('channel.copy')}}</div>
        </div>
        <div class="auth_url">
          {{bindDetail.authUrl}}
        </div>
        <div class="ipBox">
          <div class="tips">
            {{$t('channel.dingSingleChatBot.tip2')}}
          </div>
          <div class="box">
            <div class="left">{{dingtalkWhite}}</div>
            <div class="copy"
                 v-clipboard:copy="dingtalkWhite"
                 v-clipboard:success="onCopy"
                 v-clipboard:error="onError"
            >{{$t('channel.copy')}}</div>
          </div>
        </div>
        <div class="interfaceBox">
          <div class="tips">
            {{$t('channel.dingSingleChatBot.tip3')}}
          </div>
          <img
              src="../../../assets/images/dingdingSing6.png"
              width="100%"
              height="auto"
              alt=""
              srcset=""
          />
        </div>
      </template>
      <template v-else-if="channelType === 'yunzhijia'">
        <div class="title">
          <div class="copy"
               v-clipboard:copy="bindDetail.authUrl"
               v-clipboard:success="onCopy"
               v-clipboard:error="onError"
          >{{$t('channel.copy')}}</div>
        </div>
        <div class="auth_url">
          {{bindDetail.authUrl}}
        </div>
        <div class="cloudBox">
          <div class="tips1">
            {{$t('channel.yunzhijia.tip1')}}
          </div>
          <div class="tips2">
            {{$t('channel.yunzhijia.tip2')}}
          </div>
        </div>
      </template>
      <template v-else-if="channelType === 'dingding'">
        <template v-if="RP_Visible('OPEN_ACCESS_EWX') && bindDetail.bindType == 1">
          <div class="title">
            <div class="left">
              {{$t('channel.dingding.tip1')}}
            </div>
            <div class="copy"
                 v-clipboard:copy="bindDetail.authUrl"
                 v-clipboard:success="onCopy"
                 v-clipboard:error="onError"
            >{{$t('channel.copy')}}</div>
          </div>
          <div class="auth_url">
            {{bindDetail.authUrl}}
          </div>
        </template>
        <div class="ipBox">
          <div class="tips">
            {{$t('channel.dingding.tip2')}}
          </div>
          <div class="box">
            <div class="left">{{dingtalkWhite}}</div>
            <div class="copy"
                 v-clipboard:copy="dingtalkWhite"
                 v-clipboard:success="onCopy"
                 v-clipboard:error="onError"
            >{{$t('channel.copy')}}</div>
          </div>
        </div>
        <div class="urlBox">
          <div class="tips">{{$t('channel.dingding.tip3')}}</div>
          <div class="box">
            <div class="left">{{openDomain}}</div>
            <div class="copy"
                 v-clipboard:copy="openDomain"
                 v-clipboard:success="onCopy"
                 v-clipboard:error="onError"
            >{{$t('channel.copy')}}</div>
          </div>
        </div>
        <div class="interfaceBox">
          <div class="tips">
            {{$t('channel.dingding.tip4')}}
          </div>
          <img
              src="../../../assets/images/WechatIMG162.jpeg"
              width="100%"
              height="auto"
              alt=""
              srcset=""
          />
        </div>
      </template>
      <template v-else-if="channelType === 'wechat'">
        <div class="title">
          <div class="copy"
               v-clipboard:copy="bindDetail.authUrl"
               v-clipboard:success="onCopy"
               v-clipboard:error="onError"
          >{{$t('channel.copy')}}</div>
        </div>
        <div class="auth_url">
          {{bindDetail.authUrl}}
        </div>
      </template>
    </div>
  </div>
</template>

<script>

export default {
  name: "accessInfo",
  data(){
    return{
      channelType:"",
      bindData:{},
      htmlCode:"</body>",
      dingtalkWhite:"",
      openDomain:""
    }
  },
  props:{
    bindDetail:{
      type:Object,
      default(){
        return {}
      }
    }
  },
  methods:{
    copyText(){

    },
    onCopy(){
      this.$message.success(this.$t('common.copySuccess'));
    },
    onError(){
      this.$message.error(this.$t('common.copyFail'));
    },
    // 获取Ip地址
    getBindDingdingIp() {
      this.$http.get(
          this.requestUrl.dingdingBind.dingtalkWhitelist,
          {}
      ).then((res) => {
        if (res.data.code === "0") {
          this.dingtalkWhite = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    getOpenDomain() {
      this.$http.get(this.requestUrl.dingdingBind.getOpenDomain, {}).then(
          (res) => {
            if (res.data.code === "0") {
              this.openDomain = res.data.data;
            } else {
              this.$message.error(res.data.message);
            }
          }
      );
    },
  },
  mounted() {
    this.channelType = this.$route.query.type;
    if (this.channelType === 'dingSingleChatBot' || this.channelType === 'dingding'){
      this.getBindDingdingIp();
    }
    if (this.channelType === 'dingding'){
      this.getOpenDomain();
    }
  }
};
</script>

<style scoped lang="less">
.accessInfo{
  .container{
    .title{
      display: flex;
      align-items: center;
      justify-content: space-between;
      //margin-bottom: 14px;
      border-radius: 5px 5px 0px 0px;
      background: #FBFCFD;
      border: 1px solid #E0E6F7;
      border-bottom: none;
      height: 38px;
      padding: 0 10px;
      .left{
        display: flex;
        align-items: center;
        .tips{
          color: #616161;
          margin-left: 10px;
        }
      }
      .copy{
        flex: none;
        width: 55px;
        height: 26px;
        background: #366AFF;
        border-radius: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        cursor: pointer;
      }
    }
    .copyTips{
      margin-top: 14px;
      background: #FBFCFD;
      border-radius: 5px;
      height: 38px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      color: #616161;
    }
    .ipBox,.urlBox{
      background: #FBFCFD;
      border-radius: 5px;
      padding: 14px 10px;
      margin-top: 20px;
      .tips{
        padding-bottom: 8px;
      }
      .box{
        display: flex;
        align-items: center;
        width: 100%;
        .left{
          height: 30px;
          display: flex;
          align-items: center;
          padding: 0 10px;
          border: 1px solid #E0E6F7;
          background-color: #FFFFFF;
          color: #000000;
          flex: 1;
          margin-right: 20px;
          overflow: hidden;
        }
        .copy{
          height: 26px;
          padding: 0 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #366AFF;
          color: #FFFFFF;
          border-radius: 13px;
          cursor:pointer;
          flex: none;
        }
      }
    }
    .interfaceBox{
      margin-top: 20px;
      border: 1px solid #E0E6F7;
      border-radius: 5px;
      background-color: #FFFFFF;
      .tips{
        background: #F6F8FD;
        border-radius: 5px 5px 0px 0px;
        height: 38px;
        padding: 0 10px;
        display: flex;
        align-items: center;
      }
    }
    .cloudBox{
      margin-top: 20px;
      .tips1{
        background: #FBFCFD;
        border-radius: 5px;
        color: #616161;
        padding: 14px 10px;
      }
      .tips2{
        margin-top: 20px;
        background: #FBFCFD;
        border-radius: 5px;
        color: #616161;
        padding: 14px 10px;
      }
    }
    .auth_url{
      border: 1px solid #E0E6F7;
      padding: 14px 10px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      color: #366aff;
      word-break: break-word;
    }
  }
}
</style>