
<template>
	<div class="add-member" v-if="visible">
		<el-drawer
			:visible.sync="visible"
			:with-header="false"
			size="358px"
			:destroy-on-close="true"
			:wrapperClosable="false"
			:close-on-press-escape="false">
			<div class="add-member-title">
				<h4>{{headerTitle}}</h4>
				<i class="el-icon-close" @click="closeDrawer"></i>
			</div>
			<div class="add-member-content">
				<el-form :model="ruleForm" ref="ruleForm" label-position="top" label-width="80px" class="demo-ruleForm">
					<el-form-item label="姓名" prop="name">
						<span slot="label">{{$t('addMember.name')}}<span class="red">*</span></span>
						<div class="user-name" v-if="memberData && memberData.source === 0 && (memberData.name.substr(0, 4) === 'woWe' || typeof(memberData.name) == 'string')">
							<open-data :type="'userName'" :openid="memberData.name"></open-data>
						</div>
						<el-input v-else v-model="ruleForm.name" :disabled="editDisabled" :placeholder="$t('common.placeholder')" maxlength="50"></el-input>
					</el-form-item>
					<el-form-item v-if="VUE_APP_SOURCE != 'lishi'" label="手机号" prop="mobile">
						<span slot="label">{{$t('addMember.mobile')}}<span class="red" v-if="memberData.source == -1 || memberData.source == 21 || !editDisabled">*</span></span>
						<el-input v-model="ruleForm.mobile" :placeholder="$t('common.placeholder')"></el-input>
					</el-form-item>
					<el-form-item v-if="VUE_APP_SOURCE == 'lishi'" prop="email">
						<span slot="label">{{$t('addMember.email')}}<span class="red">*</span></span>
						<el-input v-model="ruleForm.email" :placeholder="$t('common.placeholder')"></el-input>
					</el-form-item>
					<el-form-item label="所属部门" prop="organizationId">
						<span slot="label">{{$t('addMember.organizationId')}}<span class="red">*</span></span>
						<div :class="['staff-input',editDisabled ? 'staff-input-disabled' : '']" @click="openMemberTree">
							<span class="placeholder-text" v-if="checkedDeptPopup.length === 0">{{$t('common.selectPlaceholder')}}</span>
							<div v-else class="client-value">
								<div v-for="item in checkedDeptPopup" :key="item.id" class="item">
									<open-data :type="'departmentName'" :openid="item.label" v-if="memberData && memberData.source === 0 && (memberData.name.substr(0, 4) === 'woWe' || typeof(memberData.name) == 'string')"></open-data>
									<span v-else>{{item.label}}</span>
								</div>
							</div>
						</div>
						<!-- <div class="user-name" v-if="memberData && memberData.departments && memberData.departments[0] && memberData.departments[0].source === 1 && typeof(memberData.name) == 'string'">
							<open-data :type="'departmentName'" :openid="memberData.departments[0].name"></open-data>
						</div>
						 <el-cascader
						 	v-else
						 	:disabled="editDisabled"
						 	ref="cascaderCascader"
						 	:props='props'
						 	v-model="ruleForm.organizationId"
						 	style="width:320px"
							:options="treeDataCopy"
							:show-all-levels="false"></el-cascader> -->
					</el-form-item>
					<el-form-item v-if="VUE_APP_SOURCE == 'lishi'" label="手机号" prop="mobile">
						<span slot="label">{{$t('addMember.mobile')}}</span>
						<el-input v-model="ruleForm.mobile" :placeholder="$t('common.placeholder')"></el-input>
					</el-form-item>
					<el-form-item v-if="VUE_APP_SOURCE != 'lishi'" prop="email">
						<el-input v-model="ruleForm.email" :placeholder="$t('common.placeholder')"></el-input>
					</el-form-item>
					<el-form-item :label="$t('addMember.birthday')" prop="birthday">
						<el-date-picker
							v-model="ruleForm.birthday"
							type="date"
							:placeholder="$t('addMember.birthdayPlaceholder')">
						</el-date-picker>
					</el-form-item>
					<el-form-item :label="$t('addMember.staffIn')" prop="staffIn">
						<el-date-picker
							v-model="ruleForm.staffIn"
							type="date"
							:placeholder="$t('addMember.staffInPlaceholder')">
						</el-date-picker>
					</el-form-item>
					<el-form-item >
						<span slot="label" >{{$t('staffManage.setSystemPermissions')}}<span class="red">*</span></span>
                        <el-checkbox v-model="ruleForm.botAuth" :disabled="ruleForm.authFollowDepart" :label="$t('staffManage.bot')"></el-checkbox>
                        <el-checkbox v-model="ruleForm.workOrderAuth" :disabled="ruleForm.authFollowDepart" :label="$t('staffManage.workOrder')"></el-checkbox>
						<el-checkbox v-model="ruleForm.bigModel" :disabled="ruleForm.authFollowDepart" :label="$t('staffManage.bigModelCapability')"></el-checkbox>
						<el-checkbox v-model="ruleForm.authFollowDepart" :label="$t('staffManage.followDepart')" @change="authFollowDepartChange"></el-checkbox>
					</el-form-item>
					<el-form-item class="tag-hidden-close">
						<span slot="label" prop="label">{{$t('staffManage.setLabel')}}</span>
						<el-select v-model="ruleForm.tags" multiple collapse-tags :placeholder="$t('common.selectPlaceholder')">
							<el-option
								v-for="item in tagsList"
								:key="item.id"
								:label="item.label"
								:disabled="item.source == 1"
								:value="~~item.id">
							</el-option>
						</el-select>
					</el-form-item>
				</el-form>
			</div>
			<div :class="['footer-btns',memberData.id ? 'edit' : '']">
				<el-button 
					class="del-btn"
					icon="iconfont guoran-a-18-13" 
					@click="dleteMemberDialogVisible = true" 
					v-if="corpType == 21 && memberData.id && rolePrivilegesList.indexOf('PORTAL_MANAGEMENT_MEMBER_DELETE') !== -1" 
					size="small">
				</el-button>
				<el-button round @click="closeDrawer('ruleForm')">{{$t('common.close')}}</el-button>
				<el-button type="primary" round @click="submitForm('ruleForm')">{{$t('common.save')}}</el-button>
			</div>
		</el-drawer>
		<chooseDept 
			:showPopup.sync="showPopup" 
			:treeData="treeDataCopy" 
			@checkedDept="checkedDept">
		</chooseDept>
		<delete-dialog
			@closeEvent="dleteMemberDialogVisible = false"
			v-if="dleteMemberDialogVisible"
		>
			<div slot="popup-container" class="popup-container">
				<div class="delete-title">{{$t('addMember.deleteMemberTitle')}}</div>
				<div>{{$t('addMember.deleteMemberTip')}}</div>
			</div>
			<div slot="dialog-footer">
				<el-button
					@click="dleteMemberDialogVisible = false"
					size="small"
					plain>
          {{$t('common.cancel')}}
				</el-button>
				<el-button
					@click="deleteMember"
					style="
						background-color: #fe5965;
						color: white;
					"
					size="small">
          {{$t('common.delete')}}
				</el-button>
			</div>
		</delete-dialog>
	</div>
</template>

<script>
import OpenData from "@/components/openData";
import chooseDept from './chooseDept.vue';
import DeleteDialog from "../../../../components/deleteDialogue.vue";
import { isTB } from "../utils/customerMainId"

export default {
    data(){
        return {
			headerTitle:"",
			ruleForm:{
				name:"",
				mobile:'',
				email:'',
				organizationId:null,
				birthday:'',
				staffIn:'',
				botAuth:false,
				workOrderAuth:false,
				bigModel:false,
				authFollowDepart:true,
				tags:[]
			},
			// botAuthDisabled:true,
			// bigModelDisabled:true,
			// workOrderAuthDisabled:true,
			editDisabled:false,
			props:{
				value:"id",
                label:"label",
                leaf:"leaf",
                children:'children',
                checkStrictly: true 
			},
			rolePrivilegesList:[],
			showPopup:false,
			treeDataCopy:[],
			checkedDeptPopup:[],
			dleteMemberDialogVisible:false,
			isTB:false,
			VUE_APP_SOURCE: "askbot"
        }
    },
	components:{OpenData,chooseDept,DeleteDialog},
	props:{
		visible:{
			type:Boolean,
			default:false,
		},
		memberData:{
			type:Object,
            default(){
                return {
					departments:[{
						name:1
					}]
				}
            }
		},
		treeData:{
            type:Array,
            default(){
                return []
            }
        },
		// 立时特殊处理
		corpType:{
			type:String,
			default:'0'
		},
		corpId:{
			type:String,
			default:''
		},
		activeDeptId:{
			type:Number,
			default:null
		},
		tagsList:{
			type: Array,
			default() {
				return []
			}
		}
	},
	watch:{
		visible(n){
			this.checkedDeptPopup = [];
			this.rolePrivilegesList = [];
			let list = JSON.parse(localStorage.getItem('rolePrivileges'));
			list.forEach(item=>{
				if(item.checked){
					this.rolePrivilegesList.push(item.id)
				}
			})
			this.treeDataCopy = this.handlerTree(JSON.parse(JSON.stringify(this.treeData)));
			if(this.memberData && this.memberData.id){
				this.headerTitle = this.$t('staffManage.editMember');
				this.ruleForm = {
					name:this.memberData.name,
					mobile:this.memberData.mobile,
					email:this.memberData.email,
					organizationId:this.memberData.departmentIds[0],
					birthday:this.memberData.birthday,
					staffIn:this.memberData.staffIn,
					botAuth:this.memberData.botAuth,
					workOrderAuth:this.memberData.workOrderAuth,
					bigModel:this.memberData.bigModel,
					authFollowDepart:this.memberData.authFollowDepart,
					tags:this.memberData.tags
				}
				if(this.memberData.authFollowDepart){
					this.ruleForm.botAuth = false;
					this.ruleForm.workOrderAuth = false;
					this.ruleForm.bigModel = false;
				}
				this.memberData.departments.forEach(item => {
					item.label = item.name;
				})
				this.treeDataCopy = this.judgeChildrens(this.treeDataCopy,this.memberData.departmentIds);
				this.checkedDeptPopup = [];
				this.memberData.departments.forEach(item => {
					item.label = item.name;
					this.checkedDeptPopup.push(item);
				})
			} else {
				this.headerTitle = this.$t('staffManage.addMembers');
				this.ruleForm = {
					name:"",
					mobile:'',
					email:'',
					organizationId:this.activeDeptId,
					birthday:'',
					staffIn:'',
					botAuth:false,
					workOrderAuth:false,
					bigModel:false,
					authFollowDepart:true,
					tags:[]
				}
				this.treeDataCopy = this.judgeChildrens(this.treeDataCopy,[this.activeDeptId]);
				this.getActiveDeptName(this.treeDataCopy);
			}
		},
		checkedDeptPopup:{
			handler(n){
				console.log(n,'mm');
			},
			immediate:true,
			deep:true
		}
	},
	methods:{
		// 找出树结构中选中的数据
		judgeChildrens(data,checkedList) {
			data.forEach(item => {
				if(checkedList.indexOf(item.id) !== -1){
					this.checkedDeptPopup.push(item);
					item.checked = true;
				}
				if (item.children && item.children.length > 0) {
					this.judgeChildrens(item.children || [],checkedList);
				}
			});
			return data
		},
		// 编辑时,获取部门
		getActiveDeptName(treeData){
			treeData.forEach((item,index) => {
				if(this.activeDeptId == item.id){
					this.checkedDeptPopup = [item];
				}
                if(item.children && item.children.length > 0){
                    this.getActiveDeptName(item.children)
                } 
            })
            return treeData
		},
		handlerTree(treeData){
            treeData.forEach((item,index) => {
				item.checked = false;
                if((this.addDeptType === 'del'  || this.addDeptType === 'edit' )&& this.activeTreeItem.id == item.id){
                    item.isShow = false;
                    treeData.splice(index, 1);
                } else {
                    item.isShow = true;
                }
                if(item.children && item.children.length > 0){
                    item.leaf = false;
                    this.handlerTree(item.children)
                } else {
                    delete item.children;
                    item.leaf = true;
                }
            })
            return treeData
        },
		// 跟随部门true:工单/机器人 禁用并且清除选中
		authFollowDepartChange(){
			if(this.ruleForm.authFollowDepart){
				this.ruleForm.botAuth = false;
				this.ruleForm.bigModel = false;
				this.ruleForm.workOrderAuth = false;
				// this.botAuthDisabled = true;
				// this.bigModelDisabled = true;
				// this.workOrderAuthDisabled = true;
			} else {
				// this.botAuthDisabled = false;
				// this.bigModelDisabled = false;
				// this.workOrderAuthDisabled = false;
			}
		},
		closeDrawer(){
			this.$emit('update:visible',false)
		},
		// 打开组织架构tree弹框
		openMemberTree(){
			if(this.editDisabled) return false;
			this.showPopup = true;
		},
		// 选择部门后
		checkedDept(list){
			this.checkedDeptPopup = [...[],...list];
			this.ruleForm.organizationId = this.checkedDeptPopup[0].id;
			this.showPopup = false;
			this.setCheckedTreeData(this.treeDataCopy,list[0].id)
		},
		setCheckedTreeData(treeData,checkedId){
			treeData.forEach((item,index) => {
				this.$set(item,'checked',item.id == checkedId ? true : false)
				if(item.children && item.children.length > 0){
					this.setCheckedTreeData(item.children,checkedId)
				}
			})
		},
		submitForm(){
			console.log(this.ruleForm,'ruleForm');
            if (this.ruleForm.name == "") {
                this.$message.error(this.$t('addMember.nameRequired'));
                return;
            }
            
			if (this.main_isLishi) {
				if(this.ruleForm.email === ''){ // 立时特殊处理
					this.$message.error(this.$t('addMember.emailRequired'))
					return false;
				}
			} else {
				if(this.ruleForm.mobile === '' && (this.memberData.source == -1 || this.memberData.source == 21 || !this.editDisabled)){
					this.$message.error(this.$t('addMember.mobileRequired'))
					return false;
				}
			}
			

			
			//  else if (!/^1[3-9][0-9]{9}$/.test(this.ruleForm.mobile)){
			// 	this.$message.error('请输入正确的11位手机号')
			// 	return false;
			// } 
			if (this.ruleForm.organizationId == undefined || this.ruleForm.organizationId.length == 0) {
                this.$message.error(this.$t('addMember.organizationIdRequired'));
                return;
            }

			if (this.main_isLishi) {
				if(this.ruleForm.email && this.ruleForm.email !== '' && !/^[a-zA-Z0-9._%+-]+@(nipsea\.com\.sg|163\.com|guoranbot\.com)$/.test(this.ruleForm.email)){
					this.$message.error(this.$t('addMember.checkEmail'));
					return false;
				}
			} else {
				if(this.ruleForm.email && this.ruleForm.email !== '' && !/^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/.test(this.ruleForm.email)){
					this.$message.error(this.$t('addMember.checkEmail'));
					return false;
				}
			}
			
              
			let birthDay = "";
			if (this.ruleForm.birthday) {
				birthDay = new Date(this.ruleForm.birthday).Format("yyyy-MM-dd");
			}
			let staffIn = "";
			if (this.ruleForm.staffIn) {
				staffIn = new Date(this.ruleForm.staffIn).Format("yyyy-MM-dd");
			}
			let param = {
				email: this.ruleForm.email,
				mobile: this.ruleForm.mobile,
				name: this.ruleForm.name,
				birthday: birthDay,
				staffIn: staffIn,
				"botAuth": this.ruleForm.botAuth, // 使用机器人能力
				"workOrderAuth": this.ruleForm.workOrderAuth, // 使用工单能力
				"bigModel": this.ruleForm.bigModel, // 使用大模型能力
				"authFollowDepart": this.ruleForm.authFollowDepart, // 跟随父级部门
				corpId:this.corpId,
				tags:this.ruleForm.tags
			};
			if(Array.isArray(this.ruleForm.organizationId)){
				param.departmentIds = [this.ruleForm.organizationId[this.ruleForm.organizationId.length-1]]
			} else {
				param.departmentIds = [this.ruleForm.organizationId]
			}
			console.log("param", param);
			if (this.memberData && this.memberData.id) {
				param.id = this.ruleForm.id;
				this.$http.put(this.requestUrl.member.editMemberById + "/" + this.memberData.id,param)
					.then((res) => {
						if (res.data && res.data.code === "0") {
							this.$emit('addMemberSuccess')
						} else {
							this.$message.error(res.data.message);
						}
					});
				
			} else {
				this.$http.post(this.requestUrl.member.addMember, Object.assign(param,{
					activateStatus:0,
					
				})).then((res) => {
				console.log(res,'res');
					if (res.data && res.data.code === "0") {
						this.$emit('addMemberSuccess')
					} else {
						this.$message.error(res.data.message);
					}
				});
			}
		},
		// 删除成员 只有自建企业可删除
		deleteMember(){
			this.$http.delete(this.requestUrl.member.deleteMemberById+'/'+this.memberData.id)
				.then(res=>{
					if (res.data.code === '0'){
						this.dleteMemberDialogVisible = false;
						this.$emit('addMemberSuccess');
						this.$message({
							message:this.$t('addMember.deleteMemberSuccess'),
							type:'success',
							duration:1000
						})
					}
				})
		}
	},
	mounted() {
		this.isTB = isTB()
		if (this.main_isLishi) {
			this.corpType = 21;
			this.VUE_APP_SOURCE = 'lishi';
		}
	}
}
</script>

<style lang="less" scoped>
.tag-hidden-close {
	/deep/.el-tag__close{
		color: red !important;
		display: none;
	}
}
.add-member {
	.el-form-item{
		display: flex;
		flex-direction: column;
	}
	::v-deep .el-drawer__wrapper{
		z-index: 2001;
	}
	.add-member-title {
		height: 45px;
		background: #f6f8fd;
		padding: 0 20px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		h4 {
			font-size: 16px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #000000;
		}
		i {
			cursor: pointer;
		}
	}
	.add-member-content{
		padding: 20px;
		height: calc(100vh - 160px);
		overflow-y: scroll;
		overflow-x: hidden;
		font-family: inherit;
		::v-deep .el-form-item__label{
			line-height: 20px;
		}
		::v-deep .el-form--label-top .el-form-item__label{
			float: left;
			&.after{
				clear: both;
			}
		}
		::v-deep .el-input{
			width: 320px;
			
		}
		.red {
			color: red;
			margin-left: 6px;
		}
		.staff-input{
			// height: 36px;
			width:320px;
			border-radius: 5px;
			border:1px solid #E0E6F7;
			cursor: pointer;
			display: flex;
			align-items: center;
			&.staff-input-disabled{
				background-color: #f5f7fa;
				border-color: #e4e7ed;
				color: #c0c4cc;
				cursor: not-allowed;
			}
			.placeholder-text{
				padding-left: 15px;
				color: #A9B3C6;
			}
			.client-value{
				padding: 5px;
				color: #606266;
				display: flex;
				// justify-content: space-around;
				flex-wrap: wrap;
				align-items: center;
				line-height: 30px;
				width: 100%;
				.item{
					border:1px solid hsl(240, 5%, 92%);
					height: 24px;
					line-height: 24px;
					padding: 0 8px;
					line-height: 22px;
					position: relative;
					background-color: #f4f4f5;
					color: #909399;
					border-radius: 3px;
					font-size: 12px;
					// margin: 2px 0 2px 6px;
					.el-icon-close{
						display: inline-block;
						border-radius: 50%;
						text-align: center;
						position: relative;
						cursor: pointer;
						font-size: 12px;
						height: 14px;
						width: 14px;
						line-height: 14px;
						background-color: #c0c4cc;
						color: #909399;
						cursor: pointer;
					}
				}
				

			}
		}
		  .bot-num{
            margin-bottom: 30px;
        }
        .bot-num,.choose-num{
            height: 21px;
            display: flex;
            // justify-content: space-around;
            align-items: center;
            color: #616161;
            .icon{
                width: 21px;
                height: 21px;
                background: #09DDD5;
                border-radius: 50%;
                text-align: center;
                line-height: 21px;
                color: #fff;
                
                &.choose{
                    background-color: #87A6FF;
                }
            }
            .type{
                margin: 0 15px 0 10px;
                width: 122px;
                text-align: left;
                color: #A9B3C6;
            }
            .go-beyond{
                // width: 92px;
				padding: 0 6px;
                height: 20px;
                font-size: 12px;
                background: #FFEBEC;
                border-radius: 3px;
                color: #FE5965;
                text-align: center;
                line-height: 20px;
                margin-left: 5px;
            }

        }
		.user-name{
			border: 1px solid #E4E7ED;
			border-radius: 4px;
			color: #C0C4CC;
			height: 40px;
			padding: 0 15px;
			background-color: #F5F7FA;
			cursor: not-allowed;
		}
		
	}
	.footer-btns{
		display: flex;
		justify-content: flex-end;
		// padding: 0 30px;
		align-items: center;
		width: 100%;
		position: absolute;
		bottom: 0;
		padding:20px;
		box-shadow: 2px -2px 2px #eef2f6;
		&.edit{
			// justify-content: space-between;
		}
		.el-button--default{
			border: 2px solid #E0E6F7;
			color: #000000;
			&:hover{
				background-color: #fff;
				color:#000000;
				border-color:#E0E6F7 ;
			}
		}
		.el-button{
			height: 35px;
			padding: 0px 26px;
			font-weight: normal;
			line-height: 31px;
		}
		.el-button--primary{
			background-color: #366AFF;
			border-color: #366AFF;
			
		}
		.el-button--small{
			width: 34px;
			padding: 0;
			color: #616161;
			border: 1px solid #E0E6F7;
			height: 34px;
		}
		.del-btn{
			margin-right: 100px;
		}
	}
}
</style>>
