<template>
    <div class="app_basic_info">
        <div class="app_basic_info_content">
            <p>APP名称</p>
            <el-input v-model="appName" placeholder="请输入" ></el-input>
            <div>
                下一步
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            appName: "",

        }
    }
}
</script>

<style lang="less" scoped>
.app_basic_info {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .app_basic_info_content {
        width: 800px;
        height: 400px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
</style>