<template>
  <div class="basicInfo">
    <el-form ref="”bindForm“" :rules="bindRules" :model="bindForm">
      <el-form-item
          :label="$t('botLink.bindEnterpriseName')"
          prop="corpName"
      >
        <el-input
            v-model="bindForm.corpName"
            :placeholder="$t('botLink.enterpriseNamePla')"
            :autofocus="true"
        ></el-input>
      </el-form-item>
      <el-form-item
          prop="corpId"
          :label="$t('botLink.corpIdTip')"
      >
        <el-input
            v-model="bindForm.corpId"
            :placeholder="$t('botLink.enterpriseIDPla')"
        ></el-input>
      </el-form-item>
      <el-form-item
          prop="agentId"
          :label="$t('botLink.agentIdLabel')"
      >
        <el-input
            v-model="bindForm.agentId"
            :placeholder="$t('botLink.agentIdPla')"
        ></el-input>
        <span
            v-if="warningVisible"
            style="
                            color: #f56c6c;
                            display: block;
                            text-align: left;
                            font-size: 12px;
                            line-height: 16px;
                        "
            class="warning-text"
        >*{{$t('botLink.agentIdTip')}}</span
        >
      </el-form-item>
      <el-form-item
          prop="secret"
      >
        <el-input
            v-model="bindForm.secret"
            :placeholder="$t('botLink.secretPla')"
        ></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { setI18nFormInfo } from "@/utils/common";
export default {
  name: "basicInfo",
  data(){
    return{
      bindForm: {
        agentId: "",
        bindType: "",
        corpId: "",
        corpName: "",
        secret: "",
      },
      bindRules: {
        corpName: [
          {
            required: true,
            message: "",
            trigger: "blur",
          },
          { max: 64, message: "", trigger: "blur" },
        ],
        corpId: [
          {
            required: true,
            message: "",
            trigger: "blur",
          },
          { max: 36, message: "", trigger: "blur" },
        ],
        agentId: [
          {
            required: true,
            message: "",
            trigger: "blur",
          },
          { max: 64, message: "", trigger: "blur" },
        ],
        secret: [
          {
            required: true,
            message: "",
            trigger: "blur",
          },
          { max: 64, message: "", trigger: "blur" },
        ],
      },
      warningVisible:false
    }
  },
  created() {
    setI18nFormInfo(this.bindRules, this)
  }
};
</script>

<style scoped>

</style>