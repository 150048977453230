<template>
    <div id="inviteEnterEnterprise" v-cloak>
        <div class="gr-content-container">
            <div class="staffManageHeader">
                <top-header>
                    <div slot="setup-header-left" class="setup-header-left">
                        <div class="go-back iconfont guoran-tongyichicun-18-22-fanhui" @click="goBack"></div>
                        <div class="line"></div>
                        <span class="setup-header-left-icon">
                            <i class="iconfont guoran-tongyichicun-piliangyaoqing"></i>
                        </span>
                        <span class="text">{{$t('inviteEnter.title')}}</span>
                    </div>
                    <div class="setup-header-right-l" slot="setup-header-right-l">
                        <div class="sd-main-list">
                            <el-select
                                v-model="mainValue"
                                size="small"
                                @change="changeMainValue"
                                :placeholder="$t('common.selectPlaceholder')">
                                <el-option
                                    v-for="item in mainOptions"
                                    :key="item.corpId"
                                    :label="item.corpName"
                                    :value="item.corpId">
                                    <img
                                        height="12px"
                                        v-if="item.corpType == 0"
                                        src="https://static.guoranbot.com/cdn-arsenal/dev/img/qiyechat.b7289a96.png"
                                        alt=""
                                        srcset=""/>
                                    <img
                                        height="12px"
                                        v-if="item.corpType == 3"
                                        src="https://static.guoranbot.com/cdn-arsenal/dev/img/dingding.874450b3.jpeg"
                                        alt=""
                                        srcset=""/>
                                    <span
                                        style="
                                            display: inline-block;
                                            margin-left: 6px;
                                            color: #8492a6;
                                            font-size: 13px;
                                        ">{{ item.corpName }}</span>
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </top-header>
            </div>
            <div class="inviteEnterEnterprise-content">
                <!-- <div class="inviteEnterEnterprise-content-section"> -->
                    <div class="top-content">
                        <div class="invite-enter-enterprise">
                            <div class="left">
                                <i class="iconfont guoran-tongyichicun-piliangyaoqing"></i>
                                <span class="invite-enter-enterprise-text">{{$t('inviteEnter.title')}}</span>
                                <div class="dept-box">
                                    <template v-if="memberInfo4Client.memberId">
                                        <open-data v-if="isWechat" :openid="memberInfo4Client.fullName" :type="'departmentName'"></open-data>
                                        <span v-else>{{memberInfo4Client.fullName}}</span>
                                    </template>
                                    <template v-else>
                                        <span style="color: #A9B3C6;">{{$t('common.selectPlaceholder')}}</span>
                                    </template>
                                    <i class="iconfont guoran-a-16-15" @click="openStaffPopup"></i>
                                </div>
                                <i class="iconfont guoran-guanzhuren"></i>
                                <span class="tips">
                                    <span>{{$t('inviteEnter.tip1')}}</span>
                                    <span v-if="corpType != 21">
                                        ，{{$t('inviteEnter.tip2')}}{{this.getCorpTypeName()}}{{$t('inviteEnter.tip3')}}{{this.getCorpTypeName()}}{{$t('inviteEnter.tip4')}}
                                    </span>
                                </span>
                            </div>
                            <div class="right-operation" v-if="deactivate">
                                <el-badge :is-dot="applyListNew" class="item">
                                    <el-button round icon="iconfont guoran-tongyichicun-18-09-zhihangrizhiliebiao" @click="openApplyList">{{$t('inviteEnter.applicationList')}}</el-button>
                                </el-badge>
                                <el-button round icon="iconfont guoran-tongyichicun-16-14-shezhiliebiao" @click="openInviteSetting"  v-if="false">邀请设置</el-button>
                            </div>
                        </div>
                        <div class="invite-mode" v-if="deactivate">
                            <div class="left-text">{{$t('inviteEnter.tip5')}}{{inviteTime !== -1 ? $t('inviteEnter.validityUntil') : ''}}{{expirationDate}}</div>
                            <div class="right-btn">
                                <el-button round icon="iconfont guoran-shuaxin" @click="refreshInvite">{{$t('inviteEnter.refresh')}}</el-button>
                                <el-button round icon="iconfont guoran-tingyong" @click="reEnable('stop')">{{$t('inviteEnter.deactivate')}}</el-button>
                            </div>
                        </div>
                    </div>
                    <template v-if="deactivate">
                        <div class="invitation-code-and-link">
                            <div class="code-box">
                                <div class="title">{{$t('inviteEnter.inviteCode')}}</div>
                                <div class="tips">{{$t('inviteEnter.inviteCodeTip')}}</div>
                                <div class="code">{{inviteCode}}</div>
                                <el-button 
                                    round 
                                    class="copy-btn"
                                    v-clipboard:copy="inviteCodeCopy"
                                    v-clipboard:success="onCopy"
                                    v-clipboard:error="onError">
                                  {{$t('inviteEnter.copyCode')}}
                            </el-button>
                            </div>
                            <div class="link-box">
                                <div class="title">{{$t('inviteEnter.link')}}</div>
                                <div class="tips">{{$t('inviteEnter.linkTip')}}</div>
                                <div class="link">
                                  {{$t('inviteEnter.linkTip2')}}“
                                    <!-- {{memberInfo4Client.fullName}}” -->
                                    <open-data v-if="isWechat" :openid="memberInfo4Client.fullName" :type="'departmentName'"></open-data>
                                    <span v-else>{{memberInfo4Client.fullName}}</span>
                                    ”{{$t('inviteEnter.linkTip3')}} {{ isE() ? 'EBot' : 'AskBot' }}  {{$t('inviteEnter.linkTip4')}}{{inviteShortUrl}}
                                </div>
                                <el-button 
                                    round 
                                    class="copy-btn"
                                    v-clipboard:copy="codeCopyText"
                                    v-clipboard:success="onCopy"
                                    v-clipboard:error="onError">
                                  {{$t('inviteEnter.copyLink')}}
                                </el-button>
                            </div>
                        </div>
                        <div class="split-line"></div>
                        <div class="invite-setting">
                            <div class="invite-setting-title">
                                <i class="iconfont guoran-tongyichicun-16-14-shezhiliebiao"></i>
                                <span>{{$t('inviteEnter.inviteSet')}}</span>
                            </div>
                            <div class="invite-time">
                                <span>{{$t('inviteEnter.inviteTime')}}</span>
                                <el-select v-model="inviteTime" :placeholder="$t('common.selectPlaceholder')" @change="updateSetting" >
                                    <el-option
                                        v-for="item in options"
                                        :key="item.value"
                                        :label="$t('inviteEnter.' + item.value)"
                                        :value="item.value">
                                        </el-option>
                                </el-select>
                            </div>
                            <div class="approval">
                                <div class="switch-and-icon">
                                    <el-switch
                                        @click.native.prevent="changeValue('switch')"
                                        :width="42"
                                        v-model="needApprove"
                                        active-color="#366AFF"
                                        inactive-color="#E2E2E2">
                                    >
                                    </el-switch>
                                    <span
                                        class="switch-open-icon"
                                        @click="changeValue('open')"
                                        v-if="needApprove">
                                        <i class="iconfont guoran-a-16-17"></i>
                                    </span>
                                    <span
                                        class="switch-close-icon"
                                        @click="changeValue('close')"
                                        v-if="!needApprove">
                                        <i class="arsenal_icon arsenalcuo1"></i>
                                    </span>
                                </div>
                                <span>{{$t('inviteEnter.approvalRequired')}}</span>
                            </div>
                            <div class="approval-role">
                                <span>{{$t('inviteEnter.approvalRole')}}</span>
                                <el-select v-model="approvalRoleValue" @change="updateSetting" multiple :placeholder="$t('common.selectPlaceholder')">
                                    <el-option
                                    v-for="item in approvalRoleOptions"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                    </template>
                    <div class="stop-box" v-else>
                        <img src="../../../assets/images/stop.png" alt="">
                        <span class="text">{{$t('inviteEnter.deactivateTip')}}</span>
                        <el-button type="primary" round @click="reEnable('use')">{{$t('inviteEnter.reEnable')}}</el-button>
                    </div>
                <!-- </div> -->
            </div>
        </div>
        <SideNav ref="leftMenuObj"></SideNav>
        <inviteSetting :inviteSettingPopup.sync="inviteSettingPopup"></inviteSetting>
        <applyList :applyListDrawer.sync="applyListDrawer" :isWechat="isWechat" :presentUserCanApprove="PresentUserCanApprove" :departmentId="this.memberInfo4Client.memberId"></applyList>
        <popup @closeEvent="dialogMemberTree = false" v-if="dialogMemberTree">
            <div slot="popup-name" class="popup-name">选择部门</div>
            <div slot="popup-con" class="member-contanier">
                <staff-customer
                    :isCheckDept="true"
                    chooseType="dept"
                    :isShowUpDown="false"
                    :isMultiple="false"
                    @onNodeClick="onNodeClick"
                    :clientType="'ASKBOT_MEMBER'"
                    :memberInfo4Client="memberInfo4Client"
                    ref="staffCustomer"
                ></staff-customer>
            </div>
            <div slot="dialog-footer">
                <el-button @click="closeStaff" plain size="small">{{$t('common.cancel')}}</el-button>
                <el-button type="primary" @click="preserStaff" size="small">{{$t('common.confirm')}}</el-button>
            </div>
      </popup>
    </div>
</template>
<script>
import Popup from "@/components/popup";
import SideNav from "./../../menu/SideNav.vue";
import TopHeader from "./../../../components/top-header.vue";
import switchAndIcon from './../../../components/switchAndIcon.vue'
import inviteSetting from './components/inviteSetting.vue';
import applyList from './components/apply-list.vue';
import StaffCustomer from "@/components/staffCustomer";
import OpenData from "@/components/openData";
import {redirectUri} from "../../../const/redirectUri";
export default {
    name: "staffManage",
    components: {
        Popup,
        SideNav,
        TopHeader,
        switchAndIcon,
        inviteSetting,
        applyList,
        StaffCustomer,
        OpenData
    },
    data(){
        return{
            mainOptions: [], // 主体列表
            mainValue: "", // 当前主体id
            expirationDate:"2023/01/13 00:00",
            inviteTime:2,
            options:[{
                label: '3天后过期',
                value: 3
            },{
                label: '7天后过期',
                value: 7
            },{
                label: '30天后过期',
                value: 30
            },{
                label: '永久有效',
                value: -1
            }],
            needApprove:true,
            approvalRoleValue:[],
            approvalRoleValueOld:[],
            approvalRoleOptions:[],
            inviteSettingPopup:false,
            applyListDrawer:false,
            dialogMemberTree:false,
            isCheckData:null,
            memberInfo4Client:{
                type:'',
                bindType:'',
                memberId:'',
                name:'',
                fullName:''
            },
            departmentId:null,
            departmentName:'',
            isWechat:false,
            corpId:null,
            id:null,
            deactivate:true, // true 正常  false 停用,
            inviteCode:'',
            inviteShortUrl:'',
            inviteCodeCopy:'',
            PresentUserCanApprove:true,
            applyListNew:false,
            codeCopyText:""
        }
    },
    props:{
        isInviteEnterEnterprise:{
            type:Boolean,
            default:false
        }
    },
    created(){
        console.log(this.$route.query,'query');
        this.corpId = this.$route.query.corpId;
        this.departmentId =  this.$route.query.departmentId;
        this.departmentName = this.$route.query.departmentName;
        // this.memberInfo4Client.fullName = this.$route.query.departmentName;
        this.memberInfo4Client = {
            type:'',
            bindType:'',
            memberId:this.$route.query.departmentId,
            name:this.$route.query.departmentName,
            fullName:this.$route.query.departmentName
        }
        this.isWechat = !this.$route.query.isWechat || this.$route.query.isWechat === 'false' ? false : true;
        this.getInviteSet();
    },
    mounted(){
        this.getCorps4main();
       
    },
    methods:{
        getCorpTypeName(){
            // 0 企微 3 钉钉 10 飞书 13云之家
            let text = ''
            switch (this.corpType) {
                case '0':
                    text = '企业微信';
                    break;
                case '3':
                    text = '钉钉';
                    break;
                case '10':
                    text = '飞书';
                    break;
            }
            return text;
        },
        // 获取设置
        getInviteSet(){
            this.$http.get(this.requestUrl.invite.getInviteSet + '?corpId=' + this.corpId + '&departmentId=' + this.departmentId)
                .then((res) => {
                    if (res.data && res.data.code === "0") {
                        let data = res.data.data
                        this.id = data.id;
                        this.approvalRoleOptions = data.approveRole || [];
                        this.deactivate =  data.deactivate;
                        this.inviteTime = data.inviteValidityTime;
                        this.needApprove = data.needApprove;
                        this.approvalRoleValueOld = this.approvalRoleValue = data.approveRoleIds;
                        this.applyListNew = data.applyListNew;
                        let url = redirectUri.wisdom + '/lightning-web';
                        // if(this.isWechat){
                        //     this.inviteCodeCopy = '可通过此链接' + data.inviteShortUrl + '或 8 位企业邀请码' +  data.inviteCode + '加入企业。下载AskBot客户端使用企业邀请码：' + '【https://www.baidu.com/'
                        // } else {
                            this.inviteCodeCopy = '邀请你加入“' + this.memberInfo4Client.fullName +'”，可通过此链接' + data.inviteShortUrl + '或 8 位企业邀请码' + 
                                                    data.inviteCode + '加入企业。请跳转至AskBot知识管理使用企业邀请码：' + url + '/index.html#/login'
                        // }
                        let text = this.isE() ? 'EBot' : 'AskBot'
                        this.codeCopyText = this.$t('inviteEnter.linkTip2') + '“'+ this.memberInfo4Client.fullName+ this.$t('inviteEnter.linkTip3') + text + this.$t('inviteEnter.linkTip4') + data.inviteShortUrl
                        this.inviteCode = data.inviteCode;
                        this.inviteShortUrl = data.inviteShortUrl;
                        if(data.inviteValidityTime !== -1){
                            this.expirationDate = this.getDataTime(data.deadlineTime);
                        } else {
                            this.expirationDate = this.$t('inviteEnter.permanentValidity')
                        }
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
        },
        // 时间戳转换成时间
		getDataTime(times){
			if (!times) {
				return '-'
			}
			let getBYT = (num) => {
				if(num == 0) {
					return '00'
				} else if(num < 10) {
					return '0' + num;
				} else {
					return num;
				}
			}
			let date = new Date(times); // 13位时间戳
			let Y = date.getFullYear() + '/';
			let M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '/';
			let D = getBYT(date.getDate() )+ ' ';
            let H = getBYT(date.getHours() )+ ':';
            let M2 = getBYT(date.getMinutes() );
			return Y+M+D+H+M2;
		},
        goBack(){
            this.$router.go(-1)
        },
        // 获取主体下的公司
        getCorps4main() {
            this.$http.get(this.requestUrl.accountManage.corps4main)
                .then((res) => {
                    if (res.data && res.data.code === "0") {
                        this.mainOptions = res.data.data;
                        if (this.mainOptions.length != 0) {
                            this.mainValue = this.$route.query.corpId
                            // this.mainValue = this.mainOptions[0].corpId;
                            this.corpType = this.mainOptions[0].corpType;
                            sessionStorage.setItem('corpId',this.mainValue)
                        }
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
        },
        changeMainValue(val) {
            this.corpId = this.mainValue = val;
            sessionStorage.setItem('corpId',this.mainValue)
            this.mainOptions.forEach((item) => {
                if (item.corpId == val) {
                    this.corpType = item.corpType;
                    this.isWechat = item.corpType == 0 ? true : false;
                    let userId = localStorage.getItem("_uid");
                    let corpId = sessionStorage.getItem('corpId')
                    let url =  "/api/department/corpLazy" + "?userId=" + userId + '&corpId=' + corpId;
                    this.$http.get(url).then(res => {
                        let data = res.data[0]
                        this.memberInfo4Client = {
                            type:'',
                            bindType:'',
                            memberId:data.id,
                            name:data.label,
                            fullName:data.label
                        }
                        this.departmentId =  data.id;
                       
                        this.getInviteSet()
                         console.log(this.memberInfo4Client,this.departmentId  );
                    })
                }
            });
            
            
        },
          // Copy成功
        onCopy(e) {
            this.$message.success(this.$t('common.copySuccess'));
        },
        // Copy失败
        onError(e) {
            this.$message.error(this.$t('common.copyFail'));
        },
        changeInviteTime(){

        },
        // 改变
        changeValue(datas){
            if(datas === 'open'){
               this.needApprove = false;
            } else if (datas === 'close'){
                this.needApprove = true;
            } 
            this.updateSetting()
        },
        // 打开申请列表抽屉
        openApplyList(){
            if(this.applyListNew){
                this.$http.get(this.requestUrl.invite.userReadTheUpdate+"?departmentId="+this.departmentId)
                .then((res) => {
                    if (res.data && res.data.code === "0") {
                        this.applyListNew = false;
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
            }
            this.applyListDrawer = true
        },
        // 打开邀请设置弹框
        openInviteSetting(){
            this.inviteSettingPopup = true;
        },
        openStaffPopup(){
            this.dialogMemberTree = true;
        },
        onNodeClick(data,type,memberInfo,row){
            console.log('onNodeClick',data,type,memberInfo,row);
            this.isCheckData = data;
        },
        closeStaff(){
            this.isCheckData = null;
            this.dialogMemberTree = false;
        },
        preserStaff(){
            if (this.isCheckData){
                this.memberInfo4Client.name = this.isCheckData.label;
                this.memberInfo4Client.memberId = this.isCheckData.id;
                this.isCheckData.type === 1 && this.isNumber(this.isCheckData.label);
                this.memberInfo4Client.bindType = this.isCheckData.originData ? this.isCheckData.originData.source:'';
                let isWeWorkThirdData = (this.isCheckData.type === 0 && this.isCheckData.originData && this.isCheckData.originData.source === 0) || (this.isCheckData.isWeWorkThirdData) ? true : false;
                if (!isWeWorkThirdData){
                    this.memberInfo4Client.fullName = this.isCheckData.label;
                }
                else {
                    this.memberInfo4Client.fullName = ""
                }
                this.departmentId = this.isCheckData.id;
                this.getInviteSet();
            }
            this.dialogMemberTree = false;
        },
        isNumber(value) {
            const res = /^\d+$/;
            return res.test(value);
        },
        openInviteSetting(){
            this.inviteSettingPopup = true;
        },
        // 刷新邀请码/邀请链接
        refreshInvite(){
            this.$http.get(this.requestUrl.invite.refreshInvite+"?corpId="+this.corpId+"&departmentId="+this.departmentId+"&inviteValidityTime="+this.inviteTime)
                .then((res) => {
                    if (res.data && res.data.code === "0") {
                        this.inviteCode = res.data.data.inviteCode;
                        this.inviteShortUrl = res.data.data.inviteShortUrl;
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
        },
        // 重新启用
        reEnable(type){
            let params = {
                id:this.id,
                deactivate:type === 'stop' ? false : true, // // true 正常  false 停用,
            }
            this.$http.put(`${this.requestUrl.invite.updateInviteSet}`, params)
                .then((res) => {
                    if (res.data.code === "0") {
                        this.getInviteSet();
                        this.$message.closeAll()
                        this.$message.success(this.$t('inviteEnter.updateSuccess'));
                    }
                });
        },
        // 更改设置
        updateSetting(type){
            let flag = true;
            if(this.needApprove && (!this.approvalRoleValue || this.approvalRoleValue.length === 0)){
                flag = false;
                this.needApprove = false;
                this.$message.warning(this.$t('inviteEnter.approvalMsg'));
                return false;
            }
            if(!flag) return;
            let params = {
                id:this.id,
                inviteValidityTime:this.inviteTime,
                needApprove:this.needApprove,
                approveRoleIds:this.approvalRoleValue,
                deactivate:this.deactivate,
            }
            this.$http.put(`${this.requestUrl.invite.updateInviteSet}`, params)
                .then((res) => {
                    if (res.data.code === "0") {
                        this.approvalRoleValueOld = this.approvalRoleValue;
                        this.getInviteSet()
                        this.$message.closeAll();
                        this.$message.success(this.$t('inviteEnter.updateSuccess'));
                    }
                });
           
        }
    }
};
</script>
<style lang="less">
[v-cloak]{
    display: none !important;
}
@import "./../../../assets/less/inviteEnterEnterprise/inviteEnterEnterprise.less";
</style>