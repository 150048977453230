<template>
    <div class="add-dept">
        <popup @closeEvent="closeEvent" v-if="addDeptVisible">
            <div slot="popup-name" class="popup-name">{{headerName}}</div>
                <div slot="popup-tip">{{$t('addDept.addDeptTitle')}}</div>
            <div slot="popup-con">
            <el-form :model="ruleForm" ref="ruleForms" label-position="top" label-width="80px" class="demo-ruleForm">
                    <el-form-item label="名称" prop="name">
                        <span slot="label">{{$t('addDept.name')}}<span class="red">*</span></span>
                        <el-input v-model="ruleForm.name" :placeholder="$t('addDept.namePlaceholder')" ></el-input>
                    </el-form-item>
                    <el-form-item class="batch-edit" label="上级部门" v-if="chooseUpDept">
                         <span slot="label">{{$t('addDept.superiorDepartment')}}<span class="red">*</span></span>
                        <div class="staff-input" @click="openMemberTree">
                            <span class="placeholder-text" v-if="checkedDeptPopup.length === 0">{{$t('common.selectPlaceholder')}}</span>
                            <div v-else class="client-value">
                                <div v-for="item in checkedDeptPopup" :key="item.id" class="item">
                                    {{item.name}}
                                </div>
                            </div>
                        </div>
					</el-form-item>
                    <el-form-item label="添加限制成员数" prop="num" class="limit-contnet" v-if="source !== 5">
                        <span slot="label">{{$t('addDept.restrictAddition')}}<span class="red">*</span></span>
                        <div>
                            <el-radio-group v-model="ruleForm.peopleLimitType">
                                <el-radio :label="1" :disabled="disabledNoLimit">{{$t('addDept.unlimited')}}</el-radio>
                                <el-radio :label="2" :disabled="disabledLimit">{{$t('addDept.superiorRestrictions')}}</el-radio>
                                <el-radio :label="0">{{$t('addDept.limited')}}</el-radio>
                            </el-radio-group>
                            <el-input 
                                v-model="ruleForm.num" 
                                :placeholder="$t('addDept.numberPlaceholder')"
                                :maxlength="7" 
                                :minlength="1"
                                v-if="ruleForm.peopleLimitType === 0">
                                <span slot="suffix">人</span>
                            </el-input>
                        </div>
                    </el-form-item>
                    <el-form-item :label="$t('addDept.setSystemPermissions')">
                        <el-checkbox v-model="ruleForm.botAuth" :label="$t('addDept.botPermission')" @change="botAuthChange"></el-checkbox>
                        <el-checkbox v-model="ruleForm.workOrderAuth" :label="$t('addDept.workOrderPermission')"></el-checkbox>
					</el-form-item>
                    <el-form-item >
                       <div class="bot-num">
                           <div class="icon">{{$t('addDept.capital')}}</div>
                           <div class="type">{{$t('addDept.numberOfConsultationBot')}}</div>
                           <span>{{totalUseBotPeopleNum === -1 ? $t('addDept.unrestricted') : totalUseBotPeopleNum + $t('addDept.person')}}</span>
                       </div>
                       <div class="choose-num">
                           <div class="icon choose">{{$t('addDept.choose')}}</div>
                           <div class="type">{{$t('addDept.selectedNumberOfPeople')}}</div>
                           <span>{{existUseBotPeopleNum}}{{$t('addDept.person')}}</span>
                           <template v-if="existUseBotPeopleNum > totalUseBotPeopleNum && totalUseBotPeopleNum !== -1">
                                <div class="go-beyond" v-if="promoteSurplusDay < 0">{{$t('addDept.exceeded')}}</div>
                           </template>
                       </div>
					</el-form-item>
            </el-form>
            </div>
            <div slot="dialog-footer">
                <el-button @click="closeEvent" plain round >{{$t('common.cancel')}}</el-button>
                <el-button type="primary" @click="submitForm('ruleForms')" round >{{$t('common.save')}}</el-button>
            </div>
        </popup>
        <chooseDept :showPopup.sync="showPopup" :treeData="treeDataCopy" @checkedDept="checkedDept" :isMultiple="false"></chooseDept>
    </div>
</template>

<script>
import Popup from '../../../../components/popup.vue';
import chooseDept from './chooseDept.vue';
export default {
    data(){
        return {
            headerName: "",
            ruleForm:{
                name:'',
                // isLimit:-2, //  0 限制   -1 不限制  -2 动态
                peopleLimitType:0, // 代表成员限制类型 0 限制 1 不限制 2 根据上级动态判断
                num:"",
                botAuth:true,
                workOrderAuth:false
            },
            disabledLimit:false,
            disabledNoLimit:false,
            treeDataCopy:[],
            checkedDeptPopup:[],
            showPopup:false,
            existUseBotPeopleNum:0,
            totalUseBotPeopleNum:0,
            promoteSurplusDay:-1
        }
    },
    components:{
        Popup,
        chooseDept
    },
    props:{
        chooseUpDept:{
           type:Boolean,
			default:false 
        },
		addDeptVisible:{
			type:Boolean,
			default:false
		},
        activeType:{
            type:String,
            default:''
        },
        type:{
            type:String,
            default:''
        },
        checkedTreeItem:{
            type:Object,
            default(){
                return{}
            }
        },
        checkedTreeItemNode:{
            type:Object,
            default(){
                return {}
            }
        },
        source:{
            type:Number,
            default:null
        },
        treeData:{
            type:Array,
            default(){
                return []
            }
        },
	},
    watch:{
        showPopup(n,o){
            if(n){
                this.promoteSurplusDay = sessionStorage.getItem('promoteSurplusDay') === '0' ? 0 :  Number(sessionStorage.getItem('promoteSurplusDay'));
                this.treeDataCopy = JSON.parse(JSON.stringify(this.treeData));
            }
        },
        addDeptVisible(n,o){
            if(n){
                this.checkedDeptPopup = [];
                this.treeDataCopy = JSON.parse(JSON.stringify(this.treeData));
                this.ruleForm = {
                    name:'',
                    peopleLimitType: 0,
                    num:"",
                    botAuth:true,
                    workOrderAuth:false
                }
                this.queryPeopleAuthNum();
            }
        },
        type(n,o){
            if(n === 'edit'){
                this.headerName = this.$t('addDept.editDept');
                this.disabledLimit = this.checkedTreeItem.parentId === -1 ? true : false;
               if(this.checkedTreeItemNode.level === 1){
                    this.disabledNoLimit = false;
                } else {
                    if(this.activeType === 'member'){
                        this.disabledNoLimit = this.checkedTreeItemNode.parent.data.peopleLimitType === 1 ? false : true;
                    } else {
                        this.disabledNoLimit = this.checkedTreeItem.parentObj.peopleLimitType === 1 ? false : true;
                    }
                }
                this.editData();
            } else {
               if(n === 'one'){
                    this.headerName = this.$t('addDept.addDeptTitle');
                    this.disabledLimit = true;
                    this.ruleForm.peopleLimitType = 1;
                    this.disabledLimit = true;
                    this.disabledNoLimit = false;
                } else  if(n === 'addSameDept'){
                    this.headerName = this.$t('addDept.addSameDepart');
                    if(this.checkedTreeItemNode.level === 1){
                        this.disabledNoLimit = false;
                    } else {
                        this.handelerStatus()
                    }
                    this.disabledLimit = this.checkedTreeItem.parentId === -1 ? true : false;
                    this.ruleForm.peopleLimitType = this.checkedTreeItem.parentId === -1 ? 1 : 2;
                } else  if(n === 'addChildDept'){
                    this.headerName = this.$t('addDept.addChildDept');
                    this.disabledLimit = false;
                    this.ruleForm.peopleLimitType = 2;
                    this.handelerStatus()
                } else if (n === 'addDept'){
                    this.headerName = this.$t('addDept.addDept');
                }
            }
           
        },
    },
    created() {
       this.headerName = this.$t('addDept.addSameDepart')
    },
    methods:{
        botAuthChange(){
           this.queryPeopleAuthNum(); 
        },
        // 查询主体下可咨询机器人的总数及已有可咨询机器人人数
         // 0部门,1员工,2上下游部门,3上下游员工
        queryPeopleAuthNum(){
           this.$http.post(this.requestUrl.upAndDown.queryPeopleAuthNum + '?type=2' + '&authBot=' + this.ruleForm.botAuth,[this.checkedTreeItem.id])
                .then((res) => {
                    if(res.data.code == 0){
                        this.existUseBotPeopleNum = res.data.data.existUseBotPeopleNum;
                        this.totalUseBotPeopleNum = res.data.data.totalUseBotPeopleNum;
                    } else {
                        this.$message.error(res.data.message)
                    }
                });
        },
         // d打开组织架构tree弹框
		openMemberTree(){
            console.log(this.treeData,111);
			this.showPopup = true;
		},
        // 选择部门后
		checkedDept(list,treeData){
            
			this.checkedDeptPopup = [...[],...list];
			this.treeDataCopy = JSON.parse(JSON.stringify(treeData))
			this.showPopup = false;
            this.disabledLimit = false;
            console.log(this.checkedDeptPopup,999);
            this.disabledNoLimit =  this.checkedDeptPopup.length > 0 && this.checkedDeptPopup[0].peopleLimitType === 1 ? false : true;
		},
        // 根据父子节点限制条件 ，判断按钮是否禁用
        handelerStatus(){
            // 0 限制 1 不限制 2 根据上级动态判断
            if(this.activeType === 'member'){
                this.checkedTreeItemNode.parent.childNodes.forEach(item => {
                    if(item.isCurrent){
                        this.disabledNoLimit = item.data.peopleLimitType === 1 ? false : true;
                        return;
                    }
                })
            } else {
                this.disabledNoLimit = this.checkedTreeItem.parentObj.peopleLimitType === 1 ? false : true;
            }
           
        },
        // 编辑回显数据
        editData(){
            this.ruleForm = {
                name:this.checkedTreeItem.name,
                peopleLimitType:this.checkedTreeItem.peopleLimitType,
                num:this.checkedTreeItem.peopleLimitType === 0 ? this.checkedTreeItem.peopleLimit : '',
                botAuth:this.checkedTreeItem.botAuth ? this.checkedTreeItem.botAuth : false,
                workOrderAuth:this.checkedTreeItem.workOrderAuth ? this.checkedTreeItem.workOrderAuth : false,
            }
        },
        // 关闭弹框
        closeEvent(){
            this.$refs['ruleForms'].resetFields();
            this.$emit('addDeptSuccess',false);
        },
        // handelOrderNum
        handelOrderNum(){
            let num = 0;
            if(this.activeType === 'member'){
                if(this.type === 'addSameDept'){
                num = this.checkedTreeItemNode.parent.childNodes[this.checkedTreeItemNode.parent.childNodes.length-1].data.orderNum + 1;
                } else if (this.type === 'addChildDept'){
                    if(this.checkedTreeItemNode.childNodes.length === 0){
                        num = 1;
                    } else {
                        num = this.checkedTreeItem.children[this.checkedTreeItem.children.length-1].orderNum + 1;
                    }
                }
            } else {
                console.log( this.checkedTreeItem,3333333);
            }
           
           return num;
        },
        judgeTree(data) {
			data.forEach((item,index) => {
				delete item.isFirstNode;
                delete item.isTop;
                delete item.isBottom;
                delete item.length;
                delete item.parentObj;
                delete item.copyList;
				if (item.children && item.children.length > 0) {
					this.judgeTree(item.children);
				}
			});
			return data[0]
		},
        // 保存
        submitForm(formName){
            let flag = true;
            if(this.ruleForm.name === ''){
                flag = false;
                this.$message.error(this.$t('addDept.nameRequired'))
				return false;
            }
            if(!this.ruleForm.botAuth && !this.ruleForm.workOrderAuth){
                flag = false;
                this.$message.error(this.$t('addDept.permissionRequired'))
				return false;
            }
            if(this.ruleForm.peopleLimitType === 0){
                if(this.ruleForm.num === ''){
                    flag = false;
                    this.$message.error(this.$t('addDept.numRequired'))
                    return false;
                } else if(this.ruleForm.num > 1000000) {
                    flag = false;
                    this.$message.error(this.$t('addDept.reasonableQuantity'))
                    return false;
                } else if(this.ruleForm.num < 1 ) {
                    flag = false;
                    this.$message.error(this.$t('addDept.thanOne'))
                    return false;
                }
            }
            if(this.type === 'addDept'){
                if(this.checkedDeptPopup.length === 0){
                    flag = false;
                    this.$message.error(this.$t('addDept.superiorDepartmentMsg'))
                    return false;
                }
            }

            if(flag){
                if(this.type === 'edit'){
                    let copy = JSON.parse(JSON.stringify(this.checkedTreeItem))
                    copy= this.judgeTree([copy]);
                    let params = {
                        ...Object.assign(copy),
                        name:this.ruleForm.name,
                        peopleLimit: this.ruleForm.peopleLimitType === 2 ? null : this.ruleForm.peopleLimitType === 1 ? 0 : this.ruleForm.num, 
                        peopleLimitType:this.ruleForm.peopleLimitType,
                        botAuth:this.ruleForm.botAuth,
                        workOrderAuth:this.ruleForm.workOrderAuth,
                    }
                    
                   
                    this.$http.put(this.requestUrl.upAndDown.updateUpAndDownStream,params)
                        .then((res) => {
                            if(res.data.code == 0){
                                this.$message.success(this.$t('addDept.editDeptSuccess'))
                                this.$refs[formName].resetFields();
                                this.$emit('addDeptSuccess',true);
                            } else {
                                this.$message.error(res.data.message)
                            }
                        });
                } else {
                    let params = {
                        name: this.ruleForm.name, //部门名称
                        orderNum: 0,   // 排序字段
                        parentId: this.type === 'one' ? -1 : '',   //父ID，一级节点传 -1
                        peopleLimit: this.ruleForm.peopleLimitType === 2 ? null : this.ruleForm.peopleLimitType === 1 ? 0 : this.ruleForm.num, 
                        peopleLimitType:this.ruleForm.peopleLimitType,
                        principalId: null, // 部门负责人ID
                        botAuth:this.ruleForm.botAuth,
                        workOrderAuth:this.ruleForm.workOrderAuth,
                    }
                    if(this.type === 'addSameDept'){
                        params.parentId = this.checkedTreeItem.parentId;
                        params.orderNum = this.handelOrderNum();
                    } else if (this.type === 'addChildDept'){
                        params.parentId = this.checkedTreeItem.id;
                        params.orderNum = this.handelOrderNum();
                    } else if (this.type === 'one'){
                        params.orderNum = 1;
                    } else if (this.type === 'addDept'){
                        params.orderNum = this.handelOrderNum();
                        params.parentId = this.checkedDeptPopup[0].id;
                    }
                    if(this.source === 5){
                        params.peopleLimitType = 2;
                    }
                    this.$http.post(this.requestUrl.upAndDown.addUpAndDownStream,params)
                        .then((res) => {
                            if(res.data.code == 0){
                                this.$message.success(this.$t('addDept.addDeptSuccess'))
                                this.$refs[formName].resetFields();
                                this.$emit('addDeptSuccess',true,this.type === 'one' ? 'reload' : 'no');
                            } else {
                                this.$message.error(res.data.message)
                            }
                        });
                    }
                
            }
        }
    }

}
</script>

<style scoped lang="less">
    .add-dept{
        .el-button{
            width: 99px;
            height: 37px;
            padding: 0;
        }
        .el-button--primary{
            background-color: #366AFF;
            border-color: #366AFF;
        }
        ::v-deep .el-form--label-top .el-form-item__label{
			float: left;
			&.after{
				clear: both;
			}
		}
        .limit-contnet{
            display: flex;
            flex-direction: column;
            ::v-deep .el-input__suffix-inner span{
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #000000;
            }
        }
        .red {
			color: red;
            margin-left: 6px;
		}
        ::v-deep .el-form-item{
            display: flex;
            flex-direction: column;
            .el-radio__input.is-checked .el-radio__inner{
                border-color: #366AFF;
                background: #366AFF;            
            }
            .el-radio__input.is-checked+.el-radio__label{
                color: #366AFF;
            }
        }
         .staff-input{
            // height: 36px;
            width:466px;
            border-radius: 5px;
            border:1px solid #E0E6F7;
            cursor: pointer;
            display: flex;
            align-items: center;
            .placeholder-text{
                padding-left: 15px;
                color: #A9B3C6;
            }
            .client-value{
                padding: 5px;
                color: #606266;
                display: flex;
                // justify-content: space-around;
                flex-wrap: wrap;
                align-items: center;
                line-height: 30px;
                width: 100%;
                .item{
                    border:1px solid hsl(240, 5%, 92%);
                    height: 24px;
                    line-height: 24px;
                    padding: 0 8px;
                    line-height: 22px;
                    position: relative;
                    background-color: #ECF1FF;
                    color: #366AFF;
                    border-radius: 3px;
                    font-size: 12px;
                    margin: 2px 0 2px 6px;
                    .el-icon-close{
                        display: inline-block;
                        border-radius: 50%;
                        text-align: center;
                        position: relative;
                        cursor: pointer;
                        font-size: 12px;
                        height: 14px;
                        width: 14px;
                        line-height: 14px;
                        background-color: #c0c4cc;
                        color: #909399;
                        cursor: pointer;
                    }
                }
                

            }
            
        }
        .bot-num{
            margin-bottom: 30px;
        }
        .bot-num,.choose-num{
            height: 21px;
            display: flex;
            // justify-content: space-around;
            align-items: center;
            color: #616161;
            .icon{
                width: 21px;
                height: 21px;
                background: #09DDD5;
                border-radius: 50%;
                text-align: center;
                line-height: 21px;
                color: #fff;
                
                &.choose{
                    background-color: #87A6FF;
                }
            }
            .type{
                margin: 0 15px 0 10px;
                width: 122px;
                text-align: left;
                color: #A9B3C6;
            }
            .go-beyond{
                // width: 92px;
                padding: 0 6px;
                height: 20px;
                font-size: 12px;
                background: #FFEBEC;
                border-radius: 3px;
                color: #FE5965;
                text-align: center;
                line-height: 20px;
                margin-left: 5px;
            }

        }
    }
</style>