export const setI18nFormInfo = (object, _that) => {
    for (const key in object) {
        object[key].forEach((item, index) => {
            if (key == 'groupModalName') {
                if (index == 0) {
                    item.message = _that.$t('botLink.dingGroupDrawer.dingTemplateNamePla')
                }
                if (index == 1) {
                    item.message = _that.$t('channel.rule64Msg')
                }
            }
            if (key == 'groupModalId') {
                if (index == 0) {
                    item.message = _that.$t('botLink.dingGroupDrawer.dingTemplateIdPla')
                }
                if (index == 1) {
                    item.message = _that.$t('channel.rule64Msg')
                }
            }
            if (key == 'groupBotId') {
                if (index == 0) {
                    item.message = _that.$t('botLink.dingGroupDrawer.botIdPla')
                }
                if (index == 1) {
                    item.message = _that.$t('channel.rule64Msg')
                }
            }
            if (key == 'corpId') {
                if (index == 0) {
                    item.message = _that.$t('botLink.enterpriseIDPla')
                }
                if (index == 1) {
                    item.message = _that.$t('channel.rule36Msg')
                }
            }
            if (key == 'agentId') {
                if (index == 0) {
                    item.message = _that.$t('botLink.agentIdPla')
                }
                if (index == 1) {
                    item.message = _that.$t('channel.rule32Msg')
                }
            }
            if (key == 'secret') {
                if (index == 0) {
                    item.message = _that.$t('botLink.secretPla')
                }
                if (index == 1) {
                    item.message = _that.$t('channel.rule64Msg')
                }
            }
            if (key == 'appKey') {
                if (index == 0) {
                    item.message = _that.$t('botLink.appKeyPla')
                }
                if (index == 1) {
                    item.message = _that.$t('channel.rule64Msg')
                }
            }
            if (key == 'agentName') {
                if (index == 0) {
                    item.message = _that.$t('botLink.appNamePla')
                }
                if (index == 1) {
                    item.message = _that.$t('channel.rule64Msg')
                }
            }
            if (key == 'corpName') {
                if (index == 0) {
                    item.message = _that.$t('botLink.enterpriseNamePla')
                }
                if (index == 1) {
                    item.message = _that.$t('channel.rule64Msg')
                }
            }
            if (key == 'signKey') {
                if (index == 0) {
                    item.message = _that.$t('botLink.signKeyPla')
                }
                if (index == 1) {
                    item.message = _that.$t('channel.rule64Msg')
                }
            }
            if (key == 'appSecret') {
                if (index == 0) {
                    item.message = _that.$t('botLink.appSecretPla')
                }
                if (index == 1) {
                    item.message = _that.$t('channel.rule64Msg')
                }
            }
            if (key == 'appId') {
                if (index == 0) {
                    item.message = _that.$t('botLink.appidPla')
                }
                if (index == 1) {
                    item.message = _that.$t('channel.rule64Msg')
                }
            }
            if (key == 'websiteName') {
                if (index == 0) {
                    item.message = _that.$t('botLink.websiteNamePla')
                }
            }
            if (key == 'domain') {
                if (index == 0) {
                    item.message = _that.$t('botLink.websiteDomainNamePla')
                }
            }
        });
    }
}