<template>
    <div id="account-bind">
        <el-table
                :data="accountList"
                style="width: 100%"
                :header-cell-style="{fontSize:'13px',backgroundColor:'#F6F8FD',height:'50px'}"
                :row-style="{fontSize:'13px'}"
        >
            <el-table-column
                    prop="type"
                    label="账号类型"
                    align="center"
                    width="80">
                <template scope="scope">
                    <span style="font-size: 13px">{{scope.row.type}}</span>
                </template>
            </el-table-column>
            <el-table-column
                    label="详情"
                    >
                <template scope="scope">
                    <div class="detail-info">
                        <div class="detail-avatar" v-if="scope.row.avatar">
                            <el-avatar :size="26" :src="scope.row.avatar"></el-avatar>
                        </div>
                        <div class="detail-name">{{scope.row.name?scope.row.name:'--'}}</div>
                    </div>

                </template>
            </el-table-column>
            <el-table-column
                    label="绑定时间"
                    align="center"
                    width="150">
                <template scope="scope">
                    <span style="font-size: 13px">{{scope.row.createTime?new Date(scope.row.createTime).Format('yyyy-MM-dd hh:mm:ss'):'--'}}</span>
                </template>
            </el-table-column>
            <el-table-column
                    label="状态"
                    width="100"
                    align="center"
            >
                <template scope="scope">
                    <div class="bindStatus">
                        <span class="bindStatusBtn" :class="scope.row.isBind?'bindStatusBtn':'no-bindStatusBtn'">
                        </span>
                        <span style="font-size: 13px">{{scope.row.isBind?'使用中':'未绑定'}}</span>
                    </div>

                </template>
            </el-table-column>
            <el-table-column
                    label="操作"
                    width="100"
                    align="center"
            >
                <template slot-scope="scope">
                    <el-button  type="danger" size="small" v-if="scope.row.isBind" @click="unBind(scope.row.type)">解除绑定</el-button>
                    <el-button  type="primary" size="small" v-if="!scope.row.isBind" @click="bindNow(scope.row.type)">立即绑定</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog title="绑定邮箱"
                   :visible.sync="dialogEmail"
                   width="400px"
                   :close-on-click-modal="false"
        >
            <el-form label-position="top">
                <el-form-item>
                    <span slot="label" style="text-align: left">邮箱</span>
                    <el-input v-model="emailValue" @blur="emailCheck(emailValue)" placeholder="请输入绑定邮箱"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogEmail = false">取 消</el-button>
                <el-button type="primary" @click="bindEmail">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {redirectUri} from "../const/redirectUri";
    export default {
        name: "accountBind",
        data(){
            return{
                dialogEmail:false,
                emailValue:'',
                accountList:[]
            }
        },
        props:[],
        methods:{
            //获取用户的角色
            getBind(){
                this.accountList =[]
                this.$http.get('/api/account/current').then(res=>{
                    if(res.data.code==='0')
                    {
                       let userInfo = res.data.data
                        console.debug('userInfo',userInfo)
                        let qiyeinfo = {
                            type:'企业微信',
                            name:'',
                            isBind:false,
                            avatar:'',
                            createTime:''
                        }
                        let youxianginfo ={
                            type:'邮箱',
                            name:'',
                            isBind:false,
                            avatar:'',
                            createTime:''
                        }
                        let dingTalkInfo ={
                          type:'钉钉',
                          name:'',
                          isBind:false,
                          avatar:'',
                          createTime:''
                        }
                        if (userInfo.weWorkQrCodeLoginInfo){
                            qiyeinfo.name = userInfo.weWorkQrCodeLoginInfo.user_info?userInfo.weWorkQrCodeLoginInfo.user_info.name
                                ?userInfo.weWorkQrCodeLoginInfo.user_info.name:userInfo.weWorkQrCodeLoginInfo.user_info.userid:'';
                            qiyeinfo.isBind = (userInfo.weWorkQrCodeLoginInfo.user_info&&userInfo.weWorkQrCodeLoginInfo.user_info.userid) ?true:false
                            qiyeinfo.avatar = userInfo.weWorkQrCodeLoginInfo.user_info?userInfo.weWorkQrCodeLoginInfo.user_info.avatar:""
                            qiyeinfo.createTime = userInfo.weWorkQrCodeLoginInfo?userInfo.weWorkQrCodeLoginInfo.createTime:''
                        }
                        if (userInfo.email){
                            youxianginfo.name = userInfo.email?userInfo.email:""
                            youxianginfo.isBind = userInfo.email?true:false

                        }
                        if (userInfo.dingTalkLoginInfo){
                          dingTalkInfo.name = userInfo.dingTalkLoginInfo.nick;
                          dingTalkInfo.avatar = userInfo.dingTalkLoginInfo.avatarUrl;
                          dingTalkInfo.createTime = userInfo.dingTalkLoginInfo.infoCreateTime;
                          dingTalkInfo.isBind = userInfo.dingTalkLoginInfo.openId != null;
                        }
                        this.accountList.push(qiyeinfo)
                        this.accountList.push(youxianginfo)
                        this.accountList.push(dingTalkInfo)
                    }
                })
            },
            unBind(type){
                console.log(type)
                let uid = localStorage.getItem('_uid')
                let fields = null;
                if (type==='企业微信'){
                  fields = 'weWorkQrCodeLoginInfo';
                }else if (type==='邮箱'){
                  fields = 'email';
                }else if (type==='钉钉'){
                  fields = 'dingTalkLoginInfo';
                }
                if (fields==null){
                  console.warn('not support bind type to unbind',type);
                }

                this.$confirm('是否进行解除绑定?', '解除绑定', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let url ='/api/account/field/'+uid+'/'+fields

                    this.$http.delete(url).then(res=>{
                        if (res.data.code==='0'){
                            this.getBind()
                            this.$message.success('解除绑定成功')

                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '取消'
                    });
                });
            },
            bindNow(type){
                if (type==='企业微信'){
                    let url='/scrm-api/we-work/sso/3rd-qr-connect?redirect_uri='+encodeURIComponent(redirectUri.callback+'/qrcode-callback.html')
                    this.$http.get(url).then(res=>{
                        console.debug('qr code url',res);
                        if (res.status===200){
                            let that = this
                            let w =  window.open(res.data,'newwindow', 'height=500, width=500, top=200, left=500, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=n o, status=no')
                            var loop = setInterval(function() {

                                if(w  != null && w.closed) {
                                    clearInterval(loop);
                                    //do something 在这里执行回调
                                    that.getBind()
                                }

                            }, 800);
                        }


                    }).catch(()=>{
                        this.$message.warning('返回个人中心重新操作')
                    });
                }
                else if (type==='钉钉'){
                  let url=' /ding-talk-api/auth/askbot/url?state='+localStorage.getItem('_mainId')+'&redirectUri='+encodeURIComponent(redirectUri.callback+'/dingtalk-login-callback.html')
                  this.$http.get(url).then(res=>{
                    console.debug('qr code url',res);
                    if (res.status===200){
                      let that = this
                      let w =  window.open(res.data,'newwindow', 'height=500, width=500, top=200, left=500, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=n o, status=no')
                      var loop = setInterval(function() {

                        if(w  != null && w.closed) {
                          clearInterval(loop);
                          //do something 在这里执行回调
                          that.getBind()
                        }
                      }, 800);
                    }
                  }).catch(()=>{
                    this.$message.warning('返回个人中心重新操作')
                  });
                }
                else  if (type==='邮箱'){
                    this.dialogEmail = true
                    this.emailValue =''
                }
            },
            bindEmail(){
                let uid = localStorage.getItem('_uid')
                let url ='/api/account/field/'+uid+'/email'
                let config = {
                  headers:{
                    "Content-Type": "application/json",
                  }
                }
                let email = this.emailCheck(this.emailValue)
                if (!email){

                    return
                }
                this.$http.put(url,JSON.stringify(this.emailValue),config).then(res=>{
                    if (res.data.code==='0'){
                        this.getBind()
                        this.$message.success('绑定成功')
                    }
                })
                this.dialogEmail = false
            },
            emailCheck(value){
                /*const reg= /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/*/
               /* const reg= /^\w+@[a-z0-9]+\.[a-z]{2,4}$/*/
                const reg = /^([a-z0-9A-Z]+[-|_|\.]?)+[a-z0-9A-Z]@([a-z0-9A-Z]+(-[a-z0-9A-Z]+)?\.)+[a-zA-Z]{2,4}$/
                let bool = true
                if(value){
                    if(!reg.test(value)){
                        bool = false
                        this.$message({
                            message:'请输入有效的邮箱',
                            duration:2000,
                            type:'warning'
                        })
                    }
                }
                return bool
            }

        },
        mounted() {
            this.getBind()
        }
    }
</script>

<style scoped lang="less">
#account-bind{
    font-size: 13px!important;
    .detail-info{
        display: flex;
        align-items: center;
        .detail-avatar{
            margin-right: 8px;
        }
        .detail-name{
            font-size: 13px;
        }
    }
    .detailName{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 400px;
    }
.bindStatus{
    display: flex;
    align-items: center;
    justify-content: center;
    .bindStatusBtn{
        width: 6px;
        height: 6px;
        background-color: #366aff;
        margin-right: 7px;
    }
    .no-bindStatusBtn{
        width: 6px;
        height: 6px;
        background-color: #cccccc;
        margin-right: 7px;
    }
}
    /deep/.el-form-item__label{
        display: flex;
    }
}
</style>