<template>
  <div class="basicInfo">
    <el-form ref="bindForm" :model="dingGroupBindForm" :rules="bindRules">
      <el-form-item :label="$t('botLink.dingGroupDrawer.dingTemplateName')" prop="groupModalName">
        <el-input v-model="dingGroupBindForm.groupModalName"
          :placeholder="$t('botLink.dingGroupDrawer.dingTemplateNamePla')" :autofocus="true"></el-input>
      </el-form-item>
      <el-form-item :label="$t('botLink.dingGroupDrawer.dingTemplateId')" prop="groupModalId">
        <el-input v-model="dingGroupBindForm.groupModalId"
          :placeholder="$t('botLink.dingGroupDrawer.dingTemplateIdPla')" :autofocus="true"></el-input>
      </el-form-item>
      <el-form-item :label="$t('botLink.dingGroupDrawer.botId')" prop="groupBotId">
        <el-input v-model="dingGroupBindForm.groupBotId" :placeholder="$t('botLink.dingGroupDrawer.botIdPla')"
          :autofocus="true"></el-input>
      </el-form-item>
      <el-form-item prop="corpId" :label="$t('botLink.bindDingCorpIdTip')">
        <el-input v-model="dingGroupBindForm.corpId" :placeholder="$t('botLink.enterpriseIDPla')"></el-input>
      </el-form-item>
      <el-form-item prop="agentId" :label="$t('botLink.dingAgentIdTip')">
        <el-input v-model="dingGroupBindForm.agentId" :placeholder="$t('botLink.agentIdPla')"></el-input>
        <span v-if="warningVisible" style="
                            color: #f56c6c;
                            display: block;
                            text-align: left;
                            font-size: 12px;
                            line-height: 16px;
                        " class="warning-text">*{{ $t('botLink.agentIdTip') }}</span>
      </el-form-item>
      <el-form-item prop="appKey">
        <el-input v-model="dingGroupBindForm.appKey" :placeholder="$t('botLink.appKeyPla')"></el-input>
      </el-form-item>
      <el-form-item prop="secret">
        <el-input v-model="dingGroupBindForm.secret" :placeholder="$t('botLink.secretPla')"></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { setI18nFormInfo } from "@/utils/common"
export default {
  name: "dingGroupBasicInfo",
  data() {
    return {
      bindRules: {
        groupModalName: [
          {
            required: true,
            message: "",
            trigger: "blur",
          },
          { max: 64, message: "", trigger: "blur" },
        ],
        groupModalId: [
          {
            required: true,
            message: "",
            trigger: "blur",
          },
          { max: 64, message: "", trigger: "blur" },
        ],
        groupBotId: [
          {
            required: true,
            message: "",
            trigger: "blur",
          },
          { max: 64, message: "", trigger: "blur" },
        ],
        corpId: [
          {
            required: true,
            message: "",
            trigger: "blur",
          },
          { max: 36, message: "", trigger: "blur" },
        ],
        agentId: [
          {
            required: true,
            message: "",
            trigger: "blur",
          },
          { max: 32, message: "", trigger: "blur" },
        ],
        secret: [
          {
            required: true,
            message: "",
            trigger: "blur",
          },
          { max: 64, message: "", trigger: "blur" },
        ],
        appKey: [
          {
            required: true,
            message: "",
            trigger: "blur",
          },
          { max: 64, message: "", trigger: "blur" },
        ],
      },
      dingGroupBindForm: {

      },
      warningVisible: false,
    }
  },
  created() {
    setI18nFormInfo(this.bindRules , this)
  }
};
</script>

<style scoped lang="less">
.basicInfo {
  /deep/.el-form-item__label {
    line-height: 24px;
  }
}
</style>