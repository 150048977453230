import Command from "@ckeditor/ckeditor5-core/src/command";
import { multipartUpload, ossFileUrl } from "@/assets/js/AliyunIssUtil";
import { v4 as uuidv4 } from "uuid";
import { generateOssConfig } from "../ossConfig";
const ossConfig = generateOssConfig("open")

function findListener(askPluginListeners, type) {
    for (let i = 0, len = askPluginListeners.length; i < len; i++) {
        if (askPluginListeners[i].event === type) {
            return askPluginListeners[i];
        }
    }
}

export default class FileCommand extends Command {
    refresh() {
        this.isEnabled = true;
    }
    execute(file) {
        if (file){
            this.forEachFiles(file.file)
            return
        } 
        var inputObj = document.createElement('input')
        inputObj.setAttribute('id', '_ef');
        inputObj.setAttribute('type', 'file');
        inputObj.setAttribute('multiple', true);
        inputObj.setAttribute('accept','image/*,video/mp4')
        inputObj.setAttribute("style", 'visibility:hidden');
        document.body.appendChild(inputObj);
        inputObj.click();
        inputObj.onchange = () => {
            // 循环上传文件
            let files = inputObj.files;
            for (let index = 0; index < files.length; index++) {
                const filed = files[index];
                let uid =  uuidv4()
                let command = this.editor.commands.get("insertAskComponent"); 
                const listeners = this.editor.config.get('askPluginListener');
                let beforeButtonInsert = findListener(listeners, "UPLOAD");
                if(filed.type.includes('image')) {
                    if(this.editor.uploadImg) {
                        this.editor.uploadImg.push(uid)
                    } else {
                        this.editor.uploadImg = []
                        this.editor.uploadImg.push(uid)
                    }
                    this.ImgUploadCallback({ command, filed, uid, index, beforeButtonInsert})
                } else {
                    this.FileUploadCallback({ command, filed, uid ,beforeButtonInsert})
                }
            }
        }
    }
    ImgUploadCallback({command, filed, uid, index, beforeButtonInsert}){
        command.execute({
            tag: "span-editable",
            options: {
                name: filed.name + '正在上传...',
                data: uid,
                editable:false,
                type:'upload'
            },
        })
        // 记录当前光标节点
        let selectionPosition = this.editor.model.document.selection.getFirstPosition();
        //插入两个换行符
        this.editor.execute('enter')
        this.editor.execute('enter')
        this.upload(filed).then(res =>{
            this.delChildrenNode('image')
            this.editor.model.change(writer => {
                // 移动到换行符之前插入图片
                writer.setSelection(selectionPosition);
                command.execute({
                    tag: "img",
                    options: {
                        width:'100%',
                        alt: filed.name + index,
                        src: res.default
                    },
                });
            })
        })
    }
    FileUploadCallback({command, filed, uid, beforeButtonInsert}) {
        beforeButtonInsert.process({
            type: "UPLOAD",
            param:{
                uid:uid,
                type:'pending',
                name:filed.name
            }
        });
        this.upload(filed).then(res =>{
            beforeButtonInsert.process({
                type: "UPLOAD",
                param:{
                    uid: uid,
                    name:filed.name,
                    type:'success',
                    url: res.default
                }
            })
        })
    }
    delChildrenNode(type) {
        let root =  this.editor.model.document.getRoot()
        let children = root.getChildren()
        for(let child of children){
            for (let index = 0; index < child._children._nodes.length; index++) {
                if(child._children._nodes[index].name == 'askComponentPlaceholderContainer'){
                    if(child._children._nodes[index]._children._nodes[0]) {
                        let attrs = child._children._nodes[index]._children._nodes[0]._attrs
                        if(attrs) {
                            let attrsList = [...attrs.keys()]
                            for (let j = 0; j < attrsList.length; j++) {
                                if(attrsList[j] == 'data') {
                                    if(type == 'file') {
                                        this.editor.uploadImg = this.editor.uploadImg.filter(id =>{return id != attrs.get(attrsList[j]).id })
                                    } else {
                                        this.editor.uploadImg = this.editor.uploadImg.filter(id =>{return id != attrs.get(attrsList[j]) })
                                    }
                                }
                            }
                        }
                        this.editor.model.change(writer => {
                            writer.remove(child._children._nodes[index]._children._nodes[0]);
                        });
                    }
                }
            }
        }
    }
    forEachFiles(files){
        for (let index = 0; index < files.length; index++) {
            const filed = files[index];
            this.upload(filed).then(res =>{
                // 需要回调通知父组件 上传成功
                let command = this.editor.commands.get("insertAskComponent");
                command.execute({
                    tag: "span-editable",
                    options: {
                        name: filed.name,
                        data: res.default,
                        editable:false,
                        type:'MyFile'
                    },
                    // src: res.default
                })
            })
        }
    }

    upload(file) {
        return new Promise((resolve) => {
            /* this._initRequest();
             this._initListeners( resolve, reject, file );
             this._sendRequest( file );*/
            this.uploadFile(file, resolve)
        })
    }
    uploadFile(file, resolve) {
        let imgInfo = {
            url: '',
        }
        let res = multipartUpload(
            ossConfig,
            file,
            null,
            imgInfo
        );
        res.then(resp => {
            imgInfo.url = ossFileUrl(ossConfig, resp.name)
            resolve({
                name: resp.name,
                default: imgInfo.url
            })
        })
    }
}