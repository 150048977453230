<template>
  <div class="accessConfiguration">
    <div class="gr-content-container">
      <div class="personalHeader">
        <detail-page-header @goBack="goBack">
          <div slot="header-name" class="header-name">
            <span class="template-name">{{$t('channel.accessHeader')}}{{ sourceName }}</span>
            <div class="tips" v-if="accessChannel === 'dingding' || accessChannel === 'wechat'" @click="changeWay">{{ tips }}</div>
          </div>
        </detail-page-header>
      </div>
      <div class="access-content">
        <template v-if="accessChannel !== 'feishu' && accessChannel !== 'askBotClient'">
          <template v-if="accessChannel === 'dingding' && !thirdParty">
            <div class="container">
              <div class="tips">{{$t('channel.dingThirdTip')}}</div>
              <img src="../../assets/images/dingdingThird.png" style="height: 300px">
              <div class="refresh">
                {{$t('channel.dingThirdTip2')}}
              </div>
            </div>
          </template>
          <template v-else-if="accessChannel === 'dingdingGroup' && !thirdParty">
            <third-party-access></third-party-access>
          </template>
          <template v-else-if="accessChannel === 'wechat' && !thirdParty">
            <div class="container">
              <div class="tips">{{$t('channel.wechatThirdTip')}}</div>
              <img src="../../assets/images/wechatThird.png" style="width: 300px">
              <div class="refresh">
                {{$t('channel.wechatThirdTip2')}}
              </div>
            </div>
          </template>
          <template v-else>
            <div class="stepBg">
              <step-header
                  ref="stepHeader"
                  :stepList="stepList">
              </step-header>
            </div>
            <div class="stepContainer" :class="accessChannel === 'wechatAccount' ? 'accountContainer' : ''">
              <div v-show="activeStepId === 'basic' || activeStepId === 'selectBot'" :class="accessChannel === 'wechatAccount' ? 'container' : ''">
                <basic-information
                    v-if="accessChannel === 'website'"
                    ref="basicInformation"
                    :bindDetail="bindDetail">
                </basic-information>
                <select-bot ref="selectBot" v-if="accessChannel === 'miniProgram'"></select-bot>
                <iframe v-else-if="accessChannel === 'wechatAccount'"
                        :src="iframeSrc"
                        height="100%"
                        width="100%"
                        frameborder="no"
                        border="0"
                        id="wechatAccountIframe"
                ></iframe>
<!--                <wechat-account-basic-info-->
<!--                    v-else-if="accessChannel === 'wechatAccount'"-->
<!--                    @authorizationSuccess="authorizationSuccess"-->
<!--                    :bindDetail="bindDetail"-->
<!--                    ref="wechatAccountBasic"-->
<!--                ></wechat-account-basic-info>-->
                <ding-group-basic-info v-else-if="accessChannel === 'dingdingGroup'" ref="dingGroupBasic"></ding-group-basic-info>
                <ding-single-chat-basic-info v-else-if="accessChannel === 'dingSingleChatBot'" ref="dingSingleChatBasic"></ding-single-chat-basic-info>
                <cloud-home-basic-info v-else-if="accessChannel === 'yunzhijia'" ref="cloudHomeBasic"></cloud-home-basic-info>
                <ding-basic-info v-else-if="accessChannel === 'dingding'" ref="dingBasic"></ding-basic-info>
                <wechat-basic-info v-else-if="accessChannel === 'wechat'" ref="wechatBasic"></wechat-basic-info>
                <div class="app_basic_info" v-else-if="accessChannel === 'app'" ref="appBasic">
                    <p class="app_basic_info_title">
                        {{ $t('channel.appBot.agentName') }}
                        <span>*</span>
                    </p>
                    <el-input v-model="appName" :placeholder="$t('common.placeholder')"></el-input>
                </div>
              </div>
              <div v-if="activeStepId === 'ability'">
                <bind-information
                    :bindDetail="bindDetail"
                    ref="bindInformation"
                >
                </bind-information>
                <ding-group-table v-if="accessChannel === 'dingdingGroup'" :bindDetail="bindDetail" ref="dingGroupTable"></ding-group-table>
                <el-dropdown trigger="click" @command="gotoIntelligentAgentConfig">
                  <div class="addBindIntelligent" v-if="isBindIntelligentAgent">
                    <div class="addIcon">
                      <i class="iconfont guoran-a-16-13"></i>
                    </div>
                    <span>{{$t('channel.bindAgent')}}</span>
                  </div>
                  <el-dropdown-menu slot="dropdown">
                    <div class="dropdownContainer">
                      <el-dropdown-item
                          v-for="(item,index) in intelligentAgentList"
                          :key="index"
                          class="intelligentAgentItem"
                          :command="item"
                      >
                        <el-avatar shape="square" :size="30" :src="item.logoUrl"></el-avatar>
                        <div class="name-des">
                          <div class="name">{{item.agentName}}</div>
                          <div class="des">{{item.description}}</div>
                        </div>
                      </el-dropdown-item>
                      <div class="loadMore" v-if="!isLastPage" @click="loadIntelligentAgent">
                        <span>{{$t('common.loadMore')}}</span>
                      </div>
                    </div>
                    <div class="addIntelligent" @click="addIntelligentFun">
                      {{$t('channel.addAgentBot')}}
                    </div>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <div v-if="activeStepId === 'access'">
                <access-info :bindDetail="bindDetail"></access-info>
              </div>
              <div v-if="activeStepId === 'complete'" class="container">
                <access-completed></access-completed>
              </div>
            </div>
          </template>
        </template>
        <template v-else-if="accessChannel === 'feishu'">
          <div class="container">
            <div class="feishu_tips">{{$t('channel.feishuThirdTip')}}</div>
            <template v-if="false">
              <img src="../../assets/images/webchat.png">
              <div class="refresh">
                <i class="iconfont guoran-tongyichicun-16-12-shuaxin"></i>
                刷新二维码
              </div>
            </template>
          </div>
        </template>
        <template v-else-if="accessChannel === 'askBotClient'">
          <div class="askBotContainer">
            <img src="../../assets/images/empty-flow.png" style="height: 240px">
            <div class="tips">askBotTip
              {{$t('channel.')}}
            </div>
            <div class="web-btn" @click="gotoWeb">{{$t('channel.goWebTip')}}</div>
          </div>
        </template>
        <div class="stepFooter" v-if="accessChannel !== 'askBotClient' && (accessChannel !== 'wechatAccount' || (accessChannel === 'wechatAccount' && activeStepId === 'complete'))" :class="accessChannel === 'dingdingGroup' ? 'footer-1' : 'footer-2'">
          <template v-if="accessChannel === 'dingdingGroup'">
            <div class="doc" @click="goHelp">
              <i class="iconfont guoran-tongyichicun-dianjichakantuli"></i>
              {{$t('channel.viewDoc')}}
            </div>
          </template>
          <template v-if="accessChannel !== 'feishu' &&
          ((accessChannel === 'dingdingGroup' && thirdParty) || accessChannel !== 'dingdingGroup') &&
          ((accessChannel === 'dingding' && thirdParty) || accessChannel !== 'dingding') &&
          ((accessChannel === 'wechat'&& thirdParty) || accessChannel !== 'wechat')">
            <div class="footerRight">
              <div class="previousStep stepBtn" @click="previousStep" v-if="activeStep > 1">{{$t('channel.previousStep')}}</div>
              <div class="nextStep stepBtn" @click="nextStep" v-if="activeStep < stepList.length">{{$t('channel.nextStep')}}</div>
              <div class="nextStep stepBtn" @click="goSet" v-else>{{$t('channel.setUp')}}</div>
            </div>
          </template>
          <template v-else-if="accessChannel !== 'app'">
            <div class="footerRight">
              <div class="previousStep stepBtn" @click="previousStep" v-if="activeStep > 1">{{$t('channel.previousStep')}}</div>
              <div class="nextStep stepBtn" @click="nextStep" v-if="activeStep < stepList.length">{{$t('channel.nextStep')}}</div>
              <div class="nextStep stepBtn" @click="goSet" v-else>{{$t('channel.setUp')}}</div>
            </div>
          </template>
          <template v-else>
            <div v-if="accessChannel === 'dingdingGroup' && !thirdParty" class="selfBuilt" @click="selfBuild">
              <span>{{$t('channel.selfBuild')}}</span>
            </div>
          </template>
        </div>
      </div>
    </div>
    <!-- 渠道管理删除机器人，校验机器人下所有意图是否被统一欢迎语配置使用 -->
    <popup @closeEvent="dialogCheckDelBot = false" v-if="dialogCheckDelBot" class="delbot-relation-popup" :haveTips="true">
      <div slot="popup-name">{{$t('botLink.dialogCheckDelBotTitle')}}</div>
      <div slot="popup-tip">{{$t('botLink.dialogCheckDelBotTip')}}</div>
      <div slot="popup-con" class="member-contanier">
        <div v-if="relationIntents.length > 0 " class="intent-relation-content">
          <div class="title">
            <span class="handle-title">{{$t('botLink.handleTitle')}}</span>
            <span class="relation-title">{{$t('botLink.relationTitle')}}</span>
          </div>
          <div
              v-for="(item, index) in relationIntents"
              :key="index"
              class="relation-content">
            <div class="handle-name">{{item.bot.name}}</div>
            <div class="relation-name">
              <div
                  v-for="(relationIntentItem, rIndex) in item.relationSettings"
                  :key="rIndex"
                  @click="gotoChatFlow(item,relationIntentItem)"
                  class="relation-name-cell">
                {{relationIntentItem.name}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="dialog-footer">
        <el-button style="width: 100px" @click="dialogCheckDelBot = false" plain class="cancel-btn">{{$t('common.close')}}</el-button>
      </div>
    </popup>
    <SideNav ref="leftMenuObj"></SideNav>
  </div>
</template>

<script>
import SideNav from "@/views/menu/SideNav";
import DetailPageHeader from "@/components/detail-header";
import stepHeader from "@/views/channelManage/components/stepHeader";
import BasicInformation from "@/views/channelManage/components/website/basicInformation";
import BindInformation from "@/views/channelManage/components/bindInformation";
import AccessInfo from "@/views/channelManage/components/accessInfo";
import AccessCompleted from "./components/accessCompleted";
import SelectBot from "./components/miniProgram/selectBot";
import wechatAccountBasicInfo from "./components/wechatAccount/wechatBasicInfo";
import ThirdPartyAccess from "./components/dingGroup/thirdPartyAccess";
import DingGroupBasicInfo from "./components/dingGroup/dingGroupBasicInfo";
import DingSingleChatBasicInfo from "./components/dingSingleChatBot/basicInfo";
import cloudHomeBasicInfo from "./components/cloudHome/basicInfo"
import Popup from "../../components/popupNew";
import DingBasicInfo from "./components/dingding/dingBasicInfo";
import WechatBasicInfo from "./components/wechat/basicInfo";
import appBasicInfo from './components/app/appBasicInfo.vue';
import { requestUrl } from "../../api/requestUrl";
import { authoritySetConfirm, submitBindBot } from "./utils/access";
import DingGroupTable from "./components/dingGroup/dingGroupTable";
export default {
    name: "accessConfiguration",
    components: {
        DingGroupTable,
        DingBasicInfo,
        Popup,
        DingSingleChatBasicInfo,
        DingGroupBasicInfo,
        ThirdPartyAccess,
        SelectBot,
        AccessCompleted,
        AccessInfo,
        BindInformation,
        BasicInformation,
        DetailPageHeader,
        SideNav,
        stepHeader,
        wechatAccountBasicInfo,
        cloudHomeBasicInfo,
        WechatBasicInfo,
        appBasicInfo
    },
    data(){
        return{
        activeStep: 1,
        preStep: 1,
        accessChannel:"",
        stepList:[
            {
                name: "基础配置",
                id:"basic"
            },
            {
                name: "能力管理",
                id:"ability"
            },
            {
                name: "接入信息",
                id:"access"
            },
            {
                name: "完成接入",
                id:"complete"
            }
        ],
        thirdParty:false,
        bindCode:"",
        bindDetail:{},
        relationIntents:[],
        dialogCheckDelBot:false,
        tips:"",
        activeStepId:"basic",//不同渠道stepHeader不同，用次表示当前在那步
        iframeSrc:"",
        authSuccess:false,
        appName: "",
        intelligentAgentList:[],
        keyword:"",
        pageNum:0,
        pageSize:20,
        total:0,
        isLastPage:true,
        isBindIntelligentAgent:false
    }
  },
  computed:{
    sourceName(){
      if (this.accessChannel === 'wechat'){
        return this.$t('channel.wechat.name')
      } else if (this.accessChannel === 'website'){
        return this.$t('channel.website.other')
      } else if (this.accessChannel === 'miniProgram'){
        return this.$t('channel.miniProgram.name')
      } else if (this.accessChannel === 'wechatAccount'){
        return this.$t('channel.wechatAccount.name')
      } else if (this.accessChannel === 'dingding'){
        return this.$t('channel.dingding.name')
      } else if (this.accessChannel === 'dingdingGroup'){
        return this.$t('channel.dingdingGroup.name')
      } else if (this.accessChannel === 'dingSingleChatBot'){
        return this.$t('channel.dingSingleChatBot.name')
      } else if (this.accessChannel === 'feishu'){
        return this.$t('channel.feishu.name')
      } else if (this.accessChannel === 'yunzhijia'){
        return this.$t('channel.yunzhijia.name')
      } else if (this.accessChannel === 'IM'){
        return 'IM'
      } else if (this.accessChannel === 'askBotClient'){
        return this.$t('channel.askBot')
      }
    }
  },
  methods:{
    goBack(){
      this.$router.push({
        path:"/channel"
      })
    },
    previousStep(){
      this.preStep = this.activeStep
      this.activeStep -= 1;
      this.activeStepId = this.stepList[this.activeStep - 1].id;
      this.$refs.stepHeader.activeStep = this.activeStep;
    },
    async nextStep(){
      let flag = true;
      if (this.activeStepId === 'basic' || this.activeStepId === 'selectBot'){
        if (this.accessChannel === 'website'){
          flag = await this.checkWebsite()
        }
        if (this.accessChannel === 'wechatAccount' && this.$refs.wechatAccountBasic && !this.$refs.wechatAccountBasic.isAuthorization){
          this.$message({
            message:this.$t('channel.empowerTip'),
            type:"warning"
          })
          return
        }
        if (this.accessChannel === 'miniProgram'){
          flag = await this.checkMiniProgram();
        }
        if (this.accessChannel === 'dingding'){
          flag = await this.checkDingding();
        }
        if (this.accessChannel === 'dingdingGroup'){
          flag = await this.checkDingGroup();
        }
        if (this.accessChannel === 'dingSingleChatBot'){
          flag = await this.checkDingChat();
        }
        if (this.accessChannel === 'yunzhijia'){
          flag = await this.checkYunzhijia();
        }
        if (this.accessChannel === 'wechat'){
          flag = await this.checkWechat();
        }
        if (this.accessChannel === 'wechatAccount' && !this.authSuccess){
          this.$message({
            message:this.$t('channel.empowerTip'),
            type:"warning"
          })
          flag = false
        }
        if(this.accessChannel === 'app') {
            flag = await this.checkApp()
        }
        await this.getInfoByBindCode();
      }
      if (this.activeStepId === 'ability'){
        flag = await this.submitBindBot();
      }
      if (!flag){
        return
      }
      this.preStep = this.activeStep
      this.activeStep += 1;
      this.activeStepId = this.stepList[this.activeStep - 1].id;
      this.$refs.stepHeader.activeStep = this.activeStep;
    },
    //网站
    async checkWebsite(){
      let websiteBindForm = this.$refs.basicInformation.websiteBindForm;
      if (!websiteBindForm.websiteName){
        this.$message({
          message:this.$t('botLink.websiteNamePla'),
          type:"warning"
        })
        return false
      }
      if (!websiteBindForm.domain){
        this.$message({
          message:this.$t('botLink.websiteDomainNamePla'),
          type:"warning"
        })
        return false
      }
      let url = this.requestUrl.bindV2.addWebsiteApp
      let objData = {
        domain: websiteBindForm.domain,
        websiteName: websiteBindForm.websiteName,
        channel:websiteBindForm.channel ? Number(websiteBindForm.channel) : null,
        corpId:websiteBindForm.corpId || '',
        corpName:websiteBindForm.corpName || '',
        needLogin:websiteBindForm.needLogin
      };
      console.log(objData,'objData');
      let result = true
      if (!this.bindDetail.id){
        result = await this.$http.post(
            url,
            objData,
            { emulateJSON: true }
        ).then(async (res) => {
          if (res.data.code === "0") {
            this.bindCode = res.data.data;
            this.$message.success(this.$t('channel.saveSuccess'));
            await this.getBindInfo();
            return true
          } else {
            this.$message.error(res.data.message);
            return false
          }
        });
      } else {
        result = await this.$http.put(
            this.requestUrl.websiteBind.updateWebInfo + '/' + this.bindDetail.id,
            objData
        ).then(async (res) => {
          if (res.data.code === "0") {
            this.bindCode = res.data.data;
            this.$message.success(this.$t('channel.saveSuccess'));
            await this.getBindInfo();
            return true
          } else {
            this.$message.error(res.data.message);
            return false
          }
        });
      }
      return result
    },

    //微信小程序
    async checkMiniProgram(){
      if (!this.$refs.selectBot.botId){
        this.$message({
          message:this.$t('channel.openMiniTip'),
          type:"warning"
        })
        return false
      }
      let url = '/api-open/bot/' + this.$refs.selectBot.botId;
      return await this.$http.put(url,{
        botSetting:{
          showAppletEntrance:true
        }
      }).then(async res => {
        if (res.data.code == 0 && res.data.data){
          this.$message({
            message:this.$t('channel.saveSuccess'),
            type:"success"
          })
          return true
        } else {
          this.$message.warning(res.data.message);
          return false
        }
      })
    },

    //检查钉钉自建
    async checkDingding(){
      let dingdingBindForm = this.$refs.dingBasic.dingdingBindForm;
      if (!dingdingBindForm.corpName){
        this.$message({
          type:"warning",
          message:this.$t('botLink.enterpriseNamePla')
        })
        return false
      }
      if (!dingdingBindForm.corpId){
        this.$message({
          type:"warning",
          message:this.$t('botLink.enterpriseIDPla')
        })
        return false
      }
      if (!dingdingBindForm.agentName){
        this.$message({
          type:"warning",
          message:this.$t('botLink.appNamePla')
        })
        return false
      }
      if (!dingdingBindForm.agentId){
        this.$message({
          type:"warning",
          message:this.$t('botLink.agentIdPla')
        })
        return false
      }
      if (!dingdingBindForm.appKey){
        this.$message({
          type:"warning",
          message:this.$t('botLink.appKeyPla')
        })
        return false
      }
      if (!dingdingBindForm.secret){
        this.$message({
          type:"warning",
          message:this.$t('botLink.appSecretPla')
        })
        return false
      }
      //检查是否有必填或者字符长度检验
      let bool = true;
      this.$refs.dingBasic.$refs['bindForm'].validate((valid) => {
        if (!valid) {
          this.$message({
            message:"请检查填写信息"
          })
          bool = false;
        }
      });
      if (!bool){
        return bool;
      }
      let url = this.requestUrl.dingdingBind.dingTalkSave;
      let obj = {
        agentId: dingdingBindForm.agentId.trim(),
        corpId: dingdingBindForm.corpId.trim(),
        corpName: dingdingBindForm.corpName.trim(),
        appSecret: dingdingBindForm.secret.trim(),
        appKey: dingdingBindForm.appKey,
        agentName: dingdingBindForm.agentName,
        isCheckAgent: false,
      }
      let ret = ''
      for (let it in obj) {
        ret += encodeURIComponent(it) + '=' + encodeURIComponent(obj[it]) + '&'
      }
      ret = ret.substring(0, ret.lastIndexOf('&'));
      url += '?' + ret;
      return await this.$http.post(
          url
      ).then((res) => {
        if (res.data.code === "0") {
          this.$message.success(this.$t('channel.bindSuccess'));
          this.bindDetail = res.data.data;
          this.bindCode = res.data.data.bindCode;
          return true
        } else if (res.data.code === "0OPBE0006") {
          this.$refs.dingBasic.warningVisible = true;
          return
        } else {
          this.$message.error(res.data.message);
          return
        }
      });
    },
    getInfoByBindCode(){
      let bindCode = this.bindCode;
      let url = this.requestUrl.bindV2.getInfoByBindCode + '?bindCode=' + bindCode;
      this.$http.get(url).then(res => {
        if (res.data.code == 0 && res.data.data){
          this.bindDetail = res.data.data;
          if (this.isBindIntelligentAgent){
            if (!this.bindDetail.wechatBindAgent){
              let obj = {
                agentBindBotInfos:[],
                bindCode:bindCode,
                bindId:this.bindDetail.id
              }
              this.$set(this.bindDetail,'wechatBindAgent',obj)
            }
          }
        }
      })
    },
    //检查钉钉群
    async checkDingGroup(){
      let dingGroupBindForm = this.$refs.dingGroupBasic.dingGroupBindForm;
      if (!dingGroupBindForm.groupModalName){
        this.$message({
          type:"warning",
          message:this.$t('botLink.dingGroupDrawer.dingTemplateNamePla')
        })
        return
      }
      if (!dingGroupBindForm.groupModalId){
        this.$message({
          type:"warning",
          message:this.$t('botLink.dingGroupDrawer.dingTemplateIdPla')
        })
        return
      }
      if (!dingGroupBindForm.groupBotId){
        this.$message({
          type:"warning",
          message:this.$t('botLink.dingGroupDrawer.botIdPla')
        })
        return
      }
      if (!dingGroupBindForm.corpId){
        this.$message({
          type:"warning",
          message:this.$t('botLink.enterpriseIDPla')
        })
        return
      }
      if (!dingGroupBindForm.agentId){
        this.$message({
          type:"warning",
          message:this.$t('botLink.agentIdPla')
        })
        return
      }
      if (!dingGroupBindForm.appKey){
        this.$message({
          type:"warning",
          message:this.$t('botLink.appKeyPla')
        })
        return
      }
      if (!dingGroupBindForm.secret){
        this.$message({
          type:"warning",
          message:this.$t('botLink.appSecretPla')
        })
        return
      }
      //检查是否有必填或者字符长度检验
      let bool = true;
      this.$refs.dingGroupBasic.$refs['bindForm'].validate((valid) => {
        if (!valid) {
          this.$message({
            message:"请检查填写信息"
          })
          bool = false;
        }
      });
      if (!bool){
        return bool;
      }
      let url = this.requestUrl.dingdingGroup.newTemplateBind;
      let obj = {
        groupTemplateName: dingGroupBindForm.groupModalName.trim(),
        agentName: dingGroupBindForm.groupModalName.trim(),
        groupTemplateId: dingGroupBindForm.groupModalId.trim(),
        groupRobotCode: dingGroupBindForm.groupBotId.trim(),
        agentId: dingGroupBindForm.agentId.trim(),
        corpId: dingGroupBindForm.corpId.trim(),
        appSecret: dingGroupBindForm.secret.trim(),
        appKey: dingGroupBindForm.appKey,
        dingTalkType: 7,
      }
      let ret = ''
      for (let it in obj) {
        ret += encodeURIComponent(it) + '=' + encodeURIComponent(obj[it]) + '&'
      }
      ret = ret.substring(0, ret.lastIndexOf('&'));
      url += '?' + ret;
      return await this.$http.post(url).then(async (res) => {
        if (res.data.code === "0") {
          this.$message.success(this.$t('channel.bindSuccess'));
          this.bindCode = res.data.data.bindCode;
          this.bindDetail = res.data.data;
          return true
        } else if (res.data.code === "0OPBE0006") {
          this.$refs.dingGroupBasic.warningVisible = true;
          return false
        } else {
          this.$message.error(res.data.message);
          return false
        }
      });
    },

    //钉钉单聊
    async checkDingChat(){
      let dingSingBindForm = this.$refs.dingSingleChatBasic.dingSingBindForm;
      if (!dingSingBindForm.corpName){
        this.$message({
          message:this.$t('botLink.enterpriseNamePla'),
          type:"warning"
        })
        return
      }
      if (!dingSingBindForm.corpId){
        this.$message({
          message:this.$t('botLink.enterpriseIDPla'),
          type:"warning"
        })
        return
      }
      if (!dingSingBindForm.agentName){
        this.$message({
          message:this.$t('botLink.appNamePla'),
          type:"warning"
        })
        return
      }
      if (!dingSingBindForm.agentId){
        this.$message({
          message:this.$t('botLink.agentIdPla'),
          type:"warning"
        })
        return
      }
      if (!dingSingBindForm.appKey){
        this.$message({
          message:this.$t('botLink.appKeyPla'),
          type:"warning"
        })
        return
      }
      if (!dingSingBindForm.secret){
        this.$message({
          message:this.$t('botLink.appSecretPla'),
          type:"warning"
        })
        return
      }
      //检查是否有必填或者字符长度检验
      let bool = true;
      this.$refs.dingSingleChatBasic.$refs['bindForm'].validate((valid) => {
        if (!valid) {
          this.$message({
            message:"请检查填写信息"
          })
          bool = false;
        }
      });
      if (!bool){
        return bool;
      }
      let obj = {
        agentId: dingSingBindForm.agentId.trim(),
        corpId: dingSingBindForm.corpId.trim(),
        corpName: dingSingBindForm.corpName.trim(),
        appSecret: dingSingBindForm.secret.trim(),
        appKey: dingSingBindForm.appKey,
        agentName: dingSingBindForm.agentName,
        isCheckAgent: false,
      };
      let url = this.requestUrl.dingdingSingBind.newSingBind;
      let ret = ''
      for (let it in obj) {
        ret += encodeURIComponent(it) + '=' + encodeURIComponent(obj[it]) + '&'
      }
      ret = ret.substring(0, ret.lastIndexOf('&'));
      url += '?' + ret;
      return await this.$http.post(url).then(async (res) => {
        if (res.data.code === "0") {
          this.$message.success(this.$t('channel.bindSuccess'));
          this.bindCode = res.data.data.bindCode;
          this.bindDetail = res.data.data;
          return true
        } else if (res.data.code === "0OPBE0006") {
          this.$refs.dingSingleChat.warningVisible = true;
          return false
        } else {
          this.$message.error(res.data.message);
          return false
        }
      });
    },
    //云之家
    async checkYunzhijia(){
      let yunzhijiaBindForm = this.$refs.cloudHomeBasic.yunzhijiaBindForm;
      if (!yunzhijiaBindForm.corpName){
        this.$message({
          message:this.$t('botLink.enterpriseNamePla'),
          type:"warning"
        })
        return
      }
      if (!yunzhijiaBindForm.corpId){
        this.$message({
          message:this.$t('botLink.EID'),
          type:"warning"
        })
        return
      }
      if (!yunzhijiaBindForm.appName){
        this.$message({
          message:this.$t('botLink.appNamePla'),
          type:"warning"
        })
        return
      }
      if (!yunzhijiaBindForm.appId){
        this.$message({
          message:this.$t('botLink.appIdPla'),
          type:"warning"
        })
        return
      }
      if (!yunzhijiaBindForm.appSecret){
        this.$message({
          message:this.$t('botLink.appSecretPla'),
          type:"warning"
        })
        return
      }
      if (!yunzhijiaBindForm.signKey){
        this.$message({
          message:this.$t('botLink.signKeyPla'),
          type:"warning"
        })
        return
      }
      //检查是否有必填或者字符长度检验
      let bool = true;
      this.$refs.cloudHomeBasic.$refs['bindForm'].validate((valid) => {
        if (!valid) {
          this.$message({
            message:"请检查填写信息"
          })
          bool = false;
        }
      });
      if (!bool){
        return bool;
      }
      let url = this.requestUrl.dingdingBind.newYunzhijiaBind;
      let params = '?appId=' + yunzhijiaBindForm.appId + '&appName=' + yunzhijiaBindForm.appName + '&appSecret=' + yunzhijiaBindForm.appSecret
          + '&contactSecret=' + '&corpId=' + yunzhijiaBindForm.corpId + '&corpName=' + yunzhijiaBindForm.corpName + '&signKey=' + yunzhijiaBindForm.signKey
      url+= params
      return await this.$http.post(url).then(res=>{
        if (res.data.code == '0'){
          this.$message.success("绑定成功");
          this.bindDetail = res.data.data;
          this.bindCode = res.data.data.bindCode
          return true
        } else {
          this.$message.warning(res.data.message)
          return false
        }
      })
    },

    //企微
    async checkWechat(){
      let bindForm = this.$refs.wechatBasic.bindForm;
      if (!bindForm.corpName){
        this.$message({
          message:this.$t('botLink.enterpriseNamePla'),
          type:"warning"
        })
        return
      }
      if (!bindForm.corpId){
        this.$message({
          message:this.$t('botLink.enterpriseIDPla'),
          type:"warning"
        })
        return
      }
      if (!bindForm.agentId){
        this.$message({
          message:this.$t('botLink.agentIdPla'),
          type:"warning"
        })
        return
      }
      if (!bindForm.secret){
        this.$message({
          message:this.$t('botLink.secretPla'),
          type:"warning"
        })
        return
      }
      //检查是否有必填或者字符长度检验
      let bool = true;
      if (this.$refs.wechatBasic && this.$refs.wechatBasic.$refs['bindForm']){
        this.$refs.wechatBasic.$refs['bindForm'].validate((valid) => {
          if (!valid) {
            this.$message({
              message:"请检查填写信息"
            })
            bool = false;
          }
        });
      }
      if (!bool){
        return bool;
      }
      let obj = {
        agentId: bindForm.agentId.trim(),
        bindType: bindForm.bindType || "",
        corpId: bindForm.corpId.trim(),
        corpName: bindForm.corpName.trim(),
        secret: bindForm.secret.trim(),
      }
      return await this.$http.get(this.requestUrl.eWechatBind.eWechatSave,{
        data:obj,
        params:obj
      }).then((res) => {
        if (res.data.code === "0") {
          this.$message.success(this.$t('channel.bindSuccess'));
          this.bindCode = res.data.data.bindCode;
          this.bindDetail = res.data.data;
          return true
        } else if (res.data.code === "0OPBE0006") {
          this.$refs.wechatBasic.warningVisible = true;
          return false
        } else {
          // this.$message.error(res.message);
          this.$message.error(this.$t('channel.bindError'));
          return false
        }
      });
    },
    async checkApp() {
        let flag = false
        if(this.appName != "" && this.appName.trim() != "") {
            flag = true
        } else {
            this.$message.warning(this.$t("channel.appBot.warningTip1"))
            flag = false
        }
        if(!flag) {
            return false
        }
        return await this.$http.post(this.requestUrl.appBind.saveApp + "?appName=" + this.appName)
        .then(async res => {
            if(res.data.code == "0") {
                this.bindCode = res.data.data.bindCode;
                await this.getBindInfo();
                return true
            }
        })
    },
    // 渠道管理删除机器人，校验机器人下所有意图是否被统一欢迎语配置使用
    checkStartNodeFn(){
      this.relationIntents = []
      let botIds = [];
      let bindBots = this.$refs.bindInformation.bindBots;
      let bindBotsCopy = this.$refs.bindInformation.bindBotsCopy;
      bindBots.forEach(newItem => {
        botIds.push(newItem.botId)
      })
      let apiKeys = [];
      bindBotsCopy.forEach(oldItem => {
        if(botIds.indexOf(oldItem.botId) === -1 || botIds.length === 0){
          apiKeys.push( oldItem.apiKey)
        }
      });
      if(apiKeys.length > 0){
        return this.$http.post(this.requestUrl.bindV2.checkStartNode + '?bindCode=' + this.bindDetail.bindCode,apiKeys).then((res) => {
          if (res.data.code === "0") {
            this.relationIntents = res.data.data;
          } else {
            this.$message.error(res.data.message);
          }
        });
      }
    },

    // 提交绑定机器人
    async submitBindBot() {
      let result = true
      let bindBots = this.$refs.bindInformation.bindBots;
      let enableKnowledgeSearch = this.$refs.bindInformation.enableKnowledgeSearch;
      let dataSetCondition = this.$refs.bindInformation.$refs.identification ?
          this.$refs.bindInformation.$refs.identification.$refs.dataSet.dataSetCondition : [];
      let pluginCondition = this.$refs.bindInformation.$refs.identification ?
          this.$refs.bindInformation.$refs.identification.pluginCondition : [];
      if (this.accessChannel !== 'askBot'){
        await this.checkStartNodeFn();
        if(this.relationIntents.length > 0) {
          this.dialogCheckDelBot = true;
          return false;
        }
        submitBindBot(this.accessChannel,this.bindDetail,bindBots,enableKnowledgeSearch,this,() => {
          this.$message.success(this.$t('channel.saveSuccess'));
        })
        /*这里是钉钉群有几个处于编辑态，点击保存时统一做保存*/
        let dingGroupTable = []
        if (this.$refs.dingGroupTable){
          dingGroupTable = this.$refs.dingGroupTable.dingGroupData;
        }
        if (dingGroupTable && dingGroupTable.length > 0){
          let promises = [];
          let saveFlag = 0;
          let saveSuccess = 0;
          for (let i=0;i<dingGroupTable.length;i++){
            let item = dingGroupTable[i];
            let refs = 'bindInformation' + item.id;
            if (item.edit){
              saveFlag += 1;
              let groupBindBots = this.$refs.dingGroupTable.$refs[refs].bindBots;
              let promise = new Promise((resolve) => {
                submitBindBot(this.accessChannel,item,groupBindBots,false,this,() => {
                  saveSuccess += 1;
                  if (typeof callback === 'function') {
                    callback();
                  } else {
                    this.$refs.bindInformation.getBotList();
                  }
                  resolve();
                })
              });
              promises.push(promise);
            }
          }
          await Promise.all(promises);
          /*等待for循环中所有事件全部执行完*/
          if (saveFlag == saveSuccess){
            if (saveFlag > 0){
              this.$refs.dingGroupTable.getDingdingGroupBindList();
            }
          }
        }
      } else {
        let enableBotRecognize = this.$refs.bindInformation.enableBotRecognize
        submitBindBot(this.accessChannel,this.bindDetail,bindBots,enableBotRecognize,this,() => {
          this.$message.success(this.$t('channel.saveSuccess'));
          if (typeof callback === 'function') {
            callback();
          } else {
            this.$refs.bindInformation.getBotList();
          }
        })
      }
      if(this.accessChannel === 'app') {
        await this.getBindInfo();
      }
      let bool = false
      if (bool && this.$refs.bindInformation.isHaveKnowledge && (this.accessChannel === 'website' || this.accessChannel === 'wechat' || this.accessChannel === 'dingding' || this.accessChannel === 'feishu' || this.accessChannel === 'askBot')){
        authoritySetConfirm(this.accessChannel,this.bindDetail,{
          bindBots,
          enableKnowledgeSearch,
          dataSetCondition,
          pluginCondition
        },this)
      }
      return result
    },
    // 网站接入/企微/钉钉/飞书的权限设置
    async authoritySetConfirm(){
      //  website 网站接入     wechat 企微    dingding 钉钉     feishu 飞书
      if(this.accessChannel === 'website' || this.accessChannel === 'wechat' || this.accessChannel === 'dingding' || this.accessChannel === 'feishu'){
        this.$http.post(this.requestUrl.bindV2.knowledgeScope,{
          "bindId": this.bindDetail.id,
          "enableKnowledgeSearch": this.$refs.bindInformation.enableKnowledgeSearch,
          "enableAISearch": this.$refs.bindInformation.enableAISearch,
          "knowledgeScopeConditions": this.$refs.bindInformation.$refs.identification ? this.$refs.bindInformation.$refs.identification.$refs.dataSet.dataSetCondition :[],
          "conditions": this.$refs.bindInformation.$refs.identification ? this.$refs.bindInformation.$refs.identification.pluginCondition : [],
          "pluginToolInfo": null
        }).then(res => {
          console.log('knowledgeScope',res)
          if (res.data.code == 0){
            this.getBindInfo();
          }
        })
      }
    },
    saveSet(){
      this.$http.post(this.requestUrl.bindV2.knowledgeScope,{
        "bindId": this.bindDetail.id,
        "enableKnowledgeSearch": this.$refs.bindInformation.enableKnowledgeSearch,
        "enableAISearch": this.$refs.bindInformation.enableAISearch,
        bindCode:this.bindCode,
        botInfos:this.$refs.bindInformation.bindBots
      }).then(res => {
        console.log('knowledgeScope',res)
        if (res.data.code == 0){
          this.$message.success('信息更新成功');
          this.getBindInfo();
        }
      })
    },
    getBindInfo(){
      let bindCode = this.bindCode;
      let url = this.requestUrl.bindV2.getInfoByBindCode + '?bindCode=' + bindCode;
      this.$http.get(url).then(res => {
        if (res.data.code == 0 && res.data.data){
          this.$set(this, 'bindDetail', res.data.data)
        }
      })
    },
    goSet(){
      if (this.accessChannel === 'wechatAccount'){
        sessionStorage.setItem('gotoFlag','config')
        this.$router.push({
          path:"/channel"
        })
        return
      }
      this.$router.push({
        path:"/accessDetails",
        query:{
          type:this.accessChannel,
          bindCode:this.bindCode,
          id:this.bindDetail.id || (this.$refs.selectBot ? this.$refs.selectBot.botId : "")
        }
      })
    },
    selfBuild(){
      this.thirdParty = true;
    },
    gotoChatFlow(item){
      let url = process.env.VUE_APP_OPEN_HOST + '/#/main/link?openDrawerType=' + item.wechatType + '&bindCode='+this.bindCode;
      window.open(url, "_blank");
    },
    changeWay(){
      this.thirdParty = !this.thirdParty;
      if (this.thirdParty){
        this.tips = this.accessChannel === 'wechat' ? '使用企业微信第三方应用' : "使用钉钉第三方应用"
      } else {
        this.tips = this.accessChannel === 'wechat' ? '使用企业微信自建应用' : "使用钉钉自建应用"
      }
    },
    goHelp(){
      this.$router.push({
        name: "help",
        params: {
          helpSrc:"https://showdoc.askbot.cn/web/#/5?page_id=41",
        },
      });
    },
    authorizationSuccess(){
      this.preStep = this.activeStep
      this.activeStep += 1;
      this.activeStepId = this.stepList[this.activeStep - 1].id;
      this.$refs.stepHeader.activeStep = this.activeStep;
      this.getBindInfo();
    },
    gotoWeb(){
      if (process.env.NODE_ENV === 'development'){
        window.open('https://app.test.askbot.cn/',"_black");
      } else {
        window.open('https://app.askbot.cn/',"_black");
      }
    },
    gotoIntelligentAgentConfig(item){
      this.$refs.bindInformation.setAgentBind(item)
    },
    getIntelligentAgentList(){
      let url = requestUrl.intelligentAgent.getList;
      url += '?keyWord=' + this.keyword + '&pageNum=' + this.pageNum + '&pageSize=' + this.pageSize;
      this.$http.get(url).then(res => {
        if (res.data.code == 0 && res.data.data){
          this.intelligentAgentList = this.intelligentAgentList.concat(res.data.data.list);
          this.total = res.data.data.total;
          this.isLastPage = res.data.data.isLastPage;
        }
      })
    },
    loadIntelligentAgent(){
      this.pageNum += 1;
      this.getIntelligentAgentList();
    },
    addIntelligentFun(){
      sessionStorage.setItem('addIntelligentAgentDialog',true);
      this.$router.push({
        path:"/channel"
      })
    },
  },
  mounted() {
    this.iframeSrc = process.env.VUE_APP_OPEN_HOST + '/#/wechatBasicInfo?formSource=wechatAccount' + '&locale=' + this.$i18n.locale;
    console.log('iframeSrc',this.iframeSrc)
    this.accessChannel = this.$route.query.type;
    if (this.accessChannel === 'miniProgram'){
      this.stepList = [
        {
          name:"选择机器人",
          id:"selectBot"
        },
        {
          name:"完成接入",
          id:"complete"
        }
      ]
    } else if (this.accessChannel === 'dingdingGroup'){
      this.stepList = [
        {
          name: "基础配置",
          id:"basic"
        },
        {
          name: "能力管理",
          id:"ability"
        },
        {
          name: "完成接入",
          id:"complete"
        }
      ]
    } else if (this.accessChannel === 'IM' || this.accessChannel === 'askBotClient'){
      this.stepList = [
        {
          name:"能力管理",
          id:"ability"
        },
        {
          name: "完成接入",
          id:"complete"
        }
      ]
    } else if (this.accessChannel === 'wechatAccount'){
      this.stepList = [
        {
          name:"基础配置",
          id:"basic"
        },
        {
          name: "完成接入",
          id:"complete"
        }
      ]
    }
    if (this.stepList.length > 0){
      this.activeStepId = this.stepList[0].id;
    }
    if (this.accessChannel === 'dingding'){
      this.tips = this.$t('channel.dingding.headerTip')
    } else if (this.accessChannel === 'wechat'){
      this.tips = this.$t('channel.wechat.headerTip')
    }
    this.isBindIntelligentAgent = this.$route.query.type === 'feishu' || this.$route.query.type === 'wechat' || this.$route.query.type === 'dingding' || this.$route.query.type === 'askBot'
    if (this.isBindIntelligentAgent){
      this.getIntelligentAgentList();
    }
    window.addEventListener('message',(event) => {
      let type = event.data;
      console.log('addEventListener',type)
      if (event === 'authorizationSuccess' || type === 'authorizationSuccess'){
        this.authSuccess = true;
        this.authorizationSuccess();
      }
    })
  }
};
</script>

<style scoped lang="less">
.accessConfiguration{
  .gr-content-container {
    position: absolute;
    left: 64px;
    width: calc(100% - 96px);
    height: calc(100% - 24px);
    padding: 12px 16px;
    min-width: 1000px;
    overflow-x: auto;
    text-align: left;
    .header-name{
      display: flex;
      align-items: center;
      .tips{
        cursor:pointer;
        color: #366aff;
      }
    }
    .access-content {
      width: 100%;
      height: calc(100% - 78px);
      overflow: hidden;
      background-color: #FFFFFF;
      padding: 20px;
      box-sizing: border-box;
      margin-top: 16px;
      .stepBg{
        width: 100%;
        margin-bottom: 20px;
      }
      .stepContainer{
            padding: 20px;
            width: 1000px;
            margin: 0 auto;
            height: calc(100% - 100px);
            overflow-y: scroll;
            box-sizing: border-box;
            /deep/.el-form-item__label{
                line-height: 24px;
                color: #616161;
                padding-bottom: 6px;
            }
            .app_basic_info {
                .app_basic_info_title {
                    margin-top: 20px;
                    margin-bottom: 10px;
                    span {
                        color: red;
                    }
                }
            }
        .addBindIntelligent{
          display: flex;
          align-items: center;
          color: #366aff;
          cursor: pointer;
          margin-top: 30px;
          .addIcon{
            flex: none;
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #366aff;
            margin-right: 10px;
            border-radius: 5px;
            i{
              color: #FFFFFF;
            }
          }
        }
      }
      .accountContainer{
        width: 100% !important;
        box-sizing: border-box;
        padding: 20px 0!important;
        .container{
          height: 100%;
        }
      }
      .stepFooter{
        width: 760px;
        margin: 0 auto;
        height: 30px;
        display: flex;
        align-items: center;
        .doc{
          padding: 0 10px;
          height: 30px;
          background: #FFFFFF;
          border-radius: 18px;
          border: 1px solid #A1B9FF;
          color: #366AFF;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          i{
            margin-right: 6px;
            font-size: 14px;
          }
        }
        .footerRight{
          display: flex;
          align-items: center;
          //justify-content: flex-end;
          //width: 200px;
          .stepBtn{
            flex: none;
            height: 30px;
            width: 80px;
            border-radius: 19px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
          .previousStep{
            background-color: #FFFFFF;
            color: #000000;
            border: 1px solid #E0E6F7;
            margin-right: 20px;
          }
          .nextStep{
            background-color: #366aff;
            color: #ffffff;
            border: 1px solid #366aff;
          }
        }
        .selfBuilt{
          background: #366AFF;
          border-radius: 19px;
          height: 30px;
          padding: 0 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #FFFFFF;
          cursor:pointer;
        }
      }
      .footer-1{
        justify-content: space-between;
      }
      .footer-2{
        justify-content: center;
      }
      .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: calc(100% - 40px);
        .tips{
          color: #616161;
          margin-bottom: 30px;
        }
        .refresh{
          display: flex;
          align-items: center;
          color: #366aff;
          //cursor: pointer;
          margin-top: 30px;
          i{
            margin-right: 4px;
          }
        }
        .feishu_tips{
          font-size: 18px;
        }
      }
      .askBotContainer{
        width: 800px;
        margin: 0 auto;
        height: calc(100% - 70px);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .tips{
          padding: 30px 0;
          font-size: 18px;
        }
        .web-btn{
          padding: 0 14px;
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 17px;
          background-color: #366aff;
          color: #FFFFFF;
          cursor:pointer;
        }
      }
    }
  }
}
.delbot-relation-popup{
  #popup-assembly-new{
    width: 790px !important;
  }
  .title {
    display: flex;
    justify-content: flex-start;
    line-height: 34px;
    height: 34px;
    padding: 6px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    color: #616161;
    line-height: 44px;
    .handle-title {
      width: 240px;
      flex: none;
      text-align: left;
    }
    .relation-title {
      width: 240px;
      flex: auto;
      text-align: left;
    }
  }
  .relation-content {
    display: flex;
    justify-content: flex-start;
    line-height: 24px;
    padding: 6px;
    .handle-name {
      flex: none;
      text-align: left;
      width: 200px;
      line-height: 20px;
      // height: 112px;
      max-height: 112px;
      background: #F6F8FD;
      border-radius: 5px;
      padding: 12px;
      font-size: 14px;
      color: #000000;
    }
    .relation-name {
      flex: auto;
      text-align: left;
      margin-left: 12px;
      width: 200px;
      // height: 112px;
      //  max-height: 112px;
      //  overflow: auto;
      background: #F6F8FD;
      border-radius: 5px;
      padding: 12px;
      padding-bottom: 0;
      font-size: 14px;
      color: #000000;
      .relation-name-cell {
        color: #366AFF;
        cursor: pointer;
        white-space: nowrap;
        margin-bottom: 10px;
        cursor: pointer;
        height: 20px;
        line-height: 20px;
      }
    }
  }
}
.el-dropdown-menu{
  overflow-y: hidden;
  padding: 0;
}
.dropdownContainer{
  max-height: 300px;
  overflow-y: scroll;
  .loadMore{
    padding: 8px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #366aff;
    cursor:pointer;
  }
  .intelligentAgentItem{
    display: flex;
    align-items: center;
    line-height:20px;
    padding: 10px;
    border-bottom: 1px solid #E4E9F2;
    width: 150px;
    cursor: pointer;
    .name-des{
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      .name{
        margin-bottom: 4px;
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 13px;
      }
      .des{
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 13px;
        color: #A9B3C6;
      }
    }
  }
}
.addIntelligent{
  height: 36px;
  min-width: 160px;
  //width: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #366aff;
  cursor:pointer;
}
</style>