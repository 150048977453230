<template>
    <div id="roleTable">
        <el-table
            :data="roleData"
            style="width: 100%"
            v-loading="loading"
            height="calc(100vh - 160px)"
        >
            <template slot="empty">
                <DefaultImage></DefaultImage>
            </template>
            <el-table-column
                prop="name"
                :label="$t('roleManage.roleName')"
                width="240px"
                :show-overflow-tooltip="true"
            >
                <template slot-scope="scope">
                    <div style="display: flex; align-items: flex-start">
                        {{ scope.row.name }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="remark"
                :label="$t('roleManage.roleDes')"
                align="left"
                :show-overflow-tooltip="true"
            >
                <template slot-scope="scope">
                    <div style="display: flex; align-items: flex-start">
                        <span
                            v-if="scope.row.remark !== ''"
                            style="
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            "
                            >{{ scope.row.remark }}</span
                        >
                        <span v-else>--</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="accountNum"
                :label="$t('roleManage.roleNum')"
                align="center"
                width="120"
            >
            </el-table-column>
            <el-table-column :label="$t('roleManage.operation')" align="center" width="100">
                <template slot-scope="scope">
                    <div style="display: flex; justify-content: flex-start">
                        <el-tooltip
                            class="item"
                            effect="dark"
                            :content="$t('roleManage.detail')"
                            placement="top"
                            ><span
                                @click="details(scope.$index, scope.row.id)"
                                class="table-handle-icon"
                                v-if="scope.row.isDefault"
                            >
                                <i class="iconfont guoran-a-16-04"></i>
                            </span>
                        </el-tooltip>

                        <span
                            @click="editRow(scope.$index, scope.row.id)"
                            class="table-handle-icon"
                            v-if="!scope.row.isDefault"
                        >
                            <el-tooltip
                                class="item"
                                effect="dark"
                                :content="$t('roleManage.edit')"
                                placement="top"
                            >
                                <span
                                    v-if="
                                        operableDeleteRole(
                                            'PORTAL_MANAGEMENT_ROLE_UPDATE'
                                        )
                                    "
                                >
                                    <i class="iconfont guoran-a-16-15"></i
                                ></span>
                            </el-tooltip>

                            <el-tooltip
                                class="item"
                                effect="dark"
                                :content="$t('roleManage.detail')"
                                placement="top"
                            >
                                <span
                                    v-if="
                                        !operableDeleteRole(
                                            'PORTAL_MANAGEMENT_ROLE_UPDATE'
                                        )
                                    "
                                    ><i class="iconfont guoran-a-16-04"></i>
                                </span>
                            </el-tooltip>
                        </span>
                        <el-tooltip
                            class="item"
                            effect="dark"
                            :content="$t('roleManage.delete')"
                            placement="top"
                        >
                            <span
                                @click="deleteRow(scope.row.id, scope.row.name, scope.row)"
                                class="table-handle-icon"
                                v-if="
                                    !scope.row.isDefault &&
                                    operableDeleteRole(
                                        'PORTAL_MANAGEMENT_ROLE_DELETE'
                                    )
                                "
                            >
                            <!-- &&
                                    scope.row.accountNum === 0 -->
                                <i class="iconfont guoran-a-18-13"></i>
                            </span>
                        </el-tooltip>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div v-if="roleData.length != 0" class="footer-pagination">
            <pagination
                :pageSize="pageSize"
                :currentPage="currentPage"
                :total="roleTableTotal"
                @currentChange="handleCurrentChange"
            ></pagination>
        </div>
        <delete-dialog @closeEvent="dialogVisible = false" v-if="dialogVisible">
            <div slot="popup-container" class="popup-container">
                <div class="delete-title">{{$t('roleManage.deleteTitle')}}</div>
                <span style="text-align: left"
                    >{{$t('roleManage.deleteTip')}}
                    <span style="padding-left: 4px; font-weight: 600">{{
                        roleName
                    }}</span></span
                >
            </div>
            <div slot="dialog-footer">
                <el-button @click="dialogVisible = false" size="small" plain
                    >{{$t('common.cancel')}}</el-button
                >
                <el-button
                    @click="deleteRole"
                    style="background-color: #fe5965; color: white"
                    size="small"
                    >{{$t('common.delete')}}</el-button
                >
            </div>
        </delete-dialog>
    </div>
</template>

<script>
import { operable } from "../../js/operable";
import pagination from "./../../../../components/pagination.vue";
import DeleteDialog from "./../../../../components/deleteDialogue.vue";
import DefaultImage from "@/components/DefaultImage.vue";

export default {
    name: "roleTable",
    components: { pagination, DeleteDialog, DefaultImage },
    data() {
        return {
            roleData: [],
            dialogVisible: false,
            roleName: "",
            roleTableTotal: 20,
            currentPage: 1,
            pageSize: 20,
            roleId: 0,
            rolePrivilegesList: [],
            loading: false,
        };
    },
    mounted() {
        this.getRoles();
        this.rolePrivilegesList = this.getRolePrivileges();
    },
    computed: {
        operableDeleteRole() {
            return function (type) {
                return operable(type);
            };
        },
    },
    methods: {
        //获取所有角色
        getRoles() {
            this.loading = true;
            let url =
                "api/role/page?pageSize=" +
                this.pageSize +
                "&pageNo=" +
                this.currentPage;
            this.$http.get(url).then((res) => {
                if (res.data.code === "0") {
                    this.roleData = res.data.data.list;
                    this.roleTableTotal = res.data.data.total;
                }
                this.loading = false;
            });
        },
        details(index, id) {
            this.$router.push({
                path: "/_addRole",
                query: {
                    type: "details",
                    id: id,
                },
            });
        },
        editRow(index, id) {
            this.$router.push({
                path: "_addRole",
                query: {
                    type: "edit",
                    id: id,
                },
            });
        },
        deleteRow(id, name, row) {
            if(row.accountNum !== 0) {
                this.$message.warning(this.$t('roleManage.notDelete'))
                return
            }
            this.roleId = id;
            this.dialogVisible = true;
            this.roleName = name;
        },
        handleSizeChange(value) {
            this.pageSize = value;
            this.getRoles();
        },
        handleCurrentChange(value) {
            this.currentPage = value;
            this.getRoles();
        },
        deleteRole() {
            let url = "/api/role/" + this.roleId;
            this.$http.delete(url).then((res) => {
                if (res.data.code === "0") {
                    this.$message({
                        message: this.$t('roleManage.deleteSuccess'),
                        type: "success",
                        duration: 2000,
                    });
                    this.getRoles();
                }
            });
            this.dialogVisible = false;
        },
    },
};
</script>

<style lang="less">
#roleTable{
    .el-table th > .cell {
        padding-left: 20px !important;
    }

    .el-table .cell {
        padding-left: 20px !important;
    }
}
.el-table {
    color: #000000 !important;
    padding-left: 0 !important;
}


thead {
    background-color: #f6f8fd !important;

    .el-dropdown {
        color: #000000;
        font-weight: 400;
    }

    tr {
        background-color: #f6f8fd !important;

        th {
            height: 58px;
            background-color: #f6f8fd !important;
            color: #000000;
            font-weight: 400;
        }
    }
}

.table-handle-icon-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-right: 38px;
}

.table-handle-icon {
    font-size: 18px;
    display: inline-block;
    height: 20px;
    flex: none;
    width: 27px;
    line-height: 20px;
    text-align: center;
    color: #366aff;
    cursor: pointer;
    font-weight: 400;
}
</style>