<template>
    <div class="forget-password">
        <div class="register">
            <div  :class="[isE()?'gr-register-left-ebot':'gr-register-left']"></div>
            <div class="gr-register-right">
                <div class="gr-register-right-c">
                    <div  :class="[isE()?'gr-register-logo-ebot':'gr-register-logo']"></div>
                    <div class="gr-register-title">重置密码</div>
                    <div class="gr-register-form-t" id="form">
                        <div class="form-item">
                            <div class="label">手机号码</div>
                            <input
                                id="inputPhone"
                                v-model="phone"
                                type="number"
                                @input="changephone"
                                placeholder="请输入您的手机号"
                            />
                            <div class="gr-tips">{{ phoneTips }}</div>
                        </div>
                        <div class="form-item">
                            <div class="label">邮箱</div>
                            <input
                                id="inputPhone"
                                v-model="email"
                                @input="changeEmail"
                                placeholder="请输入您的邮箱"
                            />
                            <div class="gr-tips">{{ emailTips }}</div>
                        </div>

                        <div class="form-item">
                            <div class="label">密码</div>
                            <input
                                type="password"
                                id="inputPassword"
                                v-model="newPassword"
                                :onkeyup="
                                    (newPassword = newPassword.replace(
                                        /\s+/g,
                                        ''
                                    ))
                                "
                                @input="changeNewPassword"
                                placeholder="请输入密码"
                            />
                            <div class="gr-tips">{{ newPasswordTips }}</div>
                        </div>
                        <div class="form-item">
                            <div class="label">确认密码</div>
                            <input
                                type="password"
                                v-model="repeatNewPassword"
                                :onkeyup="
                                    (repeatNewPassword =
                                        repeatNewPassword.replace(/\s+/g, ''))
                                "
                                @input="changeRepeatNewPassword"
                                id="inputpasswordAgain"
                                placeholder="请再次输入密码"
                            />
                            <div class="gr-tips">
                                {{ repeatNewPasswordTips }}
                            </div>
                        </div>
                        <div class="form-item gr-register-btn">
                            <button @click="register" id="getBackNow">
                                重置密码
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { redirectUri } from "../../const/redirectUri";
export default {
    data() {
        return {
            verifyTime: 60,
            verifyName: "获取验证码",
            phone: "",
            phoneTips: "",
            email: "",
            emailTips: "",
            checkCode: "",
            checkCodeTips: "",
            newPassword: "",
            newPasswordTips: "",
            repeatNewPassword: "",
            repeatNewPasswordTips: "",
            verifyIng: false,
            currentIp: "",
            loginUrl: redirectUri.sso,
        };
    },
    computed: {},
    mounted() {
        this.getCurrentIp();
        let mainId = "14676a0cb2ce11ea8f0900163e0964d5";
        if (window.location.host.indexOf("test") != -1) {
            mainId = "6cdfcac6a7d611eabdcd506b4b2f3acc";
        }
        if (
            this.$route.query.source === "official_website" &&
            this.$route.query.tel
        ) {
            this.phone = this.$route.query.tel.trim();
            this.$http_ignore_auth
                .post(
                    "/scrm-api/custom-clue",
                    {
                        source: "UNKNOW",
                        name: "官网推送",
                        mobile: this.phone,
                    },
                    { headers: { uid: "0", mainId: mainId } }
                )
                .then((res) => {});
        }
    },
    methods: {
        changephone(val) {
            if (this.phone.trim() !== "") {
                this.phoneTips = "";
            }
        },

        changeEmail() {
            if (this.email.trim() !== "") {
                this.emailTips = "";
            }
        },

        changeNewPassword() {
            if (this.newPassword.trim() !== "") {
                this.newPasswordTips = "";
            }
        },

        changeRepeatNewPassword() {
            if (this.repeatNewPassword.trim() !== "") {
                this.repeatNewPasswordTips = "";
            }
        },

        changeCheckCode() {
            if (this.checkCode.trim() !== "") {
                this.checkCodeTips = "";
            }
        },

        getCurrentIp() {
            this.$http_ignore_auth
                .get("https://www.askbot.cn/ext-api/ip")
                .then((res) => {
                    this.currentIp = res.data.ip;
                });
        },
        register() {
            if (this.phone.trim() === "") {
                this.phoneTips = "账号不能为空！";
                return false;
            }

            if (this.email.trim() === "") {
                this.emailTips = "邮箱不能为空！";
                return false;
            }

            if (this.newPassword.trim() === "") {
                this.newPasswordTips = "请输入密码！";
                return false;
            }
            if (this.repeatNewPassword === "") {
                this.repeatNewPasswordTips = "请再次输入密码！";
                return false;
            }
            if (this.newPassword.trim() !== this.repeatNewPassword.trim()) {
                this.repeatNewPasswordTips = "两次输入密码不一致，请确认！";
                return false;
            }

            this.$message.success("重置成功");
        },
        getCheckcode() {
            if (this.phone.trim() === "") {
                this.phoneTips = "手机号不能为空！";
                return false;
            } else {
                let tel_reg =
                    /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;

                if (!tel_reg.test(this.phone.trim())) {
                    this.phoneTips = "请检查手机号码是否正确";
                    return false;
                }
            }
            this.$http_ignore_auth
                .get("/api/register/check?phone=" + this.phone.trim())
                .then((res) => {
                    console.log(res);
                    if (res.data.code === "0") {
                        if (res.data != null && res.data.data) {
                            this.phoneTips = "手机号已经存在";
                            return false;
                        } else {
                            this.$http_ignore_auth
                                .post(
                                    "/api/code/sms/send?phone=" +
                                        this.phone.trim()
                                )
                                .then((res) => {
                                    if (res.data.code != 0) {
                                        this.$message.error(res.data.message);
                                    } else {
                                        this.verifyIng = true;
                                        this.verifyTime = 60;
                                        let timer = setInterval(() => {
                                            this.verifyTime =
                                                this.verifyTime - 1;
                                            if (this.verifyTime == 0) {
                                                this.verifyName = "重新获取";
                                                this.verifyIng = false;
                                                clearInterval(timer);
                                            }
                                        }, 1000);
                                    }
                                });
                        }
                    }
                });
        },
    },
};
</script>
<style lang="less">
.register {
    width: 100%;
    display: flex;
    height: 100vh;
    background-color: white;
    font-family: "Arial", "Microsoft YaHei", "黑体", "宋体", sans-serif;
}

.exit-acc {
    margin-top: -32px;
    text-align: left;
    a {
        color: #3682ff;
    }
}

.gr-tips {
    color: #f56c6c;
    text-align: right;
    font-size: 11px;
}

@media screen and (max-width: 960px) {
    .gr-register-left {
        display: none;
    }

    .register {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.gr-register-left {
    width: 62vw;
    background: #f7f9ff;
    text-align: center;
    background-image: url("https://guoranopen-zjk.oss-cn-zhangjiakou.aliyuncs.com/cdn-office-website/images/login_newui.png");
    background-repeat: no-repeat;
    background-size: 70% auto;
    background-position: center center;
}

.gr-register-left-ebot {
    width: 62vw;
    background: #f7f9ff;
    text-align: center;
    background-image: url("https://guoranopen-zjk.oss-cn-zhangjiakou.aliyuncs.com/cdn-office-website/images/login_newui_eBot.png");
    background-repeat: no-repeat;
    background-size: 70% auto;
    background-position: center center;
}

.gr-register-right {
    width: 38vw;
    min-width: 556px;
    height: 370px;
    margin: auto 0;
}

.gr-register-right-c {
    margin-top: -100px;
}

.gr-register-logo {
    width: 100%;
    height: 46px;
    background-image: url("https://guoranopen-zjk.oss-cn-zhangjiakou.aliyuncs.com/cdn-office-website/images/logo_newui.png");
    background-repeat: no-repeat;
    background-size: auto 46px;
    background-position: center center;
}

.gr-register-logo-ebot {
    width: 100%;
    height: 46px;
    background-image: url("https://guoranopen-zjk.oss-cn-zhangjiakou.aliyuncs.com/cdn-office-website/images/logo_newui_eBot.png");
    background-repeat: no-repeat;
    background-size: auto 46px;
    background-position: center center;
}

.gr-register-title {
    margin-top: 15px;
    font-weight: 400;
    text-align: center;
    line-height: 14px;
    height: 14px;
}

.gr-register-form-t {
    max-width: 260px;
    min-width: 260px;
    margin: 30px auto 0;
}

.gr-register-form-t .form-item {
    width: 100%;
    height: 79px;
    .el-select {
        width: 100%;
        input {
            width: 100%;
        }
    }

    .form-input-cell {
        display: flex;
        justify-content: space-between;
        input {
            flex: auto;
            border-radius: 5px 0 0 5px;
        }
        .varify-btn {
            height: 38px;
            line-height: 38px;
            width: 140px;
            text-align: center;
            background: #3682ff;
            color: white;
            font-size: 12px;
            border-radius: 0 5px 5px 0;
            font-weight: 700;
            cursor: pointer;
        }
        .varify-time {
            height: 38px;
            line-height: 38px;
            width: 140px;
            text-align: center;
            background: #3682ff;
            color: white;
            font-size: 12px;
            border-radius: 0 5px 5px 0;
            font-weight: 700;
        }
    }
}

.gr-register-form-t .form-item input {
    width: calc(100% - 30px);
    outline-style: none;
    border: 1px solid #e0e6f7;
    border-radius: 5px;
    padding: 10px 14px;
    font-size: 14px;
}

.gr-register-form-t .form-item .label {
    margin-bottom: 7px;
    font-size: 14px;
    font-weight: 500;
    color: #616161;
    line-height: 14px;
    text-align: left;
}

input::-webkit-input-placeholder {
    color: #a9b3c6 !important;
    font-weight: 500;
}

input::-moz-placeholder {
    color: #a9b3c6 !important;
    font-weight: 500;
}

input:-ms-input-placeholder {
    color: #a9b3c6 !important;
    font-weight: 500;
}

input:focus {
    border-color: #366aff;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.015), 0 0 4px #366aff;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.015), 0 0 4px #366aff;
}

.gr-register-btn {
    padding-top: 18px;
}

.gr-register-btn button {
    width: 100%;
    height: 38px;
    background: #3682ff;
    border-radius: 5px;
    color: white;
    border: none;
    cursor: pointer;
}

.gr-register-btn button:hover {
    background-color: #366aff;
}

.gr-register-login {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    font-weight: 500;
}
</style>