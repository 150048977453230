var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"staff-customer"},[_c('div',{staticClass:"staff-customer-input"},[_c('el-input',{ref:"serchInput",attrs:{"placeholder":_vm.$t('common.search'),"size":"small"},on:{"input":_vm.monitorSearchNull},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.searchEmployee.apply(null, arguments)}},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}})],1),(_vm.corpList.length > 1)?_c('el-select',{staticStyle:{"width":"100%","margin-bottom":"10px"},attrs:{"placeholder":_vm.$t('common.selectPlaceholder'),"size":"small"},model:{value:(_vm.corpId),callback:function ($$v) {_vm.corpId=$$v},expression:"corpId"}},_vm._l((_vm.corpList),function(item){return _c('el-option',{key:item.originData.corpId,attrs:{"label":item.label,"value":item.originData.corpId}})}),1):_vm._e(),_vm._l((_vm.treeData),function(item){return _c('div',[_c('el-tree',{key:"selectOrganizationTreeLazy",ref:item.id + 'TreeLazy',refInFor:true,class:['filter-tree', item.id + 'TreeLazy'],attrs:{"data":[item],"id":"selectOrganizationTreeLazy","node-key":"id","default-expanded-keys":_vm.defaultExpandedKeysMember,"expand-on-click-node":false},scopedSlots:_vm._u([{key:"default",fn:function({node,data}){return _c('span',{staticClass:"custom-tree-node",on:{"click":function($event){return _vm.onNodeClick(data, '', item, node)}}},[_c('span',{staticClass:"custom-tree-node-left"},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(data.type == 1),expression:"data.type == 1"}],class:['el-icon-caret-right', data.showChildren ? 'down' : 'right'],on:{"click":function($event){$event.stopPropagation();return _vm.nodeExpand(data, node, item)}}}),(data.isWeWorkThirdData ||
            item.isWeWorkThirdData ||
            (item.originData &&
              item.originData.wechatBotDTO &&
              item.originData.wechatBotDTO.bindType === 0 &&
              item.originData.wechatBotDTO.wechatType === 0))?_c('span',{staticClass:"data-label"},[_c('open-data',{attrs:{"type":data.type == 1 ? 'departmentName' : 'userName',"openid":data.label}})],1):_c('span',{class:['data-label', data.dataType == 'hasNext' ? 'load-more-node' : '']},[_vm._v(_vm._s(data.label))]),(data.originData && data.originData.departments && _vm.keyword)?[_c('span',[_vm._v(" ")]),_vm._l((data.originData.departments),function(cell,index){return _c('div',{key:index},[_c('el-tooltip',{attrs:{"placement":"top","effect":"light","popper-class":"staff-costomer-popper"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" ( "),(cell.source === 1 &&
                    (data.isWeWorkThirdData || item.isWeWorkThirdData))?_c('span',[_c('open-data',{attrs:{"type":'departmentName',"openid":cell.fullName}})],1):_c('span',{domProps:{"innerHTML":_vm._s(cell.fullName)}}),_vm._v(" ) ")]),(cell.source === 1 &&
                  (data.isWeWorkThirdData || item.isWeWorkThirdData))?_c('span',[_vm._v("( "),_c('open-data',{attrs:{"type":'departmentName',"openid":cell.name}}),_vm._v(" ) ")],1):_c('span',[_vm._v("("+_vm._s(cell.name)+")")])])],1)})]:_vm._e()],2),_c('span',{directives:[{name:"show",rawName:"v-show",value:(data.checked),expression:"data.checked"}]},[_c('i',{class:['el-icon-check']})])])}}],null,true)})],1)}),(_vm.keyword && _vm.staffHasNext)?_c('span',{staticClass:"hasNextPage",on:{"click":_vm.loadStaffMore}},[_vm._v(" "+_vm._s(_vm.$t('common.loadMore'))+" ")]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }