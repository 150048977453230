<!---流量/容量申请-->
<template>
    <div class="capacity-flow-apply">
        <el-drawer custom-class="capacity-flow-apply-drawer" :visible.sync="applyVisible" :with-header="false"
            :destroy-on-close="true" :size="'750px'" :wrapperClosable="false" @close="closeDrawer">
            <div class="add-member-title">
                <h4>{{ headerName }}</h4>
                <i class="el-icon-close" @click="closeDrawer"></i>
            </div>
            <div class="drawer-content">
                <div class="search-box">
                    <el-input v-model="keyword" @change="getDetail"
                        :placeholder="$t('capacityFlowManage.deptPlaceholder')" prefix-icon="el-icon-search"></el-input>
                    <el-select v-model="sortValue" multiple :placeholder="$t('common.selectPlaceholder')"
                        @change="changeSortValue">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <el-table :data="tableData" :row-key="row => row.id" :expand-row-keys="expandRowKeys"
                    :empty-text="$t('common.emptyData')" class="table-box">
                    <el-table-column type="expand" class-name="expand-cell">
                        <template slot-scope="scope">
                            <div class="description">{{ scope.row.description }} </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="userName" align="left" :show-overflow-tooltip="true"
                        :width="pageType === 'flow' ? 170 : 250" class-name="user-name-cell"
                        :label="$t('capacityFlowManage.applicant')">
                        <template slot-scope="scope">
                            <template v-if="scope.row.userName">
                                <el-image v-if="scope.row.profilePhoto && scope.row.profilePhoto !== ''"
                                    class="user-icon profilePhoto" :src="scope.row.profilePhoto" :fit="'container'">
                                </el-image>
                                <div v-else class="user-icon">
                                    <span
                                        v-if="!scope.row.ewechat">{{ scope.row.userName.slice(1, scope.row.userName.length + 1) }}</span>
                                    <i v-else class="iconfont guoran-a-18-32"></i>
                                </div>
                                <div class="user-name">
                                    <el-tooltip class="item" :open-delay="500" effect="dark"
                                        :content="scope.row.userName" placement="top-end">
                                        <div class="one-column-ellipsis" v-if="!scope.row.ewechat">
                                            {{ scope.row.userName }}</div>
                                        <open-data v-else :type="'userName'" :openid="scope.row.userName"></open-data>
                                    </el-tooltip>
                                    <div class="dept one-column-ellipsis">
                                        <span v-for="(item, index) in scope.row.departmentNameList" :key="index">
                                            <el-tooltip placement="top" effect="light">
                                                <div slot="content">
                                                    <span v-if="!scope.row.ewechat">{{ item }}</span>
                                                    <open-data v-else :type="'departmentName'"
                                                        :openid="item"></open-data>
                                                </div>
                                                <span v-if="!scope.row.ewechat">{{ item }}</span>
                                                <open-data v-else :type="'departmentName'" :openid="item"></open-data>
                                                <span v-if="index + 1 < scope.row.departmentNameList.length">/</span>
                                            </el-tooltip>
                                        </span>
                                    </div>
                                </div>
                            </template>
                            <span v-else>--</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="time" width="150" align="left"
                        :label="$t('capacityFlowManage.applicationTime')">
                    </el-table-column>
                    <el-table-column class-name="show-num" v-if="pageType === 'capacity'" prop="total" align="left"
                        :label="$t('capacityFlowManage.totalCapacity')">
                        <template slot-scope="scope">
                            <span>{{ scope.row.useCompanyCapacityCopy }}/{{ scope.row.totalCapacityCopy }}</span>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column
                        v-if="pageType === 'capacity'"
                        class-name="show-num"
                        prop="total"
                        align="left"
                        label="企业容量">
                        <template slot-scope="scope">
                            <span >{{scope.row.useCompanyCapacityCopy}}/{{scope.row.companyCapacityTotalCopy}}</span>
                        </template>
                    </el-table-column> -->
                    <!-- <el-table-column
                        v-if="pageType === 'capacity'" 
                        class-name="show-num" 
                        prop="total"
                        align="left"
                        label="自购容量">
                        <template slot-scope="scope">
                            <span >{{scope.row.useUserBuyCapacityCopy}}/{{scope.row.userBuyCapacityCopy}}</span>
                        </template>
                    </el-table-column> -->
                    <el-table-column v-if="pageType === 'flow'" class-name="show-num" prop="total" align="left"
                        :width="$i18n.locale === 'en' ? '200' : ''"
                        :label="$t('capacityFlowManage.numberOfEnterprises')">
                        <template slot-scope="scope">
                            <!--   无限制 -1     自定义 -2    不可用 -3 -->
                            <span
                                v-if="scope.row.companyFlow == -1">{{ scope.row.useCompanyFlow }}{{ $t('capacityFlowManage.unrestricted') }}</span>
                            <span
                                v-else-if="scope.row.companyFlow == -3">{{ scope.row.useCompanyFlow }}{{ $t('capacityFlowManage.notAvailable') }}</span>
                            <span
                                v-else>{{ scope.row.useCompanyFlow }}{{ $t('capacityFlowManage.second') }}/{{ scope.row.companyFlow }}{{ $t('capacityFlowManage.second') }}{{ scope.row.companyFlowMonthRefresh
                                    ? $t('capacityFlowManage.Month') : ''}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column v-if="pageType === 'flow'" class-name="show-num" prop="total"
                        :width="$i18n.locale === 'en' ? '180' : '120'" align="center"
                        :label="$t('capacityFlowManage.surplus')">
                        <template slot-scope="scope">
                            <span>{{ scope.row.userBuyFlow }}{{ $t('capacityFlowManage.second') }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('common.operation')" align="left" class-name="operation-cell"
                        :width="$i18n.locale === 'en' ? '130' : '110'">
                        <template slot-scope="scope">
                            <!-- approveStatus 审批状态，刚创建时为null，显示同意，拒绝操作，同意操作为true，拒绝false -->
                            <template v-if="JSON.stringify(scope.row.approveStatus) == 'null'">
                                <div class="agree" @click="agree(scope.row)">
                                    <i class="el-icon-check"></i>
                                    <span class="tips">{{ $t('capacityFlowManage.agree') }}</span>
                                </div>
                                <div class="refuse" @click="refuse(scope.row)">
                                    <i class="el-icon-close"></i>
                                    <span class="tips">{{ $t('capacityFlowManage.refuse') }}</span>
                                </div>
                            </template>
                            <span v-else-if="scope.row.approveStatus" class="expanded-text">{{ pageType === 'capacity' ?
                                $t('capacityFlowManage.expanded') : $t('capacityFlowManage.increasedFrequency')}}</span>
                            <span v-else class="refuse-text">{{ $t('capacityFlowManage.rejected') }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <pagination :pageSize="pageSize" :currentPage="currentPage" :total="total"
                @handleSizeChange="handleSizeChange" @currentChange="handleCurrentChange">
            </pagination>
        </el-drawer>
        <popup @closeEvent="closeEvent" v-if="agreePopup">
            <div slot="popup-name" class="popup-name">{{ pageType === 'capacity' ? $t('capacityFlowManage.agreeToExpand')
                :
                $t('capacityFlowManage.agreeIncreasedFrequency')}}</div>
            <div slot="popup-tip">{{ pageType === 'capacity' ? $t('capacityFlowManage.agreeToExpandTip') :
                $t('capacityFlowManage.agreeIncreasedFrequencyTip')}}</div>
            <div slot="popup-con">
                <el-form :model="ruleForm" ref="ruleForm" class="demo-ruleForm" label-width="70px">
                    <el-form-item :label="$t('capacityFlowManage.applicant')">
                        <span>{{ activeData.userName }}</span>
                    </el-form-item>
                    <el-form-item :label="$t('capacityFlowManage.currentStatus')">
                        <template v-if="pageType === 'flow'">
                            <!--   无限制 -1     自定义 -2    不可用 -3 -->
                            <span
                                v-if="activeData.companyFlow == -1">{{ activeData.useCompanyFlow }}{{ $t('capacityFlowManage.unrestricted') }}</span>
                            <span
                                v-else-if="activeData.companyFlow == -3">{{ activeData.useCompanyFlow }}{{ $t('capacityFlowManage.notAvailable') }}</span>
                            <span
                                v-else>{{ activeData.useCompanyFlow }}次/{{ activeData.companyFlow }}次{{ activeData.companyFlowMonthRefresh
                                    ? $t('capacityFlowManage.Month') : ''}}</span>
                        </template>
                        <template v-else>
                            <span>{{ activeData.useCompanyCapacityCopy }}/{{ activeData.companyCapacityTotalCopy }}</span>
                        </template>
                    </el-form-item>
                    <el-form-item
                        :label="pageType === 'capacity' ? $t('capacityFlowManage.capacity') : $t('capacityFlowManage.frequency')">
                        <el-select v-model="ruleForm.custom" @change="changeCustom"
                            :placeholder="$t('common.selectPlaceholder')" size="small">
                            <el-option v-for="item in agreePopupOptions" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                        <template v-if="ruleForm.custom == -2">
                            <el-input v-model="ruleForm.value" class="input-demo" type="number"
                                :placeholder="$t('common.selectPlaceholder')" size="small"></el-input>
                            <el-select v-model="ruleForm.unit" :placeholder="$t('common.selectPlaceholder')"
                                size="small">
                                <el-option v-for="item in unitOptions" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </template>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="dialog-footer">
                <el-button @click="closeEvent" plain round>{{ $t('common.cancel') }}</el-button>
                <el-button type="primary" @click="submitForm" round>{{ $t('common.save') }}</el-button>
            </div>
        </popup>
    </div>
</template>

<script>
import popup from '../../../../components/popup.vue';
import pagination from "../../../../components/pagination.vue";
import { capacityOption, flowOption, capacityUnitOption, flowUnitOption, getDataTime } from './data'
export default {
    components: { popup, pagination },
    data() {
        return {
            headerName: '',
            keyword: '',
            sortValue: [1],
            options: [{
                value: 1,
                label: ""
            }, {
                value: 0,
                label: ""
            }, {
                value: 2,
                label: ""
            }],
            tableData: [],
            agreePopup: false,
            ruleForm: {
                custom: "",
                value: '',
                unit: "",
            },
            agreePopupOptions: [],
            pageSize: 15,
            currentPage: 1,
            total: 0,
            activeData: {},
            expandRowKeys: []

        }
    },
    props: {
        applyVisible: {
            typeof: Boolean,
            default: false
        },
        pageType: {
            type: String,
            default: ""
        },
        corpId: {
            type: String,
            default: ""
        }
    },
    watch: {
        applyVisible(n) {
            if (n) {
                if (this.pageType === 'capacity') {
                    this.headerName = this.$t('capacityFlowManage.capacityApplication');
                    this.options[2].label = this.$t('capacityFlowManage.capacityExhausted');
                    this.agreePopupOptions = capacityOption;
                    this.unitOptions = capacityUnitOption;
                } else {
                    this.headerName = this.$t('capacityFlowManage.trafficApplication');
                    this.options[2].label = this.$t('capacityFlowManage.attempts');
                    this.agreePopupOptions = flowOption;
                    this.unitOptions = flowUnitOption;
                }
                this.getDetail();
            }
        }
    },
    created() {
        this.options[0].label = this.$t('capacityFlowManage.reverseOrder');
        this.options[1].label = this.$t('capacityFlowManage.ascendingOrder');
        this.options[2].label = this.$t('capacityFlowManage.attempts');
    },
    methods: {
        // 分页器切换每页条数
        handleSizeChange(data) {
            this.pageSize = data;
            this.getDetail();
        },
        // 分页器切换页码
        handleCurrentChange(data) {
            this.currentPage = data;
            this.getDetail();
        },
        changeSortValue(value) {
            // 0 升序  1  倒序  2 置顶
            if (this.sortValue.indexOf(0) !== -1 && this.sortValue.indexOf(1) !== -1) {
                this.sortValue.splice(this.sortValue[this.sortValue.length - 1] === 0 ? this.sortValue.indexOf(1) : this.sortValue.indexOf(0), 1)
            }
            this.getDetail();
        },
        getDetail() {
            let params = {
                "page": this.currentPage, // 页码
                "size": this.pageSize, // 每页条数
                "mainId": localStorage.getItem('_mainId'),
                "corpId": this.corpId,
                "keyword": this.keyword, // 搜索关键词
                "sort": 1, // 0 申请时间升序，1 申请时间降序  默认给1
                "exhaustTop": false // 耗尽置顶 true  不置顶false  默认false
            }
            // // 0 升序  1  倒序  2 置顶
            let sortList = JSON.parse(JSON.stringify(this.sortValue));
            if (sortList.indexOf(2) !== -1) {
                params.exhaustTop = true;
                sortList.splice(this.sortValue.indexOf(2), 1)
            }
            if (sortList.length === 0) {
                params.sort = 1;
            } else {
                if (sortList.indexOf(2) === -1) {
                    params.sort = sortList[0]
                }
            }
            this.expandRowKeys = [];
            let url = this.pageType === 'capacity' ? this.requestUrl.knowledge.selectCapacityApply : this.requestUrl.knowledge.selectFlowApply;
            this.$http.post(url, params).then((res) => {
                if (res.data && res.data.list) {
                    res.data.list.forEach((item, index) => {
                        if (item.description !== '') {
                            this.expandRowKeys.push(item.id)
                        }
                        item.departmentNameList = JSON.parse(item.departmentName);
                        item.time = getDataTime(item.applyTime);
                        if (this.pageType === 'capacity') {
                            item.useCompanyCapacityCopy = this.handleCapacity(item.useCompanyCapacityCopy, item.useCompanyCapacity);
                            if (item.totalCapacity == -1 || item.totalCapacity == -3) {
                                item.totalCapacityCopy = item.totalCapacity == -1 ? '无限制' : '不可用'
                            } else {
                                item.totalCapacityCopy = this.handleCapacity(item.totalCapacityCopy, item.totalCapacity);
                            }
                            item.useCompanyCapacityCopy = this.handleCapacity(item.useCompanyCapacityCopy, item.useCompanyCapacity);
                            if (item.companyCapacityTotal == -1 || item.companyCapacityTotal == -3) {
                                item.companyCapacityTotalCopy = item.companyCapacityTotal == -1 ? '无限制' : '不可用'
                            } else {
                                item.companyCapacityTotalCopy = this.handleCapacity(item.companyCapacityTotalCopy, item.companyCapacityTotal);
                            }
                            item.useUserBuyCapacityCopy = this.handleCapacity(item.useUserBuyCapacityCopy, item.useUserBuyCapacity);
                            item.userBuyCapacityCopy = this.handleCapacity(item.userBuyCapacityCopy, item.userBuyCapacity);
                        }
                    });
                    this.tableData = res.data.list;
                    this.total = res.data.total;
                    this.$forceUpdate()
                } else {
                    this.messageFn('error', '网络异常,请稍后重试');
                }
            })

        },
        handleCapacity(newVlaue, oldValue) {
            let value = (oldValue / 1024 / 1024 / 1024).toFixed(1);
            if (value >= 1) {
                newVlaue = (oldValue / 1024 / 1024 / 1024).toFixed(1) + 'GB';
            } else {
                newVlaue = (oldValue / 1024 / 1024).toFixed(1) + 'MB';
            }
            return newVlaue;
        },
        closeDrawer() {
            this.$emit('closeApplyDrawer')
            this.$emit('update:applyVisible', false)
        },
        agree(data) {
            console.log(data);
            this.activeData = data;
            this.$set(this.ruleForm, 'custom', '');
            this.$set(this.ruleForm, 'value', '')
            this.$set(this.ruleForm, 'unit', '')
            console.log(this.ruleForm);
            this.agreePopup = true;
        },
        refuse(data) {
            console.log(data);
            this.activeData = data;
            this.submitForm('refuse');
        },
        closeEvent() {
            this.agreePopup = false;
        },
        changeCustom(value) {
            this.ruleForm.unit = this.pageType === 'capacity' ? 'GB' : '次'
            this.$forceUpdate()
        },
        submitForm(type) {
            console.log(this.ruleForm, 'this.ruleForm');
            let url = this.pageType === 'capacity' ? this.requestUrl.knowledge.approveCapacityApply : this.requestUrl.knowledge.approveFlowApply;
            let flag = true;
            let agreeDilatationCompanyFlow = 0, agreeCompanyFlowMonthRefresh = false, oldValue = 0, newValue = 0, agreeDilatationCompanyCapacity = 0;
            if (type && type === 'refuse') {

            } else {
                // 无限制 -1     自定义 -2    不可用 -3  其他 : 选择下拉框中已定义的值
                if (this.pageType === 'capacity') {
                    oldValue = this.activeData.companyCapacityTotal;
                    if (oldValue == -1 && this.ruleForm.custom != -1) {
                        this.messageFn('warning', '原来次数为无限制,只能再次设置为无限制');
                        flag = false;
                        return false
                    }
                    if (flag) {
                        let value = 0, unit = '';
                        if (this.ruleForm.custom == -1) {
                            agreeDilatationCompanyCapacity = this.ruleForm.custom
                        } else {
                            if (this.ruleForm.custom == -2) {
                                value = this.ruleForm.value;
                                unit = this.ruleForm.unit
                            } else {
                                value = Number(this.ruleForm.custom.replace(this.ruleForm.custom.indexOf('MB') !== -1 ? 'MB' : 'GB', ''));
                                unit = this.ruleForm.custom.indexOf('MB') !== -1 ? 'MB' : 'GB'
                            }
                            if (unit === 'MB') {
                                newValue = value * 1024 * 1024;
                            } else {
                                newValue = value * 1024 * 1024 * 1024;
                            }
                            console.log(oldValue, newValue, '对比');
                            if (newValue <= oldValue) {
                                this.messageFn('warning', '不允许设置比原容量量小的容量量');
                                flag = false;
                                return false
                            }
                            agreeDilatationCompanyCapacity = newValue
                        }
                    }
                } else {
                    // 原来次数是 '次/月' 时, 新设置的次数大于之前的次数
                    // 原来次数不是 '次/月' 时, 新设置的次数大于等于之前的次数
                    oldValue = this.activeData.companyFlow;
                    // 不可用
                    if (oldValue == -3 && this.ruleForm.custom == -3) {
                        this.messageFn('warning', '原来次数为不可用,不可再次设置为不可用');
                        flag = false;
                        return false
                    }
                    if (oldValue != -3 && this.ruleForm.custom == -3) {
                        this.messageFn('warning', '不可设置为不可用');
                        flag = false;
                        return false
                    }
                    if (oldValue == -1 && this.ruleForm.custom != -1) {
                        this.messageFn('warning', '原来次数为无限制,只能再次设置为无限制');
                        flag = false;
                        return false
                    }
                    let judgeFlag = true;
                    if (flag) {
                        if (this.ruleForm.custom == -1 || this.ruleForm.custom == -3) {
                            newValue = this.ruleForm.custom;
                        } else if (this.ruleForm.custom == -2) {
                            agreeCompanyFlowMonthRefresh = this.ruleForm.unit.indexOf('次/月') !== -1 ? true : false;
                            newValue = this.ruleForm.value;
                            judgeFlag = this.activeData.companyFlowMonthRefresh ? newValue <= oldValue : newValue < oldValue
                            if (judgeFlag) {
                                flag = false;
                                this.messageFn('warning', '不允许设置比原流量小的流量');
                                return false
                            }
                        } else {
                            agreeCompanyFlowMonthRefresh = this.ruleForm.custom.indexOf('次/月') !== -1 ? true : false;
                            newValue = Number(this.ruleForm.custom.replace(this.ruleForm.custom.indexOf('次/月') !== -1 ? '次/月' : '次', ''));
                            judgeFlag = this.activeData.companyFlowMonthRefresh ? newValue <= oldValue : newValue < oldValue
                            if (judgeFlag) {
                                flag = false;
                                this.messageFn('warning', '不允许设置比原流量小的流量');
                                return false
                            }
                        }
                    }
                    agreeDilatationCompanyFlow = newValue
                }
            }
            console.log(flag, '校验通过');
            if (flag) {
                let params = {
                    "id": this.activeData.id,
                    userId: this.activeData.userId,
                    "mainId": localStorage.getItem('_mainId'),
                    // "corpId":this.corpId,
                    "approveStatus": type && type === 'refuse' ? false : true, // 审批状态 true 同意 false 拒绝
                }
                if (this.pageType === 'capacity') {
                    params.agreeDilatationCompanyCapacity = agreeDilatationCompanyCapacity;
                    params.custom = this.ruleForm.custom;
                    params.value = this.ruleForm.value;
                    params.unit = this.ruleForm.unit;
                } else {
                    params.agreeDilatationCompanyFlow = type && type === 'refuse' ? null : agreeDilatationCompanyFlow // 同意扩容后企业流量
                    params.agreeCompanyFlowMonthRefresh = agreeCompanyFlowMonthRefresh;
                    params.viewDto = this.ruleForm;
                }
                this.$http.put(url, params).then((res) => {
                    if (res.data.code == 0) {
                        this.agreePopup = false;
                        this.messageFn('success', '操作成功');
                        this.$emit('applySuccess')
                        this.getDetail();
                    } else {
                        this.$message.error(res.data.message)
                    }
                })
            }
        }
    }
}
</script>

<style lang="less" scoped>
.capacity-flow-apply {
    .capacity-flow-apply-drawer {
        .add-member-title {
            height: 45px;
            background: #f6f8fd;
            padding: 0 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            h4 {
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #000000;
            }

            i {
                cursor: pointer;
            }
        }

        .drawer-content {
            padding: 20px;
            width: 100%;
            height: calc(100% - 95px);

            // overflow-x: hidden;
            // overflow-y: auto;
            .search-box {
                display: flex;
                justify-content: space-between;
                width: 100%;
                margin-bottom: 20px;

                /deep/.el-input {
                    width: 284px;
                    height: 32px;
                    background: #FFFFFF;
                    border-radius: 5px;

                    .el-input__inner {
                        width: 284px;
                        height: 32px;
                    }

                    .el-input__icon {
                        font-size: 16px;
                        line-height: 32px;
                    }
                }

                /deep/.el-select {
                    .el-input__inner,
                    .el-input {
                        min-width: 174px;
                    }

                    .el-select__tags-text {
                        // color: #000;
                    }
                }
            }

            /deep/.el-table {
                height: calc(100% - 30px);
                overflow-x: hidden;
                overflow-y: auto;

                .el-table__header-wrapper {
                    height: 40px;
                    line-height: 40px;
                    background-color: #EFF3FF;

                    th {
                        height: 40px;
                        padding: 0;
                        background: #EFF3FF;
                    }
                }

                .cell {
                    text-align: left;
                    padding: 0;
                }

                .el-table__body {
                    td {
                        height: 60px;
                        padding: 0 !important;
                    }

                    .expand-cell {
                        width: 1px;
                    }

                    .user-name-cell .cell {
                        margin-left: -45px;
                    }

                    .el-table__row {
                        &.expanded {
                            td {
                                border: none;
                            }
                        }
                    }

                    .el-table__expanded-cell {
                        border-bottom: 1px solid #EBEEF5;
                        height: auto;

                        .description {
                            padding: 10px;
                            height: auto;
                            background: #EFF3FF;
                            border-radius: 5px;
                            padding: 6px;
                            line-height: 24px;
                            margin-bottom: 5px;
                        }

                        // max-height: 40px;
                    }
                }

                .show-num .cell {
                    // white-space: nowrap;
                }

                .user-name-cell .cell {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    .user-icon {
                        width: 30px;
                        height: 30px;
                        background: #366AFF;
                        border-radius: 50%;
                        color: #fff;
                        text-align: center;
                        line-height: 30px;
                        margin-right: 6px;
                        flex-shrink: 0;

                        // margin-left: 10px;
                        span {
                            font-size: 10px;
                            transform: scale(0.5);
                        }
                    }

                    .user-name {
                        text-align: left;

                        .dept {
                            font-size: 12px;
                            color: #999999;
                        }
                    }

                }

                .operation-cell .cell {
                    display: flex;

                    // color: #366AFF;
                    i,
                    .tips {
                        cursor: pointer;
                        color: #366AFF;
                    }

                    .tips {
                        margin-left: 2px;
                    }

                    .agree {
                        margin-right: 10px;
                    }

                    .expanded-text {
                        color: #38C175;
                    }

                    .refuse-text {
                        color: #FE5965;
                    }
                }
            }

        }

    }

    /deep/.demo-ruleForm {
        .el-form-item__content {
            display: flex;
            align-items: center;
        }

        .el-input__inner,
        .el-input {
            width: 140px;
            // height: 36px;
            // min-height: 32px;
        }

        .input-demo {
            margin: 0 10px;
        }
    }
}
</style>