<template>
    <div class="example_question">
        <div class="header">
            <div class="left">{{ $t("channel.exampleQuestion") }}</div>
            <div class="saveBtn" @click="save">
                <i class="guoran-tongyichicun-16-03-baocun iconfont"></i>
                {{ $t("channel.save") }}
            </div>
        </div>
        <div class="example_question-item">
            <div class="example_question_title">
                <div class="right">
                    <div :class="['evaluate-table-switch']">
                        <el-switch @click.native.prevent="changeExampleQuestion('switch')" :width="42"
                            v-model="exampleQuestionEnable" active-color="#366AFF" inactive-color="#E2E2E2">
                            >
                        </el-switch>
                        <span class="switch-open-icon" @click="changeExampleQuestion('open')"
                            v-if="exampleQuestionEnable"><i class="iconfont guoran-a-16-17"></i></span>
                        <span class="switch-close-icon" @click="changeExampleQuestion('close')"
                            v-if="!exampleQuestionEnable"><i class="arsenal_icon arsenalcuo1"></i></span>
                    </div>
                    <div class="item-title">
                        {{
                            $t("channel.exampleQuestion")
                        }}
                    </div>
                    <span class="des">{{
                        $t("channel.exampleQuestionSetting.tip1")
                    }}</span>
                </div>
            </div>
            <div class="example_question_row" id="example_question_row">
                <div class="example_question_col" v-for="(item, index) in exampleQuestionList" :key="item.key">
                    <!-- @mouseenter="resortQuestion" -->
                    <span class="my-handle">
                        <i class="iconfont guoran-a-16-10"></i>
                    </span>
                    <el-input :placeholder="$t('common.placeholder')" type="textarea" :rows="1"
                        :autosize="{ minRows: 4, maxRows: 4 }" v-model="item.text">
                    </el-input>
                    <i class="iconfont guoran-a-16-09" @click="deleteExample(index)"></i>
                </div>
            </div>
            <div class="addExample" @click="addExampleQues">
                <div class="addExampleBtn">
                    <i class="iconfont guoran-a-16-13"></i>
                </div>
                <span>{{ $t('channel.exampleQuestionSetting.add') }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import Sortable from "sortablejs";
import { requestUrl } from "../../../api/requestUrl";
export default {
    props: ["bindDetail"],
    data() {
        return {
            exampleQuestionEnable: false,
            exampleQuestionList: [],
        }
    },
    mounted() {
        console.log(this.bindDetail, 'bindDetail');
        let exampleQuestionList = this.bindDetail.exampleQuestionList
        if (exampleQuestionList != null) {
            let arr = JSON.parse(exampleQuestionList)
            this.exampleQuestionList = arr.map(v =>{
                return {
                    text: v,
                    key: new Date().getTime()
                }
            })
        }
        // 为空添加一个默认值
        if (this.exampleQuestionList.length == 0) {
            this.addExampleQues()
        }
        this.exampleQuestionEnable = this.bindDetail.exampleQuestionEnable ? true : false

        this.$nextTick(() => {
            this.resortQuestion()
        })
    },
    methods: {
        changeExampleQuestion(type) {
            if (type === "open") {
                this.exampleQuestionEnable = false;
            } else if (type === "close") {
                this.exampleQuestionEnable = true;
            }
        },
        addExampleQues() {
            this.exampleQuestionList.push({
                text: "",
                key: new Date().getTime()
            })
        },
        deleteExample(index) {
            if (this.exampleQuestionList.length = 0) {
                this.exampleQuestionList[0].text = ""
            } else {
                this.exampleQuestionList.splice(index, 1)
            }
        },
        resortQuestion() {
            let el = document.getElementById('example_question_row');
            var Sortables = new Sortable(el, {
                animation: 200,
                easing: "cubic-bezier(1, 0, 0, 1)",
                handle: ".my-handle",
                onUpdate: function (event) {
                },
                onEnd: (event) => {
                    //修改items数据顺序
                    let newIndex = event.newIndex,
                        oldIndex = event.oldIndex,
                        $li = el.children[newIndex],
                        $oldLi = el.children[oldIndex];
                    // 先删除移动的节点
                    el.removeChild($li)
                    // 再插入移动的节点到原有节点，还原了移动的操作
                    if (newIndex > oldIndex) {
                        el.insertBefore($li, $oldLi)
                    } else {
                        el.insertBefore($li, $oldLi.nextSibling)
                    }
                    // 更新items数组
                    let item = this.exampleQuestionList.splice(oldIndex, 1)
                    this.exampleQuestionList.splice(newIndex, 0, item[0])
                }
            });
        },
        save() {
            let arr = this.exampleQuestionList.map(item => { return item.text })
            let url = requestUrl.intelligentAgent.updateExampleQuestion;
            let bindDetail = JSON.parse(JSON.stringify(this.bindDetail))
            bindDetail.exampleQuestionList = JSON.stringify(arr)
            bindDetail.exampleQuestionEnable = this.exampleQuestionEnable
            delete bindDetail.bindBot.botInfos
            this.$http.put(url, bindDetail).then(res => {
                if (res.data.code == 0) {
                    this.$message({
                        message: this.$t('channel.saveSuccess'),
                        type: "success"
                    })
                    this.$emit("updateSuccess", bindDetail)
                }
            })
        }
    }

}
</script>

<style lang="less" scoped>
.example_question {
    height: 100%;
    //padding-right: 20px;
    overflow-y: scroll;
    background-color: #ffffff;
    border-radius: 5px;
    box-sizing: border-box;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 6px;

        .left {
            font-size: 16px;
            color: #000000;
            display: flex;
            align-items: center;
        }
    }

    .saveBtn {
        height: 26px;
        background-color: #366aff;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 14px;
        font-size: 12px;
        border-radius: 17px;
        cursor: pointer;

        i {
            font-size: 12px;
            padding-right: 4px;
        }
    }

    .example_question-item {
        height: calc(100% - 60px);
        width: 100%;
        overflow: hidden;
        overflow-y: auto;

        .example_question_title {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            margin-bottom: 20px;

            .right {
                margin-top: 20px;
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                align-items: center;
            }

            .item-title {
                margin-left: 8px;
                margin-right: 8px;
            }

            .des {
                text-align: left;
                color: #999999;
                font-size: 12px;
            }
        }
    }

    .example_question_row {
        // height: calc(100% - 60px);
        width: 100%;

        .example_question_col {
            display: flex;
            align-items: center;
            margin: 12px 0;

            .guoran-a-16-10 {
                color: #366aff;
                cursor: move;
                margin: 0 12px;
            }

            .guoran-a-16-09 {
                color: #366aff;
                margin: 0 12px;
                cursor: pointer;
            }

            .el-textarea {
                max-width: 600px;
            }
        }
    }

    .addExample {
        margin-top: 12px;
        display: flex;
        align-items: center;
        color: #366aff;
        cursor: pointer;
        width: 150px;

        .addExampleBtn {
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #366aff;
            border-radius: 5px;
            margin-right: 10px;

            i {
                color: #ffffff;
            }
        }
    }
}

/deep/ .evaluate-table-switch {
    display: flex;
    flex-direction: row;
    height: 25px;
    justify-content: space-between;
    cursor: pointer;
    position: relative;
    text-align: center;
    align-items: center;

    .el-switch {
        text-align: center;
    }

    .switch-open-icon {
        position: absolute;
        // z-index: 999;
        top: 50%;
        transform: translateY(-50%);
        left: 3px;

        i {
            color: white;
            font-size: 16px;
        }
    }

    .switch-close-icon {
        position: absolute;
        // z-index: 999;
        top: 50%;
        transform: translateY(-50%);
        left: 21px;

        i {
            color: #6e6b7b;
            font-size: 14px;
            font-weight: 600;
        }
    }

    i {
        color: #366aff;
        font-size: 18px;
    }

    .el-switch__core::after {
        width: 14px;
        height: 14px;
        top: 2px;
        left: 2px;
    }

    .is-checked .el-switch__core::after {
        left: 40px;
    }

    &.disabled {

        i,
        .el-switch__input,
        .el-switch__core {
            cursor: no-drop;
        }
    }
}
</style>