<template>
    <div class="sub-menu">
        <el-menu
            :default-active="currentPath"
            class="el-menu-vertical-demo"
            router
            ref="menu"
            :collapse-transition="false"
            :default-openeds="defaultOpeneds"
            @open="handleOpen"
            @close="handleClose"
        >
            <el-menu-item index="/_accountManage" v-if="isShowIndex && !mniuOnlyShowDuty">
                <i class="iconfont guoran-zhanghaoguanli1"></i>
                <span slot="title">{{$t('manageNave.accountManage')}}</span>
            </el-menu-item>
            <el-menu-item index="/_wxAccount" v-if="isShowIndex && false && !mniuOnlyShowDuty">
                <i class="iconfont guoran-weixinkefu"></i>
                <span slot="title">{{$t('manageNave.wxAccount')}}</span>
            </el-menu-item>
            <el-menu-item index="/_staffManage" v-if="isShowIndex && !mniuOnlyShowDuty">
                <i class="iconfont guoran-yuangongguanli" ></i>
                <span slot="title">{{$t('manageNave.staffManage')}}</span>
            </el-menu-item>
            <el-menu-item index="/_upDownManage" v-if="isShowUpDownManage && !mniuOnlyShowDuty && !main_isLishi">
                <i class="iconfont  guoran-shangxiayou" ></i>
                <span slot="title">{{$t('manageNave.upDownManage')}}</span>
            </el-menu-item>
            <el-menu-item index="/_roleManage" v-if="isShowIndex && !mniuOnlyShowDuty">
                <i class="iconfont guoran-jiaoseguanli1"></i>
                <span slot="title">{{$t('manageNave.roleManage')}}</span>
            </el-menu-item>
            <el-menu-item index="/_companyInfo" v-if="isShowIndex && false && !mniuOnlyShowDuty">
                <i class="iconfont guoran-qiyexinxi"></i>
                <span slot="title">{{$t('manageNave.companyInfo')}}</span>
            </el-menu-item>
            <el-menu-item index="/_dataRoleManage" v-if="isShowIndex && !mniuOnlyShowDuty">
                <i class="iconfont guoran-shujuquanxianguanli1"></i>
                <span slot="title">{{$t('manageNave.dataRoleManage')}}</span>
            </el-menu-item>
            <el-menu-item  index="/_workTime" v-show="isShowIndex && !mniuOnlyShowDuty && !main_isLishi">
                <i class="iconfont guoran-shijianfanwei"></i>
                <span slot="title">{{$t('manageNave.workTime')}}</span>
            </el-menu-item>
            <el-menu-item  index="/_workDuty" v-show="(isShowIndex || mniuOnlyShowDuty) && !main_isLishi">
                <i class="iconfont guoran-tongyichicun-riqixuanze"></i>
                <span slot="title">{{$t('manageNave.workDuty')}}</span>
            </el-menu-item>
            <!-- <el-menu-item  index="/setLanguage" v-show="isShowIndex || mniuOnlyShowDuty">
                <i class="iconfont guoran-yuyan"></i>
                <span slot="title">{{$t('manageNave.setLanguage')}}</span>
            </el-menu-item> -->
            <el-submenu  index="/_companyManage" v-show="isShowIndex && !mniuOnlyShowDuty && activeServiceObj.KNOWLEDGE && activeServiceObj.KNOWLEDGE != -1 && !main_isLishi">
                <template slot="title">
                    <i class="iconfont guoran-zhishi1"></i>
                    <span>{{$t('manageNave.companyManage')}}</span>
                </template>
                <el-menu-item-group>
                    <el-menu-item index="/_capacity"><span class="tips"></span>{{$t('manageNave.capacity')}}</el-menu-item>
                    <el-menu-item index="/_flow"><span class="tips"></span>{{$t('manageNave.flow')}}</el-menu-item>
                </el-menu-item-group>
            </el-submenu>

        </el-menu>
    </div>
</template>
<script>
export default {
    data() {
        return {
            currentPath: "",
            isShowUpDownManage:false, // 是否显示上下游管理
            isShowIndex: false, // 其他菜单是否显示 
            mniuOnlyShowDuty: false, // 蒙牛账号白名单
            defaultOpeneds:['/_companyManage','/_capacity','/_flow'],
            activeServiceObj:{}
        };
    },
    methods: {
        getCurrentUrl() {
            if(this.$route.path === '_flow' || this.$route.path === '_capacity'){
                return false
            } else {
                this.currentPath = this.$route.path;
            }
        },
        handleOpen(index) {
        },
        handleClose() {},
        // select(index,indexPath){
        //     console.log('select',index,indexPath);
        //     if(index === '_flow' || index === '_capacity'){
        //         this.defaultOpeneds = ['/companyManage','/_capacity','/_flow']
        //         this.$refs.menu.open('/_capacity');
        //         return false

        //     }
        // }
    },
    mounted() {
       this.$nextTick(() => {
            this.getCurrentUrl();
            this.isShowIndex = localStorage.getItem('isShowIndex') === 'true' ? true : false;
            this.mniuOnlyShowDuty =  Boolean(Number(localStorage.getItem('_mniuOnlyShowDuty')));
            this.isShowUpDownManage = true;
            this.activeServiceObj = JSON.parse(localStorage.getItem("activeServiceObj"))
       })
    },
};
</script>
<style lang="less" scoped>
.sub-menu {
    //width: 218px;
    width: 300px;
    padding-top: 16px;

    .el-menu-item,.el-submenu {
        height: 46px;
        line-height: 46px;
        color: #000000 !important;
        .iconfont {
            display: inline-block;
            margin-top: -2px;
            margin-right: 6px;
            margin-left: 6px;
            font-size: 14px;
            font-weight: 800;
        }
        .tips{
            display: inline-block;
            width: 7px;
            height: 7px;
            background: #616161;
            border-radius: 2px;
            margin-right: 6px;
        }
    }
    /deep/.el-submenu{
        height: 46px;
        line-height: 46px;
        .el-submenu__title{
            height: 46px;
            line-height: 46px;
        }
    } 
    .is-active {
        // opacity: 0.7;
        border-left: solid 2px #366aff;
        color: #366AFF !important;
        background-color: #fff;
        .iconfont {
            color: #366aff;
        }
        span {
            color: #366aff;
        }
       
    }
    .el-menu-item:hover {
        background-color: #e5ecff;
    }
    .el-menu-item-group{
        .is-active{
            // background-color: #fff;
            border-left: none;
            .tips{
                background-color: #366AFF;
            }
        }
        /deep/.el-menu-item-group__title{
            display: none;
        }
    }
}
</style>